// bootstrap
import { Navbar, Container, NavDropdown, Nav } from 'react-bootstrap'
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useContext } from 'react'

// context
import { authContext } from './authContext.js'

// svgs
import logo from '../svg/logo.svg'
import logo_name from '../svg/toolkit-logo-name-beta.svg'
import logo_blue from '../svg/mini-logo-blue.svg'
import logo_green from '../svg/mini-logo-green.svg'
import logo_yellow from '../svg/mini-logo-yellow.svg'


// menu component
export default function Navigation() {
  const auth = useContext(authContext)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState("")
  const [currentSection, setCurrentSection] = useState("")
  const [dropdownTitle, setDropdownTitle] = useState("")
  const [narrow, setNarrow] = useState("")
  const [sticky, setSticky] = useState("")

  // DEPRECATED - not sure what purpose this was serving
  //if (buildings.length === 1) selectBuilding(buildings[0])
  
  useEffect(() => {

    // current page state for labelling dropdown in app page currently open
    let tempCurrentPage = ""
    if (pathname === "/app/hello") tempCurrentPage = "Hello"
    else if (pathname === "/app/report-card") tempCurrentPage = "Report Card"
    else if (pathname === "/app/calculator") tempCurrentPage = "Forecast Calculator"
    else if (pathname === "/app/energy") tempCurrentPage = "Energy"
    else if (pathname === "/app/budget") tempCurrentPage = "Budget"
    else if (pathname === "/app/dashboard") tempCurrentPage = "Investment Dashboard"
    else if (pathname === "/app/chat") tempCurrentPage = "Chat"
    setCurrentPage(tempCurrentPage)

    // current section state for only showing dropdown items for the section you are in
    if (pathname === "/app/report-card" || pathname === "/app/calculator" || pathname === "/app/dashboard")
    {
      setCurrentSection("Reserve Fund")
    }
    else if (pathname === "/app/energy" || pathname === "/app/budget" || pathname === "/app/chat")
    {
      setCurrentSection("Tools")
    }

    // add beta symbol to dropdown title
    setDropdownTitle(<a>{tempCurrentPage} <a className="beta" id={tempCurrentPage.replace(" ", "")}>Beta</a></a>)

    // give navar narrow margin if not app/login/signin pages - also keep it pinned to top
    if ((pathname.substring(1, 4) === "app") || (pathname === "/login") || (pathname === "/signup"))
    {
      setNarrow("")
      setSticky("")
    }
    else
    {
      setNarrow("narrow")
      setSticky("top")
    }

  }, [pathname])

  function handleLogout() {
    auth.logout()
    navigate("/login")
  }

  function printScreen(){
    window.print()
  }

  // TODO NavDropdown while in page - link to other Reserve Fund pages or other Tools pages only
  return (
    <Navbar collapseOnSelect variant="light" expand="sm" className="color-nav" sticky={sticky}>
      <Container id={narrow} fluid>
          <Navbar.Brand id="LandingButtonTour" as={Link} to={pathname.substring(1, 4) === "app" ? "/app" : "/"}>
            {(pathname.substring(1, 4) !== "app") && (<img alt="Vertical City Logo and Name" src={logo_name} width="280" height="40" className="d-inline-block align-top" />)}
            {(pathname.substring(1, 4) === "app") && (<img alt="Vertical City Logo" src={logo} width="50" height="40" className="d-inline-block align-top" />)}
          </Navbar.Brand>
          {(pathname.substring(1, 4) === "app") && <Navbar.Toggle aria-controls="responsive-navbar-nav" />}
          <Navbar.Collapse id="responsive-navbar-nav" style={{justifyContent:'right'}}>
          {(pathname === "/") && (<Navbar.Toggle aria-controls="basic-navbar-nav" />)}
          {(pathname.substring(1, 5) === "app/") && (pathname !== "/app/manage-data") && 
          <Nav className="me-auto my-2 my-lg-0" style={{fontWeight: 'bold' }}>
            <NavDropdown title={dropdownTitle}>
                {currentPage !== "Report Card" && currentSection === "Reserve Fund" &&
                <NavDropdown.Item as={Link} to="/app/report-card" disabled={auth.isPaid}>
                  <div className="nav-dropdown-item">
                      <img alt="Blue Logo" src={logo_blue} width="50" height="50"/>
                      <div>
                        <b>Report Card</b>
                        <p>See the overall health of your Reserve Fund.</p>
                      </div>
                  </div>     
                </NavDropdown.Item>}
                {currentPage !== "Calculator" && currentSection === "Reserve Fund" &&
                <NavDropdown.Item as={Link} to="/app/calculator" disabled={auth.isPaid}>
                  <div className="nav-dropdown-item">
                      <img alt="Green Logo" src={logo_green} width="50" height="50"/>
                      <div>
                        <b>Forecast Calculator</b>
                        <p>Simulate different scenarios using the calculator.</p>
                      </div>
                  </div>     
                </NavDropdown.Item>}
                {currentPage !== "Dashboard" && currentSection === "Reserve Fund" && 
                <NavDropdown.Item as={Link} to="/app/dashboard" disabled={auth.isPaid}>
                  <div className="nav-dropdown-item">
                      <img alt="Yellow Logo" src={logo_yellow} width="50" height="50"/>
                      <div>
                        <b>Investment Dashboard</b>
                        <p>Manage your cash and investments.</p>
                      </div>
                  </div> 
                </NavDropdown.Item>}
                {currentPage !== "Energy" && currentSection === "Tools" &&
                <NavDropdown.Item as={Link} to="/app/energy" disabled={auth.isPaid}>
                  <div className="nav-dropdown-item">
                      <img alt="Blue Logo" src={logo_blue} width="50" height="50"/>
                      <div>
                        <b>Energy</b>
                        <p>Monitor your energy consumption.</p>
                      </div> 
                  </div>         
                </NavDropdown.Item>}
                {currentPage != "Budget" && currentSection === "Tools" &&
                <NavDropdown.Item as={Link} to="/app/budget" disabled={auth.isPaid}>
                  <div className="nav-dropdown-item">
                      <img alt="Green Logo" src={logo_green} width="50" height="50"/>
                      <div>
                        <b>Budget</b>
                        <p>Visualize your historical budgets.</p>
                      </div> 
                  </div>         
                </NavDropdown.Item>}
                {currentPage != "Chat" && currentSection === "Tools" &&
                <NavDropdown.Item as={Link} to="/app/chat" disabled={auth.isPaid}>
                  <div className="nav-dropdown-item">
                      <img alt="Yellow Logo" src={logo_yellow} width="50" height="50"/>
                      <div>
                        <b>Chat</b>
                        <p>AI trained on your condo.</p>
                      </div> 
                  </div>         
                </NavDropdown.Item>}
            </NavDropdown>
          </Nav>}
          {(pathname.substring(1, 4) === "app") &&
          <Nav className="d-flex" style={{ marginLeft:'5px' }}>
            { (auth.tourStatus === "ManageData") && (pathname == "/app") ?
              <NavDropdown id="ManageDataTour" title={auth.selectedBuilding} align="end" show={true}>
                <NavDropdown.Item as={Link} to="/app/manage-data">Manage Data</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/app/settings">Settings</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/" onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown> :
              <NavDropdown id="ManageDataTour" title={auth.selectedBuilding} align="end">
                <NavDropdown.Item as={Link} to="/app/manage-data">Manage Data</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/app/settings">Settings</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/" onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            }
          </Nav>}
          {(pathname.substring(1, 10) === "articles/") && <Nav.Link id="home-articles" as={Link} to="/articles">Articles</Nav.Link>}
          {(pathname === "/") &&
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto justify-content-end flex-grow-1 pe-3">
                <Nav.Link id="home-articles" as={Link} to="/articles">Articles</Nav.Link>
                <Nav.Link id="home" as={Link} to="/login">Log In</Nav.Link>
                <Nav.Link id="home" as={Link} to="/signup">Sign up</Nav.Link>
            </Nav>
          </Navbar.Collapse>}
          </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
