import { useEffect, useState, useCallback, useContext } from 'react'
import { Form, Dropdown, DropdownButton, Modal, OverlayTrigger, Popover, PopoverBody, Alert, Spinner, ProgressBar, Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"

// context
import { authContext } from '../authContext.js'
import { dataContext } from '../dataContext.js'

// Components
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import MissingData from '../MissingData.js'
import OffCanvasCashflow from '../OffCanvasCashflow.js'
import SelectStudyModal from '../SelectStudyModal.js'
import OnboardingLinePlotModal from '../OnboardingLinePlotModal.js'

// Helper Functions
import { onMouseOutSync, chartExtremes } from '../../lib/helpers/highchartsHelpers.js'
import { formatAmount, formatAmountKM, getWeight } from '../../lib/helpers/formattingHelpers.js'

// tooltip flag interface
import { getExpenseFlag } from '../../lib/helpers/tooltipFlags.js'

// logo svgs
import logoBlue from '../../svg/mini-logo-blue.svg'
import logoGreen from '../../svg/mini-logo-green.svg'
import logoYellow from '../../svg/mini-logo-yellow.svg'
import check from '../../svg/check.svg'
import x from '../../svg/x-red.svg'
import greyQuestion from '../../svg/grey-question.svg'
import { ReactComponent as Watermark } from '../../svg/logo-name.svg'


export default function ReportCardPDF() {
  // context and navigation
  const auth = useContext(authContext)
  const data = useContext(dataContext)

  const currentYear = new Date().getFullYear()

  const navigate = useNavigate()

  //------------------------------------------------------------------------------------------------------------------
  // Component State
  //------------------------------------------------------------------------------------------------------------------
    
  // get available rf studies
  const [selectedYear, setSelectedYear] = useState(null)

  // chart options
  const [chartOptionsReserve, setChartOptionsReserve] = useState({title: { text: ''}})
  const [chartOptionsExpenditures, setChartOptionsExpenditures] = useState({title: { text: ''}})
  const [chartOptionsRate, setChartOptionsRate] = useState({title: { text: ''}})

  const [chartOptionsFailures, setChartOptionsFailures] = useState({title: { text: ''}})

  const [detailCharts, setDetailCharts] = useState(false)

  // for mobile screens
  const [mobileScreen, setMobileScreen] = useState(true)
  const [failureChartHeight, setFailureChartHeight] = useState('25%')
  const [labelRotation, setLabelRotation] = useState(0)
  const [chartSpacing, setChartSpacing] = useState([20,20,20,20])

  // chart interactive drawing setMouseOver
  const [mouseOver, setMouseOver] = useState(true)
  const [mouseOverEvent, setMouseOverEvent] = useState({})
  const [mouseOverPoint, setMouseOverPoint] = useState({})
  const [mouseOverYear, setMouseOverYear] = useState(null)

  // data exists flags
  const [dataExists, setDataExists] = useState(false)
  const [showMiniDiagram, setShowMiniDiagram] = useState(false)
  
  // totals header
  const [labelPoint, setLabelPoint] = useState({})

  // show flags
  const [showReportCardModal, setShowReportCardModal] = useState(false)
  const [showParamReportCard, setShowParamReportCard] = useState(false)
  const [showOffCanvas, setShowOffCanvas] = useState(false)
  const [showOriginalCashflow, setShowOriginalCashflow] = useState(false)
  const [showHeaderModal, setShowHeaderModal] = useState(0)
  const [showOnboardingModal, setShowOnboardingModal] = useState(false)

  //------------------------------------------------------------------------------------------------------------------
  // Helper Functions
  //------------------------------------------------------------------------------------------------------------------

  function helperTestBreakdown(dataTest, minYear, numYears) {
    
    function helperFillFailures(text, minYear, numYears) {
      text = text.replace("{", "")
      text = text.replace("}", "")
      let failures = text.split(',')
      
      const output = []
      let pair = []
      let year
      let sum
      for (let i = 0; i < numYears; i++) 
      {
        year = minYear + i
        sum = 0

        let failurePair = []
        for (let i in failures)
        {
          failurePair = failures[i].split(':')
          if (Number(failurePair[0]) == year) 
          {
            sum = Number(failurePair[1])
            break
          }
        }
        pair = [year, sum]
        output.push(pair)
      }

      return output
    }

    const outputBreakdown = []
    const outputFailures = {}
    for (let i in dataTest["data"])
    {
      const tempBreakdown = dataTest["data"][i].split(":")
      if (tempBreakdown[0] == "Max Cash Holdback Test") tempBreakdown.push("A Reserve Fund Study assumes that all cash is invested and earns interest yearly, but in reality, condo boards often hold some cash for emergencies. This results in the actual benchmark being lower than the study's projected benchmark, as less money is invested and earning interest than assumed.")
      else if (tempBreakdown[0] == "Inflation Adjustment Test") tempBreakdown.push("To obtain a more realistic view of how interest and inflation rates change, we model the probabilities using historical data and simulate the forecasts thousands of times using a technique called the Monte Carlo Method.")
      else if (tempBreakdown[0] == "Expense Variance Test") tempBreakdown.push("The simulator will vary the scheduled expenses from the reserve fund study from 5% to 15% across an entire simulation.")
      else if (tempBreakdown[0] == "One Time Expense Test") tempBreakdown.push("The simulator will insert a single random $100k - $300k expense dropped into a random year in the simulation.")
    

      outputFailures[tempBreakdown[0]] = helperFillFailures(dataTest["failures"][i], minYear, numYears)

      outputBreakdown.push(tempBreakdown)
    }
    data.setDataTestBreakdown(outputBreakdown)
    data.setDataFailures(outputFailures)
  }

  function getStudyFromYear(year) {
    for (let i = 0; i < data.availableRFStudies.length; i++) {
      if (data.availableRFStudies[i]['year'].toString() == year) return year + " Study by " + data.availableRFStudies[i]['engineer']
    }
  }

  // syncs all charts onMouseOver
  function onMouseOverSyncRF(e, chartNames, all=false) {
    // looping through all charts
    Highcharts.charts.forEach(chart => {
      // only apply code to given charts
      if (chart && (all || chartNames.includes(chart.title.textStr)))
      {
        // collects each point at current location on x-axis e.g. for rates chart, there would be 3 points (because there are 3 series)
        let pointsTooltip = []
        // looping through series
        chart.series.forEach(s => {
          // looping through points
          s.points.forEach(p => {
            // since mouseOut behavior is unpredictable and laggy for reserve chart, same functionality is included here
            // (if marker is currently over point, remove it)
            if (p.state == 'hover') p.setState('')

            // syncs points between the graphs
            if (p.index === e.target.index)
            {
              // sets point state to "hover" (dots appear on graph at given point)
              p.setState('hover')
              pointsTooltip.push(p)

              // refreshing crosshair of given charts
              chart.xAxis[0].drawCrosshair(e, p)

              // if mouse over on support charts, then manually update main chart label
              if (chart.title.textStr === "reserve-fund-benchmark")
              {
                setLabelPoint(p)
                //refreshReserveLabel(p)
                // Deprecated TODO validate this is not too computationally heavy
                // drawing markers on main chart when hovering over Detail Charts 
                // setMouseOverPoint(p)
                // setMouseOverEvent(e)
                // setMouseOverYear(e.target.category)
              }    
            }
          })
        })
        // refreshing tooltip of synced charts
        if (pointsTooltip.length > 0)
        {
          // this method takes an array of point objects that it uses to display the tooltip
          chart.tooltip.refresh(pointsTooltip)
        }
      }
    })
  }

  // updates chart properties based on window size
  const resizeMobile = () => {
    // mobile
    if (window.matchMedia("(max-width: 560px)").matches)
    {
      setFailureChartHeight('40%')
      setLabelRotation(-35)
      setChartSpacing([10,0,0,0])
      setMobileScreen(true)
    } 
    else if(!window.matchMedia("(max-width: 560px)").matches )
    {
      setFailureChartHeight('25%')
      setLabelRotation(0)
      setChartSpacing([20,20,20,20])
      setMobileScreen(false)
    }
  }
  
  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // fire once on page load
  useEffect(() => {
    if (auth.isContextSecured)
    {
      data.fetchRFStudies()
      if (auth.selectedStudyYear) 
      {
        setSelectedYear(auth.selectedStudyYear)
      }
    }
  }, [auth.isContextSecured])

  useEffect(() => {
    if (!auth.selectedStudyYear)
    {
      if (data.availableRFStudies.length == 1) setSelectedYear(data.availableRFStudies[0].year)
      else 
      {
        data.setShowSelectStudyModal(true)
      }
    }
  }, [data.availableRFStudies])

  useEffect(() => {
    // Closes the OffCanvasCashFlow to prevent the next step from being covered
    if(auth.tourStatus == "LandingButton") setShowOffCanvas(false)
  }, [auth.tourStatus])
  
  // fire after study year is selected
  useEffect(() => {
    if (selectedYear)
    {
      // fetch cashflow performance - requires data from previous fetch
      data.fetchCashflowData(selectedYear)
      data.fetchReportCard(selectedYear)
      if (!data.setBuildingSettingsReceived) data.fetchBuildingSettings()
    }
  }, [selectedYear, auth.isContextSecured]) // only trigger if user selects the year, otherwise this effect will be run twice on startup

  // only update report card componet when risk level changes (won't update when new study year is selected)
  useEffect(() => {
    if (Object.keys(data.dataReportCard).length > 0)
    {  
      if (showParamReportCard)
      {
        let strGrade = data.dataReportCard["param"]["grade"][0].split(":")
        data.setDataGrade(strGrade)
        data.setDataSubtestBreakdown(data.dataReportCard["param"]["subtest"])
        helperTestBreakdown(data.dataReportCard["param"]["test"], data.dataMinYear, data.dataMaxYear - data.dataMinYear)
      }
      else
      {
        let strGrade = data.dataReportCard["base"]["grade"][0].split(":")
        data.setDataGrade(strGrade)
        data.setDataSubtestBreakdown(data.dataReportCard["base"]["subtest"])
        helperTestBreakdown(data.dataReportCard["base"]["test"], data.dataMinYear, data.dataMaxYear - data.dataMinYear)
      }
      
      data.setUpdateCharts(!data.updateCharts)
      setDataExists(true)
    }
  }, [data.dataReportCard, data.dataMinYear, data.dataMaxYear, showParamReportCard])

  //Set up risize event listener for chart mobile views
  useEffect(() => {

    //Initialize state variables
    resizeMobile()

    // Add event listener for window resize
    window.addEventListener('resize', resizeMobile)

    // Clean up function
    // TODO not sure if we need this
    return () => {
      window.removeEventListener('resize', resizeMobile)
    }

  }, [])

  // Highcharts Chart Options Hook
  useEffect(() => {
    //resetReserveChart()
    if (data.dataTrueBenchmarkRange.length > 0)
    {
      const extremes = chartExtremes([data.dataBaseLow, data.dataSystemHigh, data.dataClosing])
      data.setDataExtremes(extremes) // using for drawing on chart (coordinates of points change depending on y-axis label)

      // setting chart options - this is done to ensure that each chart is not rerendered when risk level is changed, improving efficiency + preventing page from snapping to top
      setChartOptionsReserve({
        chart: {
          type: "line",
          height: '40%',
          borderRadius: 10, // same as other divs in index.css  
          backgroundColor: "#ffffff",
          spacing: chartSpacing,
          events: {
            // update main chart label for some non-mouseover edge cases
            render: function () {
              // if mouse is over chart, exit code
              if (this.xAxis[0].paddedTicks.length == 4) return
              
              var point
              var iSeries = 4 // Study Benchmark Series

              var lastIndex = this.series[iSeries].points.length - 1

              // on page load or recalculate request
              if (this.mainLabel == undefined)
              {
                point = this.series[iSeries].points[lastIndex]
                setLabelPoint(point)
                //refreshReserveLabel(point)
              }
              // change selected year
              else 
              {
                var parser = new DOMParser()
                var parsed = parser.parseFromString(this.mainLabel.textStr, "text/html")
                var html_parent = parsed.body.getElementsByClassName('chart-label')[0]
                
                // parsing value corresponding to the first $ value shown in reserve label (different for each comparisonView)        
                var yVal = this.series[iSeries].points[lastIndex].y
                var html_yVal = html_parent.children[1].children[1].children[1].innerText
                var current_yVal = Number(html_yVal.split("$")[1].replaceAll(",",""))

                if (yVal != current_yVal)
                {
                  point = this.series[iSeries].points[lastIndex]
                  setLabelPoint(point)
                  //refreshReserveLabel(point)
                }
              }
            }
          }
        },
        title: {
          text: "reserve-fund-benchmark",
          style: {
            display: 'none'
          }
        },
        tooltip: {
          // crosshairs: true,
          shared: true,
          // split: false,
          formatter: function () {
            return false
          }
        },
        series: [
          {
            name: 'Vertical City Range',
            type: 'areasplinerange',
            data: [],
            color: '#a7b661',
            lineWidth: 0,
            fillOpacity: 0.3,
            marker: {
              enabled: false
            }
          },
          {
            name: 'Vertical City',
            type: 'spline',
            data: [],
            color: '#a7b661',
            lineWidth: 2.5,
            marker: {
              enabled: false,
            }
          },
          {
            name: 'True Benchmark',
            type: 'spline',
            data: data.dataTrueBenchmark,
            color: '#5d98d1',
            lineWidth: 2,
            marker: {
              enabled: false,
            }
          },
          {
            name: 'True Benchmark Range',
            type: 'areasplinerange',
            data: data.dataTrueBenchmarkRange,
            color: '#5d98d1',
            lineWidth: 0,
            fillOpacity: 0.3,
            marker: {
              enabled: false
            }
          },
          {
            name: 'Study Benchmark',
            type: 'spline',
            data: data.dataStudyBenchmark,
            color: '#fbd57e',
            lineWidth: 2,
            marker: {
              enabled: false,
            }
          },
        ],
        legend: {
          enabled: false
        },
        plotOptions: {
          // disable markers for all series
          series: {
            marker: {
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            // mouse over chart event
            point: {
              events: {
                mouseOver(e) {
                  // mouseOverYear now triggers useEffect which does chart drawing
                  setMouseOverPoint(this)
                  setMouseOverEvent(e)
                  setMouseOverYear(e.target.category) // 2048
                },
                // TODO this mouseOut event is triggered during mouseOver, which makes it impossible to use as expected
                // mouseOut() {
                //   console.log("mouseOut")
                //   if (mouseOver) setMouseOver(false)
                // }
              }
            },
            events: {  
              legendItemClick: function () {
                return false
              }
            }
          }
        },
        xAxis: [{
          // visible: false,
          crosshair: true,
          lineWidth: 0,
          visible: true,
          startOnTick: true,
          endOnTick: false,
          maxPadding: 0.03,
          // draws a line where the current year is
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick positions to be start of graph, end of graph, and current year
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear],
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          visible: true,
          title: { enabled: false },
          max: extremes.max,
          // makes minimum always 0, unless minimum point is less than 0
          min: extremes.min > 0 ? 0 : extremes.min,
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [extremes.min, 0, extremes.max]
            }
          }
        }],
        credits: {
          enabled: false
        }
      })

      setChartOptionsExpenditures({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'expenditures',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          split: false,
          formatter: function () {
            const expense = getExpenseFlag(auth.selectedBuilding, this.points[0].x)
            const htmlTooltip = 
            `<div>\
              <b>${this.points[0].x}</b>\
              <br/>\
              <span style='color:#5d98d1'>●</span> \
              Contribution: <b>${formatAmount(this.points[0].y, 0)}</b>\
              <br/>\
              <span style='color:#fbd57e'>●</span>\
              Expenditures: <b>${formatAmount(this.points[1].y, 0)}</b>\
              <br/>\
              ${(expense != undefined) 
                ? 
                `<div style='color:#66000000'>-</div>\
                <span style='color:#000000; font-size:6px; text-indent: 3px'>●</span>\
                <p style="text-indent: 40px">${expense}</p>`
                : ""
              }
            </div>`
            return mobileScreen ? false:htmlTooltip
          }
        },
        xAxis: [{
          visible: true,
          // draws line for current year
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be on current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear],
          labels: {rotation: currentYear - data.dataMinYear == 1 ? -45 : labelRotation}
        }],
        yAxis: [{
          visible: true,          
          lineWidth: 0,
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
        }],
        series: [
          {
            name: 'Contribution',
            type: 'column',
            data: data.dataContribution,
            color: '#5d98d1',
            marker: {
              enabled: false
            }
          },
          {
            name: 'Expenditures',
            type: 'column',
            data: data.dataExpenditures,
            lineWidth: 0,
            color: '#fbd57e',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'rates'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false
              }
            },
            stickyTracking: true
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsRate({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'rates',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          split: false,
          crosshairs: true,
          shared: true,
          formatter: function () {
            var html_tooltip = '<b>Year ' + this.x + '</b><br/>'
            for (let i in this.points)
            {
              html_tooltip += '<span style="color:' + this.points[i].series.color + ';">\u25CF </span>' + this.points[i].series.name + ': ' + '<b>' + formatAmount(this.points[i].y * 100, 2, false, true) + '%' + '</b><br/>'
            }
            return mobileScreen ? false:html_tooltip
          },
        },
        xAxis: [{
          visible: true,
          gridLineWidth: 1,
          gridLineColor: "#5d98d1",
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear],
          labels: {rotation: currentYear - data.dataMinYear == 1 ? -45 : labelRotation}
        }],
        yAxis: [{
          visible: true,
          // max: .1,
          min: 0,
          // tickPositions: [0],
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {              
              return this.value * 100 + '%'              
            }
          }
        }],
        series: [
          {
            name: 'Contribution Rate',
            data: data.dataContributionRate,
            color: '#5d98d1',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          },
          {
            name: 'Interest Rate',
            data: data.dataInterestRate,
            color: '#a7b661',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          },
          {
            name: 'Inflation Rate',
            data: data.dataInflationRate,
            color: '#fbd57e',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'expenditures'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false
              }
            }
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsFailures({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: failureChartHeight
        },
        title: {
          text: 'failures',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          split: false,
          formatter: function () {
            var html_tooltip = '<b>Year ' + this.x + '</b><br/>'
            for (let i in this.points)
            {
              html_tooltip += '<span style="color:' + this.points[i].series.color + ';">\u25CF </span>' + this.points[i].series.name + ': ' + '<b>' + this.points[i].y + '</b><br/>'
            }
            return mobileScreen ? false:html_tooltip
          }
        },
        xAxis: [{
          visible: true,
          // draws line for current year
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be on current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear],
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          visible: true,          
          lineWidth: 0,
          title: { enabled: false },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
        }],
        series: [
          {
            name: "Cash Holdback",
            type: 'column',
            data: data.dataFailures["Max Cash Holdback Test"],
            color: '#5d98d1',
            lineWidth: 3,
            marker: {
              enabled: false
            }
          },
          {
            name: 'Inf. Adjustment',
            type: 'column',
            data: data.dataFailures['Inflation Adjustment Test'],
            lineWidth: 3,
            color: '#fbd57e',
            marker: {
              enabled: false
            }
          },
          {
            name: 'Expense Variance',
            type: 'column',
            data: data.dataFailures['Expense Variance Test'],
            lineWidth: 3,
            color: '#a7b661',
            marker: {
              enabled: false
            }
          },
          {
            name: 'One Time Expense',
            type: 'column',
            data: data.dataFailures['One Time Expense Test'],
            lineWidth: 3,
            color: '#f55656',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'rates'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false
              }
            },
            stickyTracking: true
          }
        },
        credits: {
          enabled: false
        }
      })
    }
  }, [data.updateCharts, data.dataTrueBenchmark, data.dataTrueBenchmarkRange, data.dataStudyBenchmark, mobileScreen])

  // Mouse Over X-Axis Tick Hook - triggered when mouse over main chart point changes
  useEffect(() => {
    if (mouseOverYear)
    {
      //----------------------------------------------------
      // Transparent box to right of hovered point
      //----------------------------------------------------

      var width = mouseOverPoint.series.chart.plotWidth - mouseOverPoint.plotX,
          height = mouseOverPoint.series.chart.plotHeight
      
      // destory box if already exists
      if (mouseOverPoint.series.chart.transparentBox != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.transparentBox).length > 0) mouseOverPoint.series.chart.transparentBox.destroy()
      }

      // adjust x coordinates of box and markers depending on starting position of chart (varies depending on y-axis ticks)
      var xAxisPadding = 0
      if (data.dataExtremes.max < 10000000)
      {
        if (data.dataExtremes.min < 0) xAxisPadding = 10
        else xAxisPadding = 5
      }
      else if (data.dataExtremes.min < 0)
      {
        if (data.dataExtremes.min < -10000000) xAxisPadding = 8
        else xAxisPadding = 10
      }
      
      // draw transparent box to right of hovered point
      mouseOverPoint.series.chart.transparentBox = mouseOverPoint.series.chart.renderer.rect(mouseOverPoint.plotX + 65+ xAxisPadding, 0, width + 15, height + 19, 1, 0)
        .attr({
          fill: "rgba(255,255,255,0.75)",
          zIndex: 3
        })
        .add()

      //----------------------------------------------------
      // Draw Markers
      //----------------------------------------------------
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.marker1 != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.marker1).length > 0)
        {
          mouseOverPoint.series.chart.marker1.destroy()
          mouseOverPoint.series.chart.marker2.destroy()
        }
      }

      // True Benchmark (blue marker)
      mouseOverPoint.series.chart.marker1 = mouseOverPoint.series.chart.renderer.rect(
        mouseOverPoint.plotX + xAxisPadding + (mobileScreen ? 39:59.9), 
        mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].plotY + (mobileScreen?5.5:15.5), 
        10, 10, 5, 0
      ).attr({
        fill: "#5d98d1", 
        zIndex: 4
      }).add()

      // Study Benchmark (yellow marker)
      mouseOverPoint.series.chart.marker2 = mouseOverPoint.series.chart.renderer.rect(
        mouseOverPoint.plotX + xAxisPadding + (mobileScreen ? 39:59.9), 
        mouseOverPoint.series.chart.series[4].data[mouseOverPoint.index].plotY + (mobileScreen?5.5:15.5), 
        10, 10, 5, 0
      ).attr({
        fill: "#fbd57e", 
        zIndex: 4
      }).add()
      
      //----------------------------------------------------
      // Draw Data Label (next to points)
      //----------------------------------------------------
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.dataLabel != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.dataLabel).length > 0) mouseOverPoint.series.chart.dataLabel.destroy()
      }
      
      var seriesA = mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].plotY
      var y // calc middle point between 2 points
      var value // calc difference of 2 points 
      var seriesB = mouseOverPoint.series.chart.series[4].data[mouseOverPoint.index].plotY
      y = ((seriesA - seriesB) / 2) + seriesB
      value = mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].y - mouseOverPoint.series.chart.series[4].data[mouseOverPoint.index].y

      var color = (value > 0) ? 'green' : 'red'
      var html = `<div class='data-label' id=${color}>${(color == 'green' ? '+' : '')}${formatAmountKM(value)}</div>`
      var xAxisLabelPadding = 72

      if (mouseOverPoint.index == mouseOverPoint.series.points.length - 1) xAxisLabelPadding = 62

      // TODO styling, bolder
      mouseOverPoint.series.chart.dataLabel = mouseOverPoint.series.chart.renderer.label(
        mobileScreen ? null:html,
        mouseOverPoint.plotX + xAxisLabelPadding + xAxisPadding,
        y + 6.8, // TODO adjust this value depending on shape/size of label
        null, null, null, true
      ).attr({
        zIndex: 4
      }).add()

      //----------------------------------------------------
      // Update Main Chart Label
      //----------------------------------------------------
      
      setLabelPoint(mouseOverPoint)
      //refreshReserveLabel(mouseOverPoint)

      //----------------------------------------------------
      // Sync All Charts
      //----------------------------------------------------

      onMouseOverSyncRF(mouseOverEvent, ['rates', 'expenditures', 'failures'])

      //----------------------------------------------------
      // Draw X Axis Tick
      //----------------------------------------------------

      const tempReserveOptions = {
        xAxis: [{
          tickPositions: [data.dataMinYear, currentYear, mouseOverYear, data.dataMaxYear]
        }],
      }
      setChartOptionsReserve(tempReserveOptions)      
    }
  }, [mouseOverYear, auth.isContextSecured])

  // Mouse Out Chart Component Hook
  useEffect(() => {
    if (Object.keys(mouseOverPoint).length == 0) return
    if (mouseOverPoint.index == null) return
    if (data.recalculationBuffering) return
    if (mouseOver == false)
    {      
      //----------------------------------------------------
      // Destroy Drawn SVGs
      //----------------------------------------------------

      // destory box if already exists
      if (mouseOverPoint.series.chart.transparentBox != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.transparentBox).length > 0) mouseOverPoint.series.chart.transparentBox.destroy()
      }
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.marker1 != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.marker1).length > 0)
        {
          mouseOverPoint.series.chart.marker1.destroy()
          mouseOverPoint.series.chart.marker2.destroy()
        }
      }
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.dataLabel != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.dataLabel).length > 0) mouseOverPoint.series.chart.dataLabel.destroy()
      }
      
      //----------------------------------------------------
      // Reset Main Chart Label
      //----------------------------------------------------

      //resetReserveChart()
      onMouseOutSync(['rates', 'expenditures', 'failures'])

      //----------------------------------------------------
      // Remove X Tick
      //----------------------------------------------------

      const tempReserveOptions = {
        xAxis: [{
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear]
        }],
      }
      setChartOptionsReserve(tempReserveOptions)
    }
  }, [mouseOver])

  //------------------------------------------------------------------------------------------------------------------
  // Handle Function
  //------------------------------------------------------------------------------------------------------------------

  function handleSelectYear(year) {
    setMouseOverPoint({}) // this is necessary to prevent onMouseOut useEffect hook from throwing error on null chart object
    setSelectedYear(year)
    auth.selectStudy(year.toString())

    if (currentYear - year > 0) setShowMiniDiagram(true)
    else setShowMiniDiagram(false)
  }

  //------------------------------------------------------------------------------------------------------------------
  // Callback Functions
  //------------------------------------------------------------------------------------------------------------------

  const handleSyncCharts = useCallback((e, chartNames, all=false) => {
    onMouseOverSyncRF(e, chartNames, all)
  }, [])
  
  const handleCloseOffCanvas = useCallback(() => {
    setShowOffCanvas(false)
  }, [])

  const handleCloseOnboarding = useCallback(() => {
    setShowOnboardingModal(false)
  }, [])

  const handleSelectStudy = useCallback((year) => {
    data.setShowSelectStudyModal(false)
    handleSelectYear(year)
  }, [])

  //------------------------------------------------------------------------------------------------------------------
  // Components
  //------------------------------------------------------------------------------------------------------------------

  function ReportCardModal(props) {

    function GradingDetail() {
      return (
        <div className='grading-breakdown'>
          {data.dataTestBreakdown.map((note, index) => (
              <div style={{width: '93%'}}>
                <div className='space-between'>
                  <div><b>{data.dataTestBreakdown[index][0] + "s:"}</b>{data.dataTestBreakdown[index][1]}</div>
                  <OverlayTrigger placement="top" overlay={<Popover><PopoverBody>{data.dataTestBreakdown[index][2]}</PopoverBody></Popover>}>
                    <div className='learn-more'>Learn More</div>
                  </OverlayTrigger>
                </div>
                <ProgressBar variant="primary" now={getWeight(data.dataTestBreakdown[index][1])} />
              </div>
          ))}
        </div>
      )
    }
  
    function TestBreakdown(props) {
      var i = props.line.lastIndexOf(" ")
      var fraction = props.line.substring(i+1, props.line.length)
      var numbers = fraction.split("/").map(Number)

      var idLast = ''
      if (props.last) idLast = 'last'
  
      // Pass (50% or higher)
      if (numbers[0]/numbers[1] >= .5) return <Alert className='alert-detail' id={idLast} variant='success'>
                                                <p><img src={check} alt="Check Emoji" width="20" height="20" /> {props.line}</p>
                                              </Alert>
      // Fail
      else return <Alert className='alert-detail' id={idLast} variant='danger'>
                                                      <p><img src={x} alt="X Emoji" width="20" height="20" /> {props.line}</p>
                                                    </Alert>
    }
  
    return (
      <Modal className="rc-modal" dialogClassName="modal-width" show={props.show} onHide={() => setShowReportCardModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Report Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='flex-column-mobile'>
          <div className='header-space'>
            <div className='grading-header'>
              <div className='grade'>{data.dataGrade[0]}</div>
              <h5>{data.dataGrade[1]}</h5>
            </div>
            <GradingDetail />
          </div>
          <div className='grading-summary'>
            <div className='alert-info'>
              {data.dataSubtestBreakdown && data.dataSubtestBreakdown.map((breakdown, index) => (
                <TestBreakdown line={breakdown} last={(index + 1) == data.dataSubtestBreakdown.length}/>
              ))}
            </div>
          </div>
        </div>
        </Modal.Body>
      </Modal>
    )
  }

  function describeArc(cx, cy, r, startAngle, endAngle) {
    const start = polarToCartesian(cx, cy, r, endAngle);
    const end = polarToCartesian(cx, cy, r, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  
    return [
      "M", start.x, start.y,
      "A", r, r, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
  }
  
  function polarToCartesian(cx, cy, r, angleInDegrees) {
    const rad = (angleInDegrees * Math.PI) / 180;
    return {
      x: cx + r * Math.cos(rad),
      y: cy - r * Math.sin(rad)
    };
  }

  function SemiCircleMeter({ score, color, word, description}) {
    const outerRadius = 200
    const strokeWidth = 50
    const cx = outerRadius + strokeWidth / 2;
    const cy = outerRadius + strokeWidth / 2;
  
    const width = outerRadius * 2 + strokeWidth;
    const height = outerRadius + strokeWidth;
  
    const bgColor = "#e9ecef";
    const fillAngle = (score / 100) * 180;

    const backgroundPath = describeArc(cx, cy, outerRadius, 180, 0);
  

    const filledPath = describeArc(cx, cy, outerRadius, 180, 180 - fillAngle);
  
    return (
      <div style={{ position: "relative", textAlign: "center", marginTop:"20px"}}>
        <svg
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          style={{ display: "block", margin: "0 auto"}}
        >
          <path
            d={backgroundPath}
            fill="none"
            stroke={bgColor}
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
          />

          <path
            d={filledPath}
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
          />
        </svg>
  
        <div
          style={{
            position: "absolute",
            top: "60%",  
            left: 0,
            width: "100%",
            transform: "translateY(-50%)"
          }}
        >
          <h1 style={{ margin: 0, fontSize: "60px" }}>{score}</h1>
          <h5 style={{fontSize: '1.5rem', margin: 4 }}>{word}</h5>
          <p style={{ fontSize: '1rem',margin: 0 }}>{description}</p>
        </div>
      </div>
    );
  }

  function ReportCardMeter() {
    var score = data.dataGrade[1].split('(')[1].split('%')[0]
    var word
    var description

    if (score < 50) 
    {
      word = "Fail"
      description = "This is a big red flag. You should be taking action immediately."
    }
    else if (score < 60) 
    {
      word = "Bad"
      description = "Things aren't looking good. Prioritize and act now."
    }
    else if (score < 70)
    {
      word = "Fair"
      description = "Not bad, but could easily get there. You probably want to prioritize this."
    }
    else if (score < 90)
    {
      word = "Good"
      description = "Looks stable but some tweaks might be in order."
    }
    else if (score < 100)
    {
      word = "Incredible"
      description = "Keep doing what you're doing."
    }
    else if (score == 100)
    {
      word = "Unicorn"
      description = "This is probably a bug."
    }

    var colorSemiCircle = showParamReportCard ? "#a7b661" : "#5d98d1"
    var colorProgressBars = showParamReportCard ? "success" : "primary"

    if (data.dataTestBreakdown.length === 0) return <></>
    data.dataTestBreakdown[0][0] = 'Cash Holdback Test'
    data.dataTestBreakdown[2][0] = 'Expense Variance Test'
    data.dataTestBreakdown[3][0] = 'One Time Expense Test'
    return (
      <>
      <div className='section-description'>Your Reserve Fund Report Card evaluates your fund's stability through comprehensive simulation testing. Each simulation checks if the fund maintains a positive balance throughout various scenarios, with thousands of tests contributing to your final score. For an in-depth look at specific tests, simply click any component. Use the Custom Configuration toggle to see how changes made in the Forecast Calculator would impact your fund's health rating.</div>
      <div className='flex-column-mobile'>
        <button className='component-container' id="meter" onClick={() => setShowReportCardModal(true)} style={{width:'60%', marginRight: '5px'}}>
          <div style={{'width': '90%', }}>
            <div className='flex-center' style={{ textAlign: "center"}}>
              <SemiCircleMeter score={score} color={colorSemiCircle} word={word} description={description}/>
            </div> 
          </div>
        </button>
        <button className='component-container' id="meter" onClick={() => setShowReportCardModal(true)} style={{width:'40%', marginLeft: '5px'}} >
          <div style={{'width':'90%', 'margin': '6px 0px'}}>
            <p className='p-pdf'>{data.dataTestBreakdown[0][0]}s</p>
            <ProgressBar variant={colorProgressBars} now={getWeight(data.dataTestBreakdown[0][1])} className='progressbar-pdf'/>
          </div>
          <div style={{'width':'90%', 'margin': '6px 0px'}}>
            <p className='p-pdf'>{data.dataTestBreakdown[1][0]}s</p>
            <ProgressBar variant={colorProgressBars} now={getWeight(data.dataTestBreakdown[1][1])} className='progressbar-pdf' />
          </div>
          <div style={{'width':'90%', 'margin': '6px 0px'}}>
            <p className='p-pdf'>{data.dataTestBreakdown[2][0]}s</p>
            <ProgressBar variant={colorProgressBars} now={getWeight(data.dataTestBreakdown[2][1])} className='progressbar-pdf' />
          </div>
          <div style={{'width':'90%', 'margin': '6px 0px'}}>
            <p className='p-pdf'>{data.dataTestBreakdown[3][0]}s</p>
            <ProgressBar variant={colorProgressBars} now={getWeight(data.dataTestBreakdown[3][1])} className='progressbar-pdf' />
          </div>
        </button>
      </div>
      </>
    )
  }

  function TotalsHeader() {
    if (!labelPoint || Object.keys(labelPoint).length === 0 ) return <></>
    const chart = labelPoint.series.chart
    const index = labelPoint.index
    const year = labelPoint.x

    var studyBenchmark = null
    var benchmark = null
    var benchmarkRange = null
    var vc = null
    var vcRange = null

    // sets label data
    chart.series.forEach(s => {
      if (s.name === "Study Benchmark")
      {
        studyBenchmark = s.yData[index]
      }
      else if (s.name === "True Benchmark")
      {
        benchmark = s.yData[index]
      }
      else if (s.name === "True Benchmark Range")
      {
        benchmarkRange = s.yData[index]
      }
      else if (s.name === "Vertical City")
      {
        vc = s.yData[index]
      }
      else if (s.name === "Vertical City Range")
      {
        vcRange = s.yData[index]
      }
    })

    var difference = benchmark - studyBenchmark
    var pos = false
    if (difference > 0) pos = true
    var numYears = year - currentYear

    function Difference() {
      return (
        <button className='component-container' style={{'width':'100%', 'paddingTop': '0px', 'paddingBottom': '10px'}} onClick={() => setShowHeaderModal(1)}>
          <div id='difference' className='flex'>
            <div style={{'width':'100%', 'marginBottom':"0px", 'whiteSpace':'nowrap'}}>
              <h5 id='difference' >Difference</h5>
              <p>(Simulated - Study)</p>
            </div>
            <div className='vertical' style={{'whiteSpace':'nowrap'}}>
              <div  className='flex'>
                <div className={'amount medium ' + (pos ? 'positive': 'negative')}>{pos ? '+': ''}{formatAmount(difference, 0)}</div>
              </div>
              <p id="difference" className='smaller right' style={{'marginTop': '-10px'}}>In {numYears} years</p>
            </div>
          </div>
        </button>
      )
    }

    function StudyBenchmark() {
      return (
        <button className='component-container' style={{'width':'100%', 'padding': '0px 10px 10px', 'marginRight':'10px'}} onClick={() => setShowHeaderModal(2)}>
            <div className='flex' style={{'height':'100%'}}>
              <div style={{'width':'90%', 'whiteSpace':'nowrap'}}>
                <h5 id='study' style={{'color':'#f1c31a'}}>Study</h5>
                <p id='portfolio-date' style={{'marginLeft': '2px', 'marginTop':'-5px'}}>As of {year}</p>
              </div>
              <div className='vertical' style={{'height':'100%'}}>
                <div  className='flex' style={{'whiteSpace':'nowrap'}}>
                  <div className='legend-size circle-legend' id='study-bm' />
                  <div className='amount medium'>{formatAmount(studyBenchmark, 0)}</div>
                </div>
              </div>
            </div>
        </button>
      )
    }

    function TrueBenchmark() {
      return (
        <button className='component-container' style={{'width':'100%', 'padding': '0px 10px 10px'}} onClick={() => setShowHeaderModal(3)}>
          <div className='flex'>
            <div style={{'width':'90%', 'whiteSpace':'nowrap'}}>
              <h5 id='simulated' style={{'color':'#5d98d1'}}>Simulated</h5>
              <p id='portfolio-date' style={{'marginLeft': '2px', 'marginTop':'-5px'}}>As of {year}</p>
            </div>
            <div className='vertical' style={{'whiteSpace':'nowrap'}}>
              <div  className='flex'>
                <div className='legend-size circle-legend' id='true-bm'/>
                <div className='amount medium'>{formatAmount(benchmark, 0)}</div>
              </div>
              <div >
                <p id='range' className='smaller right'>${formatAmountKM(benchmarkRange[0], 0) + " to $" + formatAmountKM(benchmarkRange[1], 0)}</p>
              </div>
            </div>
          </div>
        </button>
      )
    }

    return (
      <div className='totals-pdf' style={{ 'marginBottom': '10px', 'marginTop': '5px', 'gridGap': '10px', display: 'flex', flexDirection: 'row' }}>
        <div className='totals-pdf' style={{'width':'33%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
          <Difference />
        </div>
        <div className='totals-pdf' style={{'width':'66%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
          <StudyBenchmark />
          <TrueBenchmark />
        </div>
      </div>
   )
  }

  // TODO add more content to each modal; draw diagrams for absolute beginers
  // TODO improve writing of each modal v2
  function HeaderModal(props) {
    var title
    var text
    var idCircleLegend = ""
    var subheader
    if (showHeaderModal==1)
    {
      title = "Difference"
      text = <span>This is the difference between the <span style={{'color': '#5d98d1', 'font-weight': '600'}}>Simulated</span> line and the <span style={{'color': '#f1c31a', 'font-weight': '600'}}>Study</span> line. If the number is <span style={{'color': '#f55656'}}>negative</span> it means that your Reserve Fund Study is over-estimating the performance of the Reserve Fund. If it is <span style={{'color': '#98b02c'}}>positive</span>, it is under-estimating it's performance.</span>
      subheader = <div>(<span style={{'color': '#5d98d1'}}>Simulated</span> - <span style={{'color': '#f1c31a'}}>Study</span>)</div>
    }
    else if (showHeaderModal==2)
    {
      title = 'Study'
      text = "This is the same as the Closing Balance of the cashflow table. This line will show the exact forecast from the original cashflow table from the reserve fund study that is currently selected."
      idCircleLegend = "study-bm"
      subheader = <div>The Reserve Fund Study</div>
    }
    else if (showHeaderModal==3)
    {
      title = 'Simulated'
      text = "This is a more accurate forecast of the reserve fund using the same expenditures and contributions as the reserve fund study. It's basically the 2.0 version of the Cashflow Table that you are used to using. This line is simulated using forecasted interest and inflation rates as well as by using a more realistic forecasting process than that of a Reserve Fund Study."
      idCircleLegend = "true-bm"
      subheader = <div>The better forecast</div>
    }

    return (
      <Modal className="totals-modal totals" dialogClassName="modal-width" show={props.show} onHide={() => setShowHeaderModal(0)} size="lg" centered>
        <Modal.Header>
          <div>
            <div className='flex'>
              <Modal.Title>{title}</Modal.Title>
              {idCircleLegend && <div className='legend-size circle-legend' id={idCircleLegend} style={{'margin-right': '0', 'margin-left': '10px'}} />}
            </div>
            {subheader}
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className='space-between'>
          {text}
        </div>
        </Modal.Body>
      </Modal>
    )
  }

  // TODO animate this component collapsing
  function OriginalCashflow(props) {
    if (!labelPoint || Object.keys(labelPoint).length === 0 ) return <></>
    const index = labelPoint.index
    const year = labelPoint.x

    function Opening() {
      return (
        <div className='component-container' style={{'width':'100%', 'padding': '10px 15px', 'marginBottom':'0px'}}>
            <div className='flex original-container'>
              <h5 id='original' style={{width:'100%', color:'#f1c31a'}}>Opening Balance</h5>
              <div className='vertical'>
                <div id='original' className='amount' style={{'margin': '-5px 0'}}>{formatAmount(data.dataOpening[index], 0)}</div>
                {/* <p id='portfolio-date' style={{'margin-left': '2px'}}>As of {year}</p> */}
              </div>
            </div>
        </div>
      )
    }

    function Contribution() {
      return (
        <div className='component-container' style={{'width':'100%', 'padding': '10px 15px', 'marginBottom':'0px'}}>
            <div  className='flex original-container'>
              <h5 id='original' style={{width:'100%', color:'#f1c31a'}}>Contribution</h5>
              <div className='vertical'>
                <div id='original' className='amount' style={{'margin': '-5px 0'}}>{formatAmount(data.dataContribution[index][1], 0)}</div>
                {/* <p id='portfolio-date' style={{'margin-left': '2px'}}>As of {year}</p> */}
              </div>
            </div>
        </div>
      )
    }

    function Expenditures() {
      return (
        <div className='component-container' style={{'width':'100%', 'padding': '10px 15px', 'marginBottom':'0px'}}>
            <div className='flex original-container'>
              <h5 id='original' style={{width:'100%', color:'#f1c31a'}}>Expenditures</h5>
              <div className='vertical'>
                <div id='original' className='amount' style={{'margin': '-5px 0'}}>{formatAmount(data.dataExpenditures[index][1], 0)}</div>
                {/* <p id='portfolio-date' style={{'margin-left': '2px'}}>As of {year}</p> */}
              </div>
            </div>
        </div>
      )
    }

    function Interest() {
      return (
        <div className='component-container' style={{'width':'100%', 'padding': '10px 15px','marginBottom':'0px'}}>
            <div className='flex original-container'>
              <h5 id='original' style={{width:'100%', color:'#f1c31a'}}>Interest Income</h5>
              <div className='vertical'>
                <div id='original' className='amount' style={{'margin': '-5px 0'}}>{formatAmount(data.dataInterestIncome[index], 0)}</div>
                {/* <p id='portfolio-date' style={{'margin-left': '2px'}}>As of {year}</p> */}
              </div>
            </div>
        </div>
      )
    }

    function Closing() {
      return (
        <div className='component-container' style={{'width':'100%', 'padding': '10px 15px', 'marginBottom':'0px'}}>
            <div className='flex original-container'>
              <h5 id='original' style={{width:'100%', color:'#f1c31a'}}>Closing Balance</h5>
              <div className='vertical'>
                <div id='original' className='amount' style={{'margin': '-5px 0'}}>{formatAmount(data.dataClosing[index], 0)}</div>
                {/* <p id='portfolio-date' style={{'margin-left': '2px'}}>As of {year}</p> */}
              </div>
            </div>
        </div>
      )
    }

    return (
      // <Collapse in={props.show}>
      //   <div>
        <div className='flex-column-mobile totals' style={{'marginBottom': '10px', 'marginTop': '5px', 'gridGap': '5px'}}>
            <Opening/>
            <Contribution />
            <Expenditures />
            <Interest />
            <Closing />
        </div>
      //   </div>
      // </Collapse>
    )
  }

  return (
    <>
      {showOffCanvas && <OffCanvasCashflow show={showOffCanvas} selectedYear={selectedYear} selectedBuilding={auth.selectedBuilding} studyString={getStudyFromYear(selectedYear)} onSyncCharts={handleSyncCharts} onClose={handleCloseOffCanvas} inflation={data.dataInflationRate[0][1]} interest={data.dataInterestRate[0][1]} dataOpening={data.dataOpening} dataContribution={data.dataContribution} dataExpenditures={data.dataExpenditures} dataInterestIncome={data.dataInterestIncome} dataClosing={data.dataClosing} mouseOverYear={mouseOverYear} mouseOver={mouseOver} />}
      {data.showMissingRFData && <MissingData text="There are no Reserve Fund Studies linked to your account." pageSource="cashflow" />}
      {data.showProcessingData && !data.showMissingRFData && !data.showSelectStudyModal && <MissingData text="Please wait for your Reserve Fund Study to finish being processed. Refresh this page to check if it is." pageSource="cashflow" showUpload={false} />}
      {data.showSelectStudyModal && <SelectStudyModal show={data.showSelectStudyModal} availableRFStudies={data.availableRFStudies} onSelectStudy={handleSelectStudy}/>}
      {showHeaderModal>0 && <HeaderModal show={showHeaderModal>0} />}
      {showOnboardingModal && <OnboardingLinePlotModal show={showOnboardingModal} onClose={handleCloseOnboarding} />}
      {!data.showSelectStudyModal && dataExists && !data.showMissingRFData && !data.showProcessingData &&
        <div className={'content' + (showOffCanvas?' show-offcanvas':'')}>
          <div className='reserve-fund'>
            <div className='download-container'>

              <div className={auth.tourStatus == 'ReportCardIntro' ? 'intro tour-highlight':'intro'} id="ReportCardIntroTour">
                  <div className='flex-center'>
                    <h1 className="title" style={{textAlign: 'center', flex: 1, fontSize: '2.5rem'}}>Report Card</h1>
                  </div>
                  <p className='information'>
                  Understand your reserve fund's resilience through our comprehensive Report Card, which evaluates thousands of stress test scenarios. Compare your projected cash flows against our advanced simulations, which model multiple interest and inflation rate trajectories. <a target="_blank" href="https://www.verticalcityinstitute.com/post/vertical-city-toolkit-reserve-fund-forecasting">How this works »</a>
                  </p>
              </div>

              {showReportCardModal && <ReportCardModal show={showReportCardModal}></ReportCardModal>}

              <div className='flex-center study-dropdown' style={{'flexDirection': 'column', 'marginTop': '20px'}}>
                <OverlayTrigger placement="top" overlay={<Popover><PopoverBody>Select a Reserve Fund Study to be used for the forecast from the list of the uploaded studies for {auth.selectedBuilding}.</PopoverBody></Popover>}>
                <DropdownButton id='ReportCardRFSTour' title={getStudyFromYear(selectedYear)} size='lg'>
                  {data.availableRFStudies.map((study) => <Dropdown.Item onClick={() => handleSelectYear(study.year)}>{study["year"]} Study by {study["engineer"]}</Dropdown.Item>)}
                </DropdownButton>
                </OverlayTrigger>
              </div>

              <div id="ReportCardReportTour">
                <div className='header' style={{'paddingTop': '0px'}}>
                  <div className='left-pdf'>
                    <img src={logoBlue} alt="Yellow Logo" width="60" height="60" />
                    <p className='section-header'>{showParamReportCard ? "Report Card - Custom Configuration" : "Report Card - Study"}</p>
                  </div>
                  <div className='divider divider-1'></div>
                </div>

                {data.reportCardBuffering && 
                <div className= 'flex-center'>
                  <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}} />
                </div>}

                {!data.reportCardBuffering && ((showParamReportCard && data.savedConfig) || !showParamReportCard) && data.dataGrade[1] && <div className='report-card-pdf'><ReportCardMeter/></div>}
                {!data.reportCardBuffering && showParamReportCard && !data.savedConfig &&
                <div className="missing-data flex-center report-card">
                  <div style={{'flexGrow':'1'}}>
                    <h1 style={{'marginBottom':'10px'}}>There is no saved configuration</h1>
                    <h5 style={{'marginBottom':'20px'}}>Go to the calculator page to create a saved configuration</h5>
                    <div className="d-flex justify-content-center"> 
                      <Button onClick={() => {navigate("/app/calculator")}}>Go to calculator</Button>
                    </div>
                  </div>
                </div>}
              </div>

              <div id="ReportCardForecastTour">
                <div className='header' id='projected-reserve-fund'>
                  <div className='left-pdf'>
                    <img src={logoGreen} alt="Blue Logo" width="60" height="60" />
                    <p className='section-header'>Reserve Fund Forecast</p>
                  </div>
                  <div className='divider divider-2'></div>
                </div>

                {data.cashflowBuffering &&
                <div className= 'flex-center'>
                  <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
                </div>} 

                {!data.cashflowBuffering && <div className='section-description'>This chart allows you to compare your Reserve Fund Study's forecast with our Simulated forecast. The Simulated line is a forecast of the closing balance of your Reserve Fund using a more sophisticated and realistic process of forecasting the existing data from the uploaded Reserve Fund Study. To learn more about this process continue reading <a target="_blank" href="https://www.verticalcityinstitute.com/post/unveiling-the-real-standard-discovering-the-true-benchmark">here</a>.</div>}
              
                {showOriginalCashflow && <OriginalCashflow />}
                <div style={{ width: '1200px', overflow: 'auto' }}>
                  <TotalsHeader />
                </div>
                
                {!data.cashflowBuffering &&
                <div className='component' id='reserve-chart'>
                  <div className="component-container" id='reserve-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                    {data.dataTrueBenchmarkRange && <HighchartsReact highcharts={Highcharts} options={chartOptionsReserve} />}
                  </div>
                </div>}
              </div>

              <div className='watermark'>
                <Watermark />
              </div>

            </div>
            <div className='download-container'>
              <div id="ReportCardDetailsTour">
                <div className='header' id='details'>
                  <div className='left-pdf'>
                    <img src={logoYellow} alt="Green Logo" width="60" height="60" />
                    <p className='section-header'>{detailCharts ? "Reserve Fund Study Details" : "Report Card Test Failures"}</p>
                  </div>
                  <div className='divider divider-3'></div>
                </div>

                {data.cashflowBuffering &&
                <div className= 'flex-center'>
                  <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
                </div>} 

                {!data.cashflowBuffering && <>
                {detailCharts && <div className='section-description'>The following charts provide details of the currently selected Reserve Fund Study. Our system builds from the assumptions in these charts to get the forecasts above. The left chart shows projected cashflows between owner contributions and building expenditures. The right chart shows projected market assumptions and contribution increases. If you've selected to display a custom configuration, the displayed details will instead be based upon that data.</div>}
                {!detailCharts && <div className='section-description'>The following charts provide details of the report card, breaking it down and highlighting the years where simulations fail. A simple scan will give an idea of where problems exist. If you've selected to display a custom configuration, the displayed details will instead be based upon that data.</div>}
                {detailCharts && 
                <div className='component space-between detail-charts-pdf'>
                  <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                    <HighchartsReact highcharts={Highcharts} options={chartOptionsExpenditures} />
                  </div>
                  <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                    <HighchartsReact highcharts={Highcharts} options={chartOptionsRate} />
                  </div>
                </div>}
                {!detailCharts && 
                <div className='component space-between detail-charts-pdf'>
                  <div className='component-container' id='detail-chart' style={{"width": '100%'}} onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                    <HighchartsReact highcharts={Highcharts} options={chartOptionsFailures} />
                  </div>
                </div>}
                </>}
              </div>

              <div className='disclaimer'>
                Please note that Vertical City Toolkit is intended for informational purposes only and should not be construed as investment advice. 
                Condominium Boards should seek the advice of qualified professionals when making investment decisions for their Reserve Funds.
                Vertical City Toolkit does not provide financial, legal, tax or investment advice or recommendations.
              </div>
              <div className='watermark'>
                <Watermark />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
