import { useEffect, useState, useMemo, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { useTable, useSortBy } from 'react-table'
import { Button, Modal, Form, Table, Alert, OverlayTrigger, Popover, PopoverBody, Tab, Nav, Spinner } from 'react-bootstrap'

// context
import { authContext } from '../authContext.js'
import { dataContext } from '../dataContext.js'

// Components
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import MissingData from '../MissingData.js'

// Helper Functions
import { dateConversion, chartExtremes } from '../../lib/helpers/highchartsHelpers.js'
import { formatAmount, formatDate, formatDashesToString, formatDateDashes, formatAmountKM, getPercent, todaysDate, helperDateDifference, YYYYMMDDtoDate } from '../../lib/helpers/formattingHelpers.js'

// logo svgs
import logo from '../../svg/logo.svg'
import logoBlue from '../../svg/mini-logo-blue.svg'
import logoGreen from '../../svg/mini-logo-green.svg'
import question from '../../svg/grey-question.svg'

// onboarding svgs
import nextactionModal from '../../svg/nextaction-modal-01.svg'

// bank icon svgs
import getBankIcon from '../../svg/banks/interfaceBankIcons.js'

export default function Dashboard() {
  // context
  const auth = useContext(authContext)
  const data = useContext(dataContext)

  const [actionSlide, setActionSlide] = useState(0) // 0,1,2,etc.
  
  const [detailView, setDetailView] = useState(false)

  const [showSnapshotModal, setShowSnapshotModal] = useState(false)

  const [hideCashBalanceAlert, setHideCashBalanceAlert] = useState(true)
  //const [expensesExists, setExpensesExists] = useState(false)

  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false)
  const [currentAction, setCurrentAction] = useState(null)

  // was using useMemo because chart would refresh every time state changes, now unnecessary when setting chart options with state
  // const historicalDataGraph = useMemo(() => HistoricalPerformance(), [dataExists, currentCashBalance])
  const [chartOptionsHistorical, setChartOptionsHistorical] = useState({})
  
  const [showInvestmentsTable, setShowInvestmentsTable] = useState(true)
  const [chartOptionsMaturity, setChartOptionsMaturity] = useState({})

  // for mobile screens
  const [mobileScreen, setMobileScreen] = useState(true)
  const [maturityChartHeight, setMaturityChartHeight] = useState('20%')
  const [historicalChartHeight, setHistoricalChartHeight] = useState('60%')
  const [chartSpacing, setChartSpacing] = useState([20,20,20,20])
  const [chartPointWidth, setChartPointWidth] = useState(100)

  // page tab stuff
  const [pageSource, setPageSource] = useState("next actions")

  const [dataExists, setDataExists] = useState(false)
  
  const navigate = useNavigate()
  
  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // main hook for getting all data for page
  useEffect(() => {
    if (auth.isContextSecured) 
    {
      data.fetchDashboardData()
    }
  }, [auth.isContextSecured])

  useEffect(()=>{
    setPageSource(data.pageSource)
  }, [data.pageSource])

  //Formerly fully dependent on data.dataDashboardExists, but data.dataHistorical.length must also be checked to avoid errors
  useEffect(() => {
    setDataExists(data.dataDashboardExists && data.dataHistorical.length !== 0)
    
  }, [data.dataDashboardExists, data.dataHistorical])

  // Maturity Chart
  useEffect(() => {
    if (data.cumulativeDataMaturity.length > 0)
    {
        const extremes = chartExtremes(data.cumulativeDataMaturity)
        const currentYear = new Date().getFullYear()
        setChartOptionsMaturity({
          chart: {
            borderRadius: 10, // same as other divs in index.css 
            spacing: chartSpacing,
            backgroundColor: "#ffffff",
            height: maturityChartHeight,
          },
          title: {
            text: 'details',
            style: {
              display: 'none'
            }
          },
          legend: {
            itemStyle: {
              'cursor': 'default'
            },
            enabled: !mobileScreen
          },
          tooltip: {
            shared: true,
            crosshairs: true,
            valuePrefix: '$',
            valueDecimals: 0,
            formatter: function (tooltip) {
              const header = '<b>Year ' + this.x + '</b><br/>'
              return mobileScreen ? false:(header + (tooltip.bodyFormatter(this.points).join('')))
            }
          },
          xAxis: [{
            visible: true,
            plotLines: [{
              color: '#000000',
              width: 2,
              value: currentYear,
              label: 'Current year'
            }],
          }],
          yAxis: [{
            visible: true,
            title: { enabled: false, text: "Canadian Dollars" },
            startOnTick: false,
            endOnTick: false,
            plotLines: [{
              color: '#000000',
              width: 2,
              value: 0,
              dashStyle: 'ShortDot',
              label: 'Zero'
            }],
            labels: {
              formatter: function () {
                if (this.value != null)
                {
                  return formatAmountKM(this.value)
                }
              }
            },
            tickPositioner: function () {
              if (this.tickPositions)
              {
                return [0, extremes.max]
              }
            }
          }],
          series: [
            {
              name: 'Amount',
              type: 'column',
              data: data.dataMaturity,
              color: '#5d98d1',
              marker: {
                enabled: false
              }
            },

            {
              name: 'Cumulative Amount',
              type: 'spline',
              data: data.cumulativeDataMaturity,
              color: '#fbd57e',
              marker: {
                enabled: false
              }
            }
          ],
          plotOptions: {
            series: {
              pointWidth: chartPointWidth,
              // disables legend
              events: {
                legendItemClick: function () {
                  return false
                }
              }
            }
          },
          credits: {
              enabled: false
          }
        })
    }
  }, [data.cumulativeDataMaturity, mobileScreen])

  // Account History Chart
  useEffect(() => {
    if (data.dataHistorical.length > 0)
    {
      setChartOptionsHistorical({
        chart: {
          type: "line",
          height: historicalChartHeight,
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          // borderRadius: 25, 
          // backgroundColor: "#f5f5f5",
          // taken from online - this will make the chart yellow if estimate, blue if not
          // http://jsfiddle.net/BlackLabel/jmckag4q/
          events: {
            load: function () {
              this.series.forEach(s => {
                const endpoint = s.points.length
                s.points[endpoint - 1].onMouseOver()
              })
            }
          }
        },
        title: {
          text: 'history',
          style: {
            display: 'none'
          }
        },
        legend: {
          enabled: !mobileScreen
        },
        tooltip: {
          split: false,
          shared: true,
          valuePrefix: '$',
          valueDecimals: 2,
          crosshairs: true,
          // gets rid of normal tooltip
          formatter: function () {
            return false
          }
        },
        // controls zoom
        rangeSelector: {
          enabled: true,
          allButtonsEnabled: true,
          buttons: [
            {
              type: 'year',
              count: 1,
              text: '1 Year'
            },
            {
              type: 'year',
              count: 3,
              text: '3 Years'
            },
            {
              type: 'all',
              text: 'All'
            }
          ],
          buttonTheme: {
            width: 50
          },
          inputStyle: {
            color: '#5d98d1', // Change the color of the text
          },
          // defaults zoom to All, since colours get messed up when starting on 1 year
          selected: 0
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        xAxis: [{
          visible: true,
          crosshair: true,
          // sets ticks to be at the start and end of the graph
          tickPositioner: function () {
            if (this.tickPositions)
            {
                return [this.min, data.estimateEnds, this.max]
            }
          },
          // draws line where estimate stops
          plotLines: [{
            color: '#f1c31a',
            width: 2,
            value: data.estimateEnds,
            label: 'Estimate'
          }],
          labels: {
            formatter: function () {
              if (this.value === data.estimateEnds) return "Estimate"
              else return this.axis.defaultLabelFormatter.call(this)
            }
          },
          events: {
            afterSetExtremes() {
              // highlights endpoint when zoom changes
              const endpoint = this.chart.series[0].points.length
              this.chart.series.forEach(s => {
                s.points[endpoint - 1].onMouseOver()
              })
            }
          }
        }],
        yAxis: [{
            visible: true,
            opposite: false,
            startOnTick: false,
            endOnTick: false,
            showLastLabel: true,
            // since dataHistorical is an array of objects, create new array of just y value using map to find max
            // this is a lot of code on one line right now, can probably be cleaned up
            max: data.dataHistorical[data.dataHistorical.length - 1]["y"] * 1.68 > Math.max(...data.dataHistorical.map(o => o.y)) ? data.dataHistorical[data.dataHistorical.length - 1]["y"] * 1.68 : Math.max(...data.dataHistorical.map(o => o.y)) * 1.02,
            // sets ticks to be 0 and maximum value
            tickPositioner: function () {
              if (this.tickPositions)
              {
                  const max = data.dataHistorical[data.dataHistorical.length - 1]["y"]
                  // return [0, max]
                  return [0, max]
              }
            },
            // formatting chart labels
            labels: {
              formatter: function () {
                  if (this.value != null) return formatAmountKM(this.value)
              }
            },
        }],
        plotOptions: {
          areaspline: {
            fillOpacity: 0.5
          },
          series: {
            point: {
                events: {
                  mouseOver() {
                    refreshLabel(this)
                  }
                }
            }
          }
        },
        series: [
          {
            name: 'Portfolio',
            type: 'areaspline',
            data: data.dataHistorical,
            lineWidth: 0,
            color: "#5d98d1",
            pointStart: Date.parse(formatDateDashes(data.allHistoryData["date"][0])),
            pointIntervalUnit: 'month',
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 4
            },
            zoneAxis: "x"
          },
          {
            name: 'Investments',
            type: 'areaspline',
            data: data.allHistoryData["investment"],
            lineWidth: 0,
            color: '#a7b661',
            pointStart: Date.parse(formatDateDashes(data.allHistoryData["date"][0])),
            pointIntervalUnit: 'month',
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 4
            },
            fillOpacity: 1 // change to 0.5 to revert to old version
          }
        ],
        credits: {
          enabled: false
        }
      })
    }
  }, [data.dataHistorical, mobileScreen])

  // updates chart properties based on window size
  const resizeMobile = () => {
    if (window.matchMedia("(max-width: 560px)").matches) {
      setMaturityChartHeight('40%')
      setHistoricalChartHeight('70%')
      setChartSpacing([10,0,0,0])
      setChartPointWidth(35)
      setMobileScreen(true)
    } else if(!window.matchMedia("(max-width: 560px)").matches ) {
      setMaturityChartHeight('25%')
      setHistoricalChartHeight('60%')
      setChartSpacing([20,20,20,20])
      setChartPointWidth(100)
      setMobileScreen(false)
    }
  }

  //Set up risize event listener for chart mobile views
  useEffect(() => {

    //Initialize state variables
    resizeMobile()

    // Add event listener for window resize
    window.addEventListener('resize', resizeMobile)

  }, [])

  // handles when user clicks on suggested action
  function handleMoreInfoModal(action) {
    setCurrentAction(action)
    setShowMoreInfoModal(true)
  }

  // -------------------------------------------------------------------------------
  // Components
  // -------------------------------------------------------------------------------

  // suggested action info component
  function SuggestedAction(props) {
    function remainingDays(date) {
      // check if date is today
      if (helperDateDifference(dateConversion(date), new Date()) === 0) 
      {
        return (
          <div>            
            <p className='target-date'>{formatDate(dateConversion(date))}</p>
            <div className='days-remaining'> Today</div>
          </div>
        )
      }
      // check if date is tomorrow
      else if (helperDateDifference(dateConversion(date), new Date()) === 1) 
      {
        return (
          <div>            
            <p className='target-date'>{formatDate(dateConversion(date))}</p>
            <div className='days-remaining'>(In {helperDateDifference(dateConversion(date), new Date())} day)</div>
          </div>
        )
      }
      else
        return (
          <div>            
            <p className='target-date'>{formatDate(dateConversion(date))}</p>
            <div className='days-remaining'>(In {helperDateDifference(dateConversion(date), new Date())} days)</div>
          </div>
        )
    }

    function purchaseOmit(getAction) {
      if (getAction.search('Market Linked') > -1) return "MLGIC"
      else return "GIC"      
    }

    // Helper function to get GIC term from event string
    function getGICTerm(getAction){
      const strEventText = props.action["event"]
      if (getAction.search('Market Linked') > -1) return strEventText.split(' ')[5].substring(1,2)            
      else return strEventText.split(' ')[3].substring(1,2)
    }

    data.setNumSlides(5)
    var width = '20%'

    calcNumSlides()
    window.addEventListener('resize', calcNumSlides)

    // Function to calculate number of next action slides to show on the screen
    function calcNumSlides() {
      if ((window.matchMedia("(max-width: 1340px)").matches && !window.matchMedia("(max-width: 560px)").matches)) {
        data.setNumSlides(4)
        width = '25%'
      } else if (window.matchMedia("(max-width: 560px)").matches) {
        data.setNumSlides(2)
        width = '50%'
      } else {
        data.setNumSlides(5)
        width = '20%'
      }
    }

    var start = 0 + (data.numSlides*actionSlide)
    var end = (data.numSlides-1) + (data.numSlides*actionSlide)

    //<div id="available-cash">({getPercent(props.action["amount"], currentCashBalance, true)}% of cash balance) </div>
    return (
      (props.index >= start) && (props.index <= end) && 
      <div className="suggested-action component-container" style={{'width': width}} id={purchaseOmit(props.action["event"]).split(' ')[0]}>
          <div id='first-spacing'>
            <div>Purchase a
              <div className='action-event'>{getGICTerm(props.action["event"])} Year {purchaseOmit(props.action["event"])}</div>
              for
            </div>
            <div className='amount-adjuster'>{formatAmount(props.action["amount"], 0)}</div>
            <div className="available-cash"> </div>              
          </div>
          <div className='global-spacing' id='second-spacing'>
            {remainingDays(props.action["date"])}
          </div>
          <button className='global-spacing' id="more-info" onClick={() => { handleMoreInfoModal(props.action) }}>More Info</button>
      </div>
    )
  }

  function SnapshotModal(props) {
    const [disableCash, setDisableCash] = useState(true)
    const [disableExpense, setDisableExpense] = useState(true)

    function changeDateCash(e) {
      const date = Number(e.target.value.replaceAll('-', ''))
      const today = Number(todaysDate().replaceAll('-', ''))
      setDisableCash(date > today)
    }

    function changeDateExpense(e) {
      const date = Number(e.target.value.replaceAll('-', ''))
      const today = Number(todaysDate().replaceAll('-', ''))
      setDisableExpense(date > today)
    }

    function handleSubmitCashBalance(e) {
      e.preventDefault()
      const cashBalance = e.target.cash.value
      const date = e.target.date.value
      
      if (!cashBalance) return
      if (!date) return

      data.uploadCashBalance(cashBalance, date)
    }

    function handleSubmitExpenses(e) {
      e.preventDefault()
      const date = e.target.date.value
      const expenses = e.target.expense.value

      if (!expenses) return
      if (!date) return

      data.uploadExpenses(expenses, date)
    }

    function FutureExpenses() {
      return (
        data.dataFutureExpenses.map((data, index) => (
          <div className='last-updated'>{formatAmount(data["amount"], 0)} on {formatDate(YYYYMMDDtoDate(data["date"]))}</div>          
        ))
      )
    }

    return (
        <Modal className="snapshot-modal" dialogClassName="modal-width" show={props.show} onHide={() => setShowSnapshotModal(false)} size="md">
        <Modal.Header closeButton><Modal.Title>Your Reserve Fund Snapshot</Modal.Title></Modal.Header>
        <Modal.Body>
          <div>
            To help us accurately simulate your reserve fund and generate a list of next actions, we need a quick snapshot of your reserve fund details. Please provide the following:
          </div>
          <div style={{'marginTop': '12px'}} className="manage-cash-balance-header">
            <div className='component-title'>
                <div>
                  <h5 className='snapshot-label'>Cash Balance</h5>
                  <div style={{'font-size': '15px', 'width': '350px'}}>The total amount of cash in your reserve fund on a given date.</div>
                </div>
                <div>
                  <h5 style={{'textAlign': 'right'}}>{formatAmount(data.currentCashBalance, 0)}</h5>
                  <div className='last-updated'>Updated {data.daysSinceUpdateCash} days ago</div>
                  <div className='last-updated'>{formatDashesToString(data.currentCashBalanceDate)}</div>
                </div>
            </div>
            <Form className='flex snapshot-form' onSubmit={handleSubmitCashBalance}>
              <label style={{'whiteSpace': 'nowrap', 'width': '100%', 'margin': 'auto'}}><b>Add Balance</b></label>
              <Form.Group className="dollar-prefix" style={{'width': '100%'}} size="sm" >
                <Form.Control defaultValue={data.currentCashBalance} type="text" placeholder="Cash" name="cash"/>
              </Form.Group>
              <Form.Group size="sm" style={{'width':'100%'}}>
                <Form.Control style={{'width': '100%'}} type="date" placeholder="Date" name="date" onChange={changeDateCash} />
              </Form.Group>
              <Button size="sm" variant={data.updatedCashBalance ? "success" : "secondary"} type="submit" disabled={disableCash}>Update</Button>
            </Form>
            <div className='component-title' style={{'marginTop': '18px'}}>
                <div>
                  <h5 className='snapshot-label'>Future Expenses</h5>
                  <div style={{'font-size': '15px', 'width': '350px'}}>Any upcoming expenses that aren’t already included in your reserve fund study.</div>
                </div>
                <div>
                  <h5 style={{'textAlign': 'right'}}>{formatAmount(data.sumFutureExpenses, 0)}</h5>
                  <FutureExpenses />
                </div>
            </div>
            <Form className='flex snapshot-form' onSubmit={handleSubmitExpenses}>
              <label style={{'whiteSpace': 'nowrap', 'width': '100%', 'margin': 'auto'}}><b>Add Expense</b></label>
              <Form.Group className="dollar-prefix" style={{'width': '100%'}} size="sm" >
                <Form.Control defaultValue={data.currentExpenses} type="text" placeholder="Expense" name="expense"/>
              </Form.Group>
              <Form.Group size="sm" style={{'width':'100%'}}>
                <Form.Control style={{'width': '100%'}} type="date" placeholder="Date" name="date" onChange={changeDateExpense} />
              </Form.Group>
              <Button size="sm" variant={data.updatedExpenses ? "success" : "secondary"} type="submit" disabled={disableExpense}>Update</Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  // modal component for suggested actions
  function MoreInfoModal(props) {

    let date = YYYYMMDDtoDate(currentAction["date"])
    let term = currentAction["event"].match(/\(([^)]+)\)/)[1].replace(" Term", "")
    let type = currentAction["event"].split(" ")[2]
    let x = "218"
    if (type = "Market")
    {
      type = "MLGIC"
      x = "212"
    }
    let dayDiff = helperDateDifference(date, new Date())

    return (
      <Modal show={props.show} onHide={() => setShowMoreInfoModal(false)} size="md" className="more-info-modal" dialogClassName="modal-width">
        <Modal.Header>
          <Modal.Title>Selected Next Action</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{'borderRadius': '15px'}}>
          <svg viewBox="0 0 550 400" xmlns="http://www.w3.org/2000/svg">
            <image href={nextactionModal} x="0" y="0" height="400" width="550" />
            <text x="215" y="45">Purchase a</text>
            <text x={x} y="65" style={{'fontSize': '15px'}}>{term} {type}</text>
            <text x="245" y="82" style={{'fontSize': '12px'}}>for</text>
            <text x="215" y="100" style={{'fontSize': '18px', 'fontWeight': '600'}}>{formatAmount(currentAction.amount, 0)}</text>
            <text x="225" y="125" style={{'fontSize': '12px'}}>(In {dayDiff} days)</text>
            <text x="220" y="390">{formatDate(date)}</text>
          </svg>
        </Modal.Body>
      </Modal>
    )
  }

  // list of investments component
  function InvestmentBreakdown() {
    const currentDate = new Date()

    console.log('showInvestmentsTable', showInvestmentsTable)
    return (
      <div className='investment-breakdown'>
        {data.dataInvestments && Object.keys(data.dataInvestments).map((key) => (
            <div className='investment-card'>
              {(getBankIcon(key) === undefined) && <h3 className='header-alignment'>{key.toUpperCase()}</h3>}
              {getBankIcon(key) && <img src={getBankIcon(key)["img"]} alt={`${key}-icon`} height={getBankIcon(key)["height"]} width={getBankIcon(key)["width"]} />}
              <div className='total-amount'><td><p><b>{formatAmount(data.dataInvestments[key]["totalamount"], 0)}</b> </p></td></div>
              <OverlayTrigger placement="top" overlay={
                <Popover id="investment-popover">
                  <PopoverBody>
                      <Table>
                        <thead>
                            <tr>
                              <th>Amount</th>
                              <th>Rate</th>
                              <th>Maturity Date</th>
                            </tr>
                        </thead>
                        <tbody>
                          {data.dataInvestments[key]["data"].map((row) => 
                            {
                              return (
                                <tr>
                                  <td>{formatAmount(row["amount"], 0)}</td>
                                  <td>{row["type"] === "MLGIC" ? "-" : formatAmount(row["rate"] * 100, 3, false)}</td>
                                  <td style={currentDate >= dateConversion(row["maturitydate"]) ? { color: 'red' } : {}}>{formatDate(dateConversion(row["maturitydate"]))}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                  </PopoverBody>
                </Popover>
                }>
                <div className='three-dot-icon flex-center'>. . .</div>
              </OverlayTrigger>

            </div>
        ))}
      </div>
    )
  }

  function TotalsHeader() {
    const date = new Date()
    var currentYear = date.getFullYear()
    var maturing
    for (let i = 0; i < data.cumulativeDataMaturity.length; i++) {
      if (currentYear == data.cumulativeDataMaturity[i][0]) 
      {
        maturing = data.cumulativeDataMaturity[i][1]
        break
      }
    }

    // NaN checker helper
    function is_nan(value) {
      return value != value
    }

    // Return maturing amount text
    function GetMaturingAmountText(nInvestmentAmount, nMaturing){
      if (!is_nan(parseFloat(nMaturing)))
      {        
        return formatAmount(investmentsAmount > 0 ? "+" + maturing : "--", 0)
      } 
      else
      {
        return formatAmount(0, 0)
      }
    }

    var portfolioAmount = data.dataHistorical[data.dataHistorical.length-1]["y"]
    var portfolioAmountFormat = formatAmount(portfolioAmount, 0)
    var portfolioLastAmount = data.dataHistorical[data.dataHistorical.length-2]["y"]

    // total investments
    var investmentsAmount = data.allHistoryData["investment"][data.allHistoryData["investment"].length-1]
    var investmentsAmountFormat = formatAmount(investmentsAmount, 0)

    // regular gic
    var investmentsAmountGIC = data.allHistoryData["investmentgic"][data.allHistoryData["investmentgic"].length-1]
    var investmentsAmountGICFormat = formatAmount(investmentsAmountGIC, 0)

    // market linked gic
    var investmentsAmountMLGIC = investmentsAmount - investmentsAmountGIC
    var investmentsAmountMLGICFormat = formatAmount(investmentsAmountMLGIC, 0)

    var cashAmount = portfolioAmount - investmentsAmount
    var cashAmountFormat = formatAmount(cashAmount, 0)

    var contributionsAmountFormat = '--'
    if (data.contributionsAmount) contributionsAmountFormat = formatAmount(data.contributionsAmount, 0)
    var expendituresAmountFormat = '--'
    if (data.expendituresAmount) expendituresAmountFormat = formatAmount(data.expendituresAmount + data.sumFutureExpenses, 0)
    var netAmount = data.contributionsAmount - data.expendituresAmount - data.sumFutureExpenses
    var netAmountFormat = formatAmount(netAmount, 0)

    var ratioInvestments = getPercent(investmentsAmount, portfolioAmount)
    var ratioCash = getPercent(cashAmount, portfolioAmount)
    var changePortfolio = (((portfolioAmount / portfolioLastAmount) - 1) * 100).toFixed(2)
    var conditionalChangePortfolio = (changePortfolio > 0) ? 'positive' : 'negative'
    var conditionalChangeFunding = (netAmount > 0) ? 'positive' : 'negative'

    var tempDate
    if (data.currentCashBalanceDate == null) tempDate = formatDateDashes(data.allHistoryData.date[data.allHistoryData.date.length-1])
    else tempDate = data.currentCashBalanceDate
    var portfolioDate = formatDashesToString(tempDate, 'short')
    if (data.dataHistoricalLastDate > Number(tempDate.replaceAll('-',''))) portfolioDate = formatDashesToString(formatDateDashes(tempDate), 'short')

    return (
      <div className='flex-column-breakpoint totals'>
        <div className='component-container' style={{'width':'100%', 'marginBottom': '10px', 'marginRight':'5px', 'paddingTop': '0px'}}>
            <div className='space-between' style={{'flexWrap':'wrap'}}>
              <div className='vertical'>
                <h5>Reserve Fund</h5>
                <p id='portfolio-date' style={{'marginTop':'0px'}}>As of {portfolioDate}</p>
              </div>
              <div className='vertical'>
                <div className='amount big'>{portfolioAmountFormat}</div>
                <div style={{'textAlign': 'top'}}>
                  <p id='percentage' className={'small right ' + conditionalChangePortfolio}>{(changePortfolio > 0) ? "+" : "-"}%{Math.abs(changePortfolio)}</p>
                </div>
              </div>
            </div>
        </div>
        <div className="flex-column-mobile" style={{'width':'100%'}}>
          <button onClick={() => setShowSnapshotModal(true)} className='component-container' style={{'width':'100%', 'margin': '0px 5px 10px', 'paddingTop': '8px'}}>
            <div className='space-between' style={{'flexWrap':'wrap'}}>
              <h5 style={{'marginTop': '10px'}}>Cash</h5>
              <div className='vertical'>
                <div className='amount'>{cashAmountFormat}</div>
                <p className='right' style={{'whiteSpace':'nowrap'}}>%{ratioCash} of reserve fund</p>
              </div>
            </div>
            <div className='space-between' style={{'flexWrap':'wrap'}}>
              <div className='vertical'>
                <h5>Contributions</h5>
                <p>(this year)</p>
              </div>
              <div className='amount small'>{data.contributionsAmount ? '+': ''}{contributionsAmountFormat}</div>
            </div>
            <div className='space-between' style={{'flexWrap':'wrap'}}>
              <div className='vertical'>
                <h5>Expenditures</h5>
                <p>(this year)</p>
              </div>
              <div className='amount small'>{data.contributionsAmount ? '-': ''}{expendituresAmountFormat}</div>
            </div>
            <div className='space-between' style={{'flexWrap':'wrap'}}>
              <div className='vertical'>
                <h5>Net Funding</h5>
                <p>(this year)</p>
              </div>
              <div className={'amount small ' + conditionalChangeFunding}>{conditionalChangeFunding ? "+" : "-"}{netAmountFormat}</div>
            </div>
          </button>
          <div className='component-container' style={{'width':'100%', 'margin': '0px 5px 10px', 'paddingTop': '8px'}}>
            <div className='space-between' style={{'flexWrap':'wrap'}}>
              <h5 style={{'marginTop': '10px'}}>Invested</h5>
              <div className='vertical'>
                <div className='amount'>{investmentsAmount > 0 ? investmentsAmountFormat : "--"}</div>
                <p className='right'>{investmentsAmount > 0 ? "%" + ratioInvestments + " of reserve fund": "--"}</p>
              </div>
            </div>
            <div className='space-between' style={{'flexWrap':'wrap'}}>
              <div className='vertical'>
                <h5>Total GIC</h5>
                <p>(regular)</p>
                </div>
              <div className='amount small'>{investmentsAmountGICFormat}</div>
            </div>
            <div className='space-between' style={{'flexWrap':'wrap'}}>
              <div className='vertical'>
                <h5>Total MLGIC</h5>
                <p>(market linked)</p>
              </div>
              <div className='amount small'>{investmentsAmountMLGICFormat}</div>
            </div>
            <div className='space-between' style={{'flexWrap':'wrap'}}>
              <div className='vertical'>
                <h5>Maturing</h5>
                <p>(this year)</p>
              </div>
              <div className='amount small'>                            
                <div>{GetMaturingAmountText(investmentsAmount, maturing)}</div>              
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // -------------------------------------------------------------------------------
  // Reactable
  // -------------------------------------------------------------------------------

  // Table for Suggested Actions
  function SuggestedActionsReactTable(props) {

    // const data = useMemo(() => props.data, [])
    const data = props.data
    const columns = [
      {
        Header: 'Date',
        accessor: 'date',
        width: '10%',
      },
      {
        Header: 'Event',
        accessor: 'event',
        width: '70%',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        width: '15%',
      }
    ]

    const {
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow

    } = useTable({
      columns,
      data
    })

    // TODO click action event to filter only action events on table
    return (
      <Table hover>
        <thead className='stick-header-style'>
            {
              headerGroups.map((headerGroup) => (
                <tr  {...headerGroup.getHeaderGroupProps()}>
                  {
                      headerGroup.headers.map((column) => (
                        <th className='header-hover' style={column.width ? { width: column.width } : {}}>
                            <div className='header-padding' {...column.getHeaderProps({ title: undefined })}>
                              {column.render('Header')}
                            </div>
                        </th>
                      ))
                  }
                </tr>
              ))
            }
        </thead>
        <tbody {...getTableBodyProps()}>
            {
              rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} style={row["original"]["action"] ? { backgroundColor: '#a6b661d3', fontWeight: 600} : {}}>
                      {
                        row.cells.map((cell) => (
                          <td {...cell.getCellProps()} style={cell.column.width ? { width: cell.column.width } : {}}>
                            {cell.column.Header === 'Amount'
                            ? formatAmount(cell.value)
                            : cell.column.Header === 'Date'
                            ? formatDate(dateConversion(cell.value))
                            : cell.render('Cell')}
                          </td>
                        ))
                      }
                  </tr>
                )
              })
            }
        </tbody>
      </Table>
    )
  }

  // Table for Investment Breakdown
  function InvestmentReactTable(props) {
    // sets Year Diff to Year Diff. here to keep formatData generalized (since there cannot be a period in the accessor of a column header)
    const columns = useMemo(() => {
      props.columns[5].Header = 'Year Diff.'
      return props.columns
    }, []) // empty dependency array.
    const tableData = useMemo(() => props.tableData, [])

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable({
      columns,
      data: tableData, // Change `tableData` to `data` here.
      initialState: {
        sortBy: [
          { id: 'Year Diff', desc: false },
          { id: 'Amount', desc: false }
        ]
      }
    }, useSortBy)

    // necessary to create black dividing border for years left
    var prevYearsLeft = null

    const currentDate = new Date()
    const activeInvestmentAmount = data.allHistoryData["investment"][data.allHistoryData["investment"].length - 1]

    // maximum and minimum investment value in order to determine amount gradient, give each a 50% offset to ensure full yellow and white are never achieved
    var investmentMax = Math.max(...tableData.map(investment => amountToInt(investment.Amount)))
    investmentMax += investmentMax * 0.5
    var investmentMin = Math.min(...tableData.map(investment => amountToInt(investment.Amount)))
    investmentMin -= investmentMin * 0.5

    // converts amount with $ to int
    function amountToInt(amount) {
      return parseInt(amount.slice(1).replaceAll(',', ''))
    }

    // handles sorting the Amount with Year Diff
    function handleToggleSort(column, amountColumn) {     
      if (column.Header === "Year Diff.")
      {
        var descending = false
        // note that the column that is passed into the onClick is before the sorting has occured, therefore, if the column is sorted ascending, 
        // it must now be descending, etc.
        if (column.isSortedDesc === false)
        {
          descending = true
        }
        else if (column.isSortedDesc === true)
        {
          // resets sorting
          column.clearSortBy()
          return
        }
        // second parameter enables multi-sort
        column.toggleSortBy(descending, true)
        amountColumn.toggleSortBy(descending, true)
      }
    }

    // handles the colour of the arrow
    function handleArrow(column) {
      if (column.isSortedDesc == true)
      {
        return <>
          <div id="unsorted-ascending">▲</div>
          <div id="sorted-descending">▼</div>
        </>

      }
      else if (column.isSortedDesc == false)
      {
        return <>
          <div id="sorted-ascending">▲</div>
          <div id="unsorted-descending">▼</div>
        </>
      }
      else
      {
        return <>
          <div id="unsorted-ascending">▲</div>
          <div id="unsorted-descending">▼</div>
        </>
      }
    }

    // style={column.Header == "Year Diff." ? { width: '50px' } : {}
    return (
      <Table {...getTableProps} hover >
        <thead className='stick-header-style'>
          {
            headerGroups.map((headerGroup) => (
              <tr  {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map((column) => (
                    <th className='header-hover' style={{'whiteSpace':'nowrap'}} onClick={() => handleToggleSort(column, headerGroup.headers[1])} >
                      <div className='header-padding' {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}>
                        {column.render('Header')}
                        <div className='sorter'>
                          {handleArrow(column)}
                        </div>
                      </div>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>

        <tbody className='breakdown-body' {...getTableBodyProps()}>
            {
              rows.map(row => {
                  
                  prepareRow(row)
                    


                  // as the rows are being interated through, keep track of previous value in "Year Diff" - if different, show border
                  var showTopBorder = false
                  // checks if year diff is sorted
                  if (row.cells[5].column.isSorted)
                  {
                    if (prevYearsLeft != row["values"]["Year Diff"])
                    {
                        showTopBorder = true
                    }
                    prevYearsLeft = row["values"]["Year Diff"]
                  }
                  return (
                    <tr {...row.getRowProps()} style={showTopBorder ? { 'borderTop': '2px solid black' } : {}}>
                        {
                          row.cells.map((cell) => {
                            if (cell.column.Header === 'Issuer')
                            {
                              return (
                                <td {...cell.getCellProps()} style={{'alignItems': 'center', 'display': 'flex', 'height': '51px', 'justifyContent': 'center'}}>
                                  {/* {cell.render('Cell')}     */}
                                  <img className='issuer-img' src={getBankIcon(cell.value.toLowerCase())["img"]} alt={`${cell.value.toLowerCase()}-icon`}
                                    height={getBankIcon(cell.value.toLowerCase())["height"]} width={getBankIcon(cell.value.toLowerCase())["width"]}  />
                                </td>
                              )
                            }
                            // bolding Year Diff + adding border
                            else if (cell.column.Header === 'Year Diff.' || cell.column.Header === 'Issue Date')
                            {
                              const borderLeft = { 'borderLeft': '2px solid black' }
                              return (
                                <td {...cell.getCellProps()} style={cell.column.Header === 'Year Diff.' ? { 'fontWeight': 'bold', 'borderRight': '2px solid black' } : { ...borderLeft }}>
                                  <div className='table-cell'>
                                    {cell.render('Cell')}
                                  </div>
                                </td>
                              )
                            }
                            // if investment has matured, make text red
                            else if (cell.column.Header === 'Maturity Date' && new Date(cell.value) < currentDate)
                            {
                              return (
                                <td {...cell.getCellProps()}>
                                  <div className='table-cell' style={{ 'color': 'red', 'fontWeight': 'bold'}}>
                                    {cell.render('Cell')}
                                  </div>
                                </td>
                              )
                            }
                            // gives amount cell a gradient based on how close the amount is to the maximum or minimum investment
                            else if (cell.column.Header === 'Amount')
                            {
                              // OUTDATED: this gradient shouldn't be multiplied by anything, however, since the majority of the individual investments are small in comparison
                              // to the total active amount, it is necessary to notice the color
                              // const gradient = (amountToInt(cell.value) / activeInvestmentAmount)

                              const gradient = (amountToInt(cell.value) - investmentMin) / (investmentMax - investmentMin)
                              return (
                                <td {...cell.getCellProps()} >
                                  <div className='table-cell' style={{ 'backgroundColor': `rgba(241, 195, 26, ${gradient})` }}>
                                    {cell.render('Cell')}
                                  </div>
                                </td>
                              )
                            }
                            // gives rate cell a gradient based on how close the rate is to 8
                            else if (cell.column.Header === 'Rate')
                            {
                              const gradient = cell.value / 8
                              return (
                                <td {...cell.getCellProps()} >
                                  {
                                  row.original["Type"] === "MLGIC" ? 
                                    <div className='table-cell'>-</div> 
                                    :
                                  
                                    <div className='table-cell' style={{ 'backgroundColor': `rgba(167, 182, 97, ${gradient})` }}>
                                      {cell.render('Cell')}
                                    </div>
                                  }
                                </td>
                              )
                            }
                            else
                            {
                              return (
                                <td {...cell.getCellProps()}>
                                  <div className='table-cell'>
                                    {cell.render('Cell')}
                                  </div>
                                </td>
                              )
                            }
                        })
                      }
                  </tr>
                )
              })
            }
        </tbody>
      </Table >
    )
  }

  // -------------------------------------------------------------------------------
  // Highcharts
  // -------------------------------------------------------------------------------

  function refreshLabel(highcharts) {
    var chart = highcharts.series.chart
    var point = highcharts.index
    var rawDate = highcharts.x
    var numMonth = parseInt(Highcharts.time.dateFormat('%m', rawDate))
    var numYear = parseInt(Highcharts.time.dateFormat('%Y', rawDate))
    var portfolio = formatAmount(data.dataHistorical[point]["y"], 0)
    var shortenedPortfolio = formatAmountKM(data.dataHistorical[point]["y"])
    var investments = formatAmount(data.allHistoryData["investment"][point], 0)
    var cashBalance = formatAmount(data.dataHistorical[point]["y"] - data.allHistoryData["investment"][point], 0)

    // investment ratio to total portfolio
    var investmentPercent = getPercent(data.allHistoryData["investment"][point], data.dataHistorical[point]["y"])
    if (investmentPercent > 0) investments += " (" + investmentPercent + "% of reserve fund)"

    var cashPercent = getPercent(data.dataHistorical[point]["y"] - data.allHistoryData["investment"][point], data.dataHistorical[point]["y"])
    if (cashPercent > 0) cashBalance += " (" + cashPercent + "%)"

    // format header conditionally on current date
    var currentYear = new Date().getFullYear()
    // JS Date object returns months starting from 0
    var currentMonth = new Date().getMonth() + 1
    var htmlHeader = "<h5>" + Highcharts.time.dateFormat('%B %Y', rawDate) + "</h5>"
    if ((numYear === currentYear) && (numMonth === currentMonth)) 
    {
        htmlHeader = htmlHeader + "<h3>Your reserve fund is holding " + shortenedPortfolio + "</h3>"
    }
    else
    {
        htmlHeader = htmlHeader + "<h3>Your reserve fund was holding " + shortenedPortfolio + "</h3>"
    }

    var html = htmlHeader + "\
              <div class='legend'>\
                <div>\
                    <div class='label-group'>\
                      <div class='legend-size square-legend' id='portfolio-actual'></div>\
                      Portfolio: " + portfolio + "\
                    </div>\
                    <div class='label-group'>\
                      <div class='legend-size square-legend' id='investments'></div>\
                      Investments: " + investments + "\
                    </div>\
                </div>\
              </div>"

    // tooltip display div html
    html = "<div class='chart-label'>" + html + "</div>"

    if (!chart.lbl) chart.lbl = chart.renderer.label('', 75, 65, null, null, null, true).css({}).add()
    chart.lbl.show().attr({ text: html })
  }

  return (
    <div className='dashboard-empty'>
      {data.showMissingDashboardData && <MissingData showUpload={false} text={"This page is being processed using your newly saved calculator configuration."} additionalText={"Try refreshing in a few seconds..."} />}
      {!dataExists && !data.showMissingDashboardData && 
      <div className= 'flex-center'>
        <Spinner animation="border" style={{ width: "14rem", height: "14rem", margin: '300px 0px'}}/>
      </div>}
      {dataExists && !data.showMissingDashboardData &&
      <div className='content'>
        <div className="Dashboard">

          <div className='intro' id='DashboardIntroTour'>
            <h1 className='title'>Investment Dashboard</h1>
            <p className='information'>
              Monitor and manage your reserve fund portfolio through our comprehensive Investment Dashboard. View calculated Next Actions based on your market assumptions and calculator settings. <a target="_blank" href="https://www.verticalcityinstitute.com/post/vertical-city-toolkit-reserve-fund-dashboard">How this works »</a>
            </p>
          </div>

          {data.currentCashBalance != null && data.currentExpenses != null && 
          <div className='alert-banner' style={{'marginBottom': ((data.daysSinceUpdateCash>6 || !data.cashBalanceExists) && hideCashBalanceAlert)?'100px':''}}>
            <Alert id='DashboardCashBalanceAlertTour' show={!data.cashBalanceExists} variant={'danger'}>
              <div className='flex-center container'>
                <img alt="Vertical City Logo" src={logo} width="50" height="40" className="d-inline-block align-top" />
                Please give a snapshot of your current cash balance and expenses.
                <OverlayTrigger placement="right" overlay={<Popover><PopoverBody>Our system needs to know what your Reserve Fund balance is before calculating the Next Actions (based on your saved forecast calculator configuration). <br></br><br></br>Until you provide this information the Next Actions will be limited or unavailable.</PopoverBody></Popover>}>
                  <img src={question} alt="Question Mark" width="30" height="30" />
                </OverlayTrigger>
                <button className='underline-button' onClick={() => setShowSnapshotModal(true)}>Update Balances</button>
              </div>
            </Alert> 
            {hideCashBalanceAlert &&
            <Alert id='DashboardCashBalanceAlertTour' show={data.daysSinceUpdateCash > 6 && data.daysSinceUpdateCash < 31} variant={'warning'}>
              <div className='space-between container' style={{'padding': '0px 20px'}}>
                <div className='flex-center'>
                  <img alt="Vertical City Logo" src={logo} width="50" height="40" className="d-inline-block align-top" />
                  Your last cash balance update was given {data.daysSinceUpdateCash} day{data.daysSinceUpdateCash === 1 ? "" : "s"} ago.
                  <button className='underline-button' onClick={() => setShowSnapshotModal(true)}>Update Balances</button>
                </div>
                <button style={{'padding': '12px'}} type="button" className="btn-close" aria-label="Close" onClick={() => setHideCashBalanceAlert(false)}></button>
              </div>
            </Alert>}
            {hideCashBalanceAlert &&
            <Alert id='DashboardCashBalanceAlertTour' show={data.daysSinceUpdateCash >= 31} variant={'danger'}>
              <div className='flex-center container'>
                <img alt="Vertical City Logo" src={logo} width="50" height="40" className="d-inline-block align-top" />
                <div className='text-container'>Your last cash balance update was given over 30 days ago. Please update!</div>
                <button className='underline-button' onClick={() => setShowSnapshotModal(true)}>Update Balances</button>
              </div>
            </Alert>}

            {showSnapshotModal && <SnapshotModal show={showSnapshotModal}></SnapshotModal>}
          </div>}

          <div className='header' style={{'paddingTop': '0px', }}>
            <div className='left'>
              <img src={logoBlue} alt="Blue Logo" width="60" height="60" />
              <p className='section-header'>Summary</p>
            </div>
            <div className='divider divider-1'></div>
          </div>

          <TotalsHeader />

          <div className='header'>
            <div className='left'>
              <img src={logoGreen} alt="Blue Logo" width="60" height="60" />
              <p className='section-header'>Details</p>
            </div>
            <div className='divider divider-2'></div>
            {data.dataInvestments && (pageSource=='investments') && 
            <div className='switch'>
              <Form>
                <Form.Check
                  type="switch"
                  id="green"
                  label="Group By Issuer"
                  checked={!showInvestmentsTable}
                  onClick={() => setShowInvestmentsTable(!showInvestmentsTable)}
                />
              </Form>
            </div>}
            {(pageSource=='next actions') && data.cashBalanceExists && 
            <div className='switch'>
              <Form>
                <Form.Check
                  type="switch"
                  id="green"
                  label="Show All Events"
                  checked={detailView}
                  onClick={() => setDetailView(!detailView)}
                />
              </Form>
            </div>}
          </div>

          <Tab.Container defaultActiveKey={!data.cashBalanceExists ? "investments" : pageSource}>
          <Nav justify variant="tabs" onSelect={setPageSource}>
              {(!data.cashBalanceExists) &&
              <OverlayTrigger placement="top" overlay={<Popover><PopoverBody>You must give a snapshot of your current cash balance before Next Actions can be calculated.</PopoverBody></Popover>}>
              <Nav.Item>
                <Nav.Link eventKey={"next actions"} disabled>Next Actions</Nav.Link>
              </Nav.Item>
              </OverlayTrigger>}
              {data.cashBalanceExists &&
              <Nav.Item>
                <Nav.Link eventKey={"next actions"}>Next Actions</Nav.Link>
              </Nav.Item>}
              <Nav.Item id='DashboardInvestmentsTabTour' style={auth.tourStatus == "DashboardInvestmentsTab" ? {zIndex: 1} : {}}>
                <Nav.Link eventKey={"investments"}>Investments</Nav.Link>
              </Nav.Item>
              <Nav.Item id='DashboardHistoryTabTour' style={auth.tourStatus == "DashboardHistoryTab" ? {zIndex: 1} : {}}>
                <Nav.Link eventKey={"history"}>History</Nav.Link>
              </Nav.Item>
          </Nav>

          <Tab.Content>
          <Tab.Pane eventKey={"history"}>
              <div className='component'>
                <div className='section-description'>This section gives a historical reference of the account balance of your reserve fund, based on all uploaded studies and any cash balance snapshots you may have given. The composition of your balance between cash and investments is made evident in the chart's legend. Feel free to change the Zoom or drag the chart back to see your fund's history.</div>
                <div className='component-container'>
                  {data.dataDashboardExists && <HighchartsReact constructorType={"stockChart"} highcharts={Highcharts} options={chartOptionsHistorical} />}
                </div>
              </div>
          </Tab.Pane>
          <Tab.Pane eventKey={"investments"}>
            <div className='component'>
              <div className='section-description'>This section provides a breakdown of all the investment holdings of your fund. The table is grouped by <span style={{'fontStyle': 'italic'}}>Year Diff.</span> by default - which is the number of years until the investment will mature. You can also view the investments grouped by <span style={{'fontStyle': 'italic'}}>Issuer</span> by clicking on the switch to the top right of this section. To update this section, please go to the Manage Data page.</div>
              {!data.dataInvestments && <MissingData pageSource="investments" showLogo={false} text="There is no Investment Data linked to your account." />}
              {!showInvestmentsTable && data.dataInvestments && <InvestmentBreakdown />}
              {showInvestmentsTable && data.dataInvestments && <div className='react-table' id='all-investments'><InvestmentReactTable tableData={data.investmentTableData} columns={data.investmentTableColumns} /></div>}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey={"next actions"}>
              {/* removing this lockout for presentation purposes: daysSinceUpdateCash < 31 */}
              {data.cashBalanceExists && 
              <>
                {showMoreInfoModal && <MoreInfoModal show={showMoreInfoModal}></MoreInfoModal>}

                <div className='component'>
                  <div className='section-description' style={{'marginBottom': '5px'}}>This section provides a forecasted investment schedule based on your saved settings from the <a style={{'color': '#5d98d1', 'cursor': 'pointer'}} onClick={() => navigate('/app/calculator')}>Forecast Calculator</a> page. It is important to follow these actions if the fund is to behave as they appear in the Reserve Fund Forecast section of the Calculator page. This is not advice, but the output of the calculations that were configured by the user.</div>
                  {!detailView && 
                  <div className="flex space-between" style={{'width': '100%'}}>
                    {(data.nextActions.length > 0) && 
                    <>
                      {actionSlide > 0 &&
                      <button className="arrow-button" style={{'padding': 0, 'height': '60px'}} onClick={() => setActionSlide(n=>n-1)}><span className="arrow" id="left"></span></button>}
                      <div id='DashboardNextActionsTour' className="flex" style={{'flexWrap': 'nowrap', 'gap': '1%', 'width': '100%', ...(auth.tourStatus == "DashboardNextActions" ? {zIndex: 1} : {}) }}>
                        {data.nextActions.map((action, index) => (<SuggestedAction action={action} index={index} />))}
                      </div>
                      {actionSlide<data.numSlides-1 && 
                      <button className="arrow-button" style={{'padding': 0, 'height': '60px', 'alignItems': 'center'}} onClick={() => setActionSlide(n=>n+1)}><span className="arrow" id="right"></span></button>}
                    </>}
                    {!(data.nextActions.length > 0) && <MissingData pageSource="cashflow" showLogo={false} showAdditionalText={true} fontScale={0.8} buttonScale={1.1} text="The Reserve Fund Studies currently available in the system are over 3 years old." additionalText="To receive accurate and up-to-date recommendations for Next Actions, please upload your most recent Reserve Fund Study." />}
                  </div>}
                  {detailView && 
                  <div className='react-table' id='all-events'>
                    <SuggestedActionsReactTable data={data.dataEvents} />
                  </div>}
                </div>
              </>}

              <div className='section-description'>This Investment Maturity Chart gives a 5 year profile of your current holdings, indicating if your investments have a balanced maturity profile or not. Imblanaced portfolios can be problematic because they can mean your money will be locked away when you need it in cash to pay for building expenses. Use this chart to inform your investment decisions.</div>
              <div id='DashboardMaturityTour'>{data.dataInvestments && <HighchartsReact highcharts={Highcharts} options={chartOptionsMaturity}/>}</div>

          </Tab.Pane>
          </Tab.Content>
          </Tab.Container>
          <div className='disclaimer'>
            Please note that Vertical City Toolkit is intended for informational purposes only and should not be construed as investment advice. 
            Condominium Boards should seek the advice of qualified professionals when making investment decisions for their Reserve Funds.
            Vertical City Toolkit does not provide financial, legal, tax or investment advice or recommendations.
          </div>
        </div>
      </div>}
    </div>
  )
}
