import { memo, useState, useEffect, useContext, useRef } from 'react'
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import { Button, Modal, Form, Spinner} from 'react-bootstrap'

// components
import ReportCardPDF from './pdf/ReportCardPDF.js'

// data
import { authContext } from './authContext.js'

// svgs
import expPDF from '../svg/export-pdf.svg'

export const DownloadPDFButton = memo(({page, disabled, id, onBeforeClick}) => {
  const auth = useContext(authContext)

  const [showModal, setShowModal] = useState(false)
  
  

  const handleDownloadButtomClick = () => {
    if(onBeforeClick)
    {
      onBeforeClick()
    }
    setShowModal(true)
  }
  
  return (
    <>
      {showModal && <ModalDownloadSelection show={showModal} onHide={() => setShowModal(false)} page={page} auth={auth}/>}
      <Button id={id} className="export-button" disabled={disabled} size='lg' onClick={handleDownloadButtomClick}>
        <img src={expPDF} alt="Export Icon" />
        Export
      </Button>
    </>
  )

})

const DownloadPreview = memo(function DownloadPreview({
      fileName,
      numPages,
      page,
    })
    {
      const downloadPrevRef = useRef(null)
      const [currentPage, setCurrentPage] = useState(1)

      useEffect(() => {
        const handleScroll = () => {
          if (downloadPrevRef.current) { // Check if innerElementRef.current is not null
            const scrollTop = downloadPrevRef.current.scrollTop;
            const pageHeight = Math.ceil(downloadPrevRef.current.scrollHeight / numPages);
            let currentPage = Math.ceil(scrollTop / pageHeight);
            if (currentPage === 0 ) currentPage = 1
            setCurrentPage(currentPage);
          }
        };
    
        if (downloadPrevRef.current) { 
          downloadPrevRef.current.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (downloadPrevRef.current) {
            downloadPrevRef.current.removeEventListener('scroll', handleScroll);
          }
        };
      }, [numPages]);

      return(
        <div ref={downloadPrevRef} style={{width: '85%', height:'80vh', overflowY:'auto', marginTop:'40px'}}>
          <div style={{display:'flex', alignItems:'center', position:'fixed', width:'inherit', maxWidth:'1325px', height:'40px', marginTop:'-40px', backgroundColor:'#696868', color:'white'}}>
            <span style={{marginLeft:'20px', width:'500px'}}>{fileName}</span>
            <span style={{textAlign:'right', width:'inherit', marginRight:'20px'}}>Page {currentPage}/{numPages}</span>
          </div>
          <div style={{ marginLeft:'5px' }}>
            <div id='pdf-container' style={{pointerEvents: 'none'}}>
              {page == "RPC" && <ReportCardPDF />}
            </div>
          </div>
        </div>
      )
})

const ModalDownloadSelection = memo(function ModalDownloadSection({show, onHide, page, auth}) {

  const [pdfOptions, setPdfOptions] = useState({filename: "webpage.pdf", method: "save", resolution: Resolution.NORMAL})
  const [fileName, setFileName] = useState("webpage.pdf")
  const [numPages, setNumPages] = useState(1)
  const [disableButton, setDisableButton] = useState(true)
  const [useModalMini, setUseModalMini] = useState(false)

  // calculate the number of pages in the pdf
  useEffect(() => {
    let extraPages = 2
    // if (selectedReportType == 'SMR' || (page == 'AOB' && selectedReportType == 'ERP')) extraPages = 2
    // const sections = checkedOptions.filter((item) => (!['frequent','neural-consistency','go','no-go'].includes(item)))      
    // if (sections.includes("power-spectrum-scores")) extraPages += 2
    // if (sections.includes("frequency-ratios-scores")) extraPages += 1

    // setNumPages(sections.length + extraPages) 
    setNumPages(extraPages)
  },[page])

  // create the file name of the pdf
  useEffect(() => {
    let tempFileName = `${auth.selectedBuilding}-${page}-${auth.selectedStudyYear}.pdf`
    tempFileName = tempFileName.replace(", ", "-")
    tempFileName = tempFileName.replace(" ", "-")
    setFileName(tempFileName)
  },[page, auth.selectedBuilding, auth.selectedStudyYear])

  // pdf download button loading
  useEffect(() => {
    if (show) {
      // Disable the button when modal first opens
      setDisableButton(true)

      let timer;
      
      if (useModalMini)
      {
        // Re-enable button after 4 seconds
        timer = setTimeout(() => {
          setDisableButton(false)
        }, 2000);
      }
      else
      {
        // Re-enable button after 1 second
        timer = setTimeout(() => {
          setDisableButton(false)
        }, 1000);
      }
      
      return () => clearTimeout(timer)
    }
  }, [show, useModalMini])


  // set the pdf options
  useEffect(() => {
    setPdfOptions(
      {
        filename: fileName,
        method: "save",
        resolution: Resolution.NORMAL,
        page: {
        margin: Margin.NONE,
        format: "letter",
        orientation: "portrait"
        },
        canvas: {
          mimeType: "image/jpeg",
          qualityRatio: 1
        },
        overrides: {
          pdf: {
              compress: true
          },
          canvas: {
            useCORS: true,
            willReadFrequently: true,
            onclone: (document) => {
              const pdfContainer = document.getElementById("pdf-container");
              const fixedHeight = 1605 * numPages; // For two pages
              pdfContainer.style.width = "1250px";
              pdfContainer.style.height = `${fixedHeight}px`;
              pdfContainer.style.overflow = "hidden";

              // Remove padding on the bottom of all the component containers
              // const componentContainers = document.querySelectorAll('.component-container')
              // componentContainers.forEach(componentContainer => {
              //   componentContainer.style.paddingBottom = '0px' // or 'hidden' depending on your requirement
              //   componentContainer.style.paddingTop = '10px'
              // })
              
            },
          }
        }
      }
    )
  }, [fileName])

  //Set up resize event listener views
  useEffect(() => {

    //Initialize state variables
    resizeModal()

    // Add event listener for window resize
    window.addEventListener('resize', resizeModal)

    // Clean up function
    // TODO not sure if we need this
    return () => {
      window.removeEventListener('resize', resizeModal)
    }

  }, [])



  //------------------------------------------------------------------------------------------------------------------
  // Components
  //------------------------------------------------------------------------------------------------------------------

  const handleSubmit = (e) => {
    e.preventDefault()
    if (disableButton) return
    const getTargetElement = () => document.getElementById("pdf-container")
    generatePDF(getTargetElement, pdfOptions)
    onHide() 
  };
  const handleDownload = () => {
    const getTargetElement = () => document.getElementById("pdf-container")
    generatePDF(getTargetElement, pdfOptions)
  };

  const resizeModal = () => {
    // smaller
    if (window.matchMedia("(max-width: 1500px)").matches)
    {
      setUseModalMini(true)
    }
    else if(!window.matchMedia("(max-width: 1500px)").matches )
    {
      setUseModalMini(false)
    }
    
  }

  return (
    <>
      {useModalMini &&
        <Modal className="modal-download-mini" dialogClassName={"modal-width"} show={show} onHide={onHide} size="sm" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Confirm Download</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to download this file?
            <div style={{ position: 'absolute', left: '-5000px', top: 0 }}>
              <DownloadPreview fileName={fileName} numPages={numPages} page={page} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide} >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDownload} disabled={disableButton} style={{ minWidth: "140px" }}>
                        {disableButton ? (<><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" style={{ marginRight: "5px" }}/>Loading...</>) : ("Download PDF")}
              </Button>
          </Modal.Footer>
        </Modal>
      }
      {!useModalMini && 
        <Modal className="modal-download" dialogClassName={"modal-width"} show={show} onHide={onHide} size="xl" backdrop="static">
          <Modal.Header closeButton><Modal.Title>Download</Modal.Title></Modal.Header>
            <Modal.Body>
              <div className='space-between'> 
                <DownloadPreview fileName={fileName} numPages={numPages} page={page}/>
                <div style={{width:'15%', backgroundColor:'#f5f5f5', borderRadius: '0px 0px 8px 0px', padding: '20px', zIndex:'400'}}>
                  <h5>Click the button below to download the PDF</h5>
                  <hr style={{marginTop:'5px', marginBottom:'5px'}}></hr>
                  <Form onSubmit={handleSubmit}>
                    <div style={{display:'flex', justifyContent: 'center', alignItems:'right'}}>
                      <Button variant="primary" type="submit" disabled={disableButton} style={{ minWidth: "140px" }}>
                       {disableButton ? (<><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" style={{ marginRight: "5px" }}/>Loading...</>) : ("Download PDF")}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Modal.Body>
        </ Modal>
      } 
    </>
  )
})

export default DownloadPDFButton