// link to this in account dropdown, under setting/manage data
import { useRef, useState, useEffect, useContext } from 'react'

import { useLocation } from 'react-router-dom'

import { todaysDate, formatDateDashes } from '../../lib/helpers/formattingHelpers.js'
import { formatServerData, formatReactableColumns, ReactTableBasic } from '../../lib/reactableBuilder.js'

// context
import { authContext } from '../authContext.js'
import { dataContext } from '../dataContext.js'

// bootstrap
import { Tab, Button, Nav, Modal, Spinner } from 'react-bootstrap'

// icons
import x from '../../svg/x.svg'
import download from '../../svg/download.svg'

// downloading data as CSV
import { CSVLink } from 'react-csv'

export default function ManageData(props) {
  const auth = useContext(authContext)
  const data = useContext(dataContext)

  const [dataLoaded, setDataLoaded] = useState(false)
  const location = useLocation()

  const [tableView, setTableView] = useState(false)

  const [currentData, setCurrentData] = useState(null) 
  const [currentDataColumns, setCurrentDataColumns] = useState(null)
  const [currentAmountRTHeaders, setCurrentRTAmountHeaders] = useState([])
  const [currentDateRTHeaders, setCurrentRTDateHeaders] = useState([])
  
  const [currentFile, setCurrentFile] = useState(null)

  const [modalShow, setModalShow] = useState(false)
  const [pageSource, setPageSource] = useState("cashflow")

  const [title, setTitle] = useState("")

  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // necessary to update the data type to what should be uploaded
  useEffect(() => {
    if (location.state)
    {
      data.setDataType(location.state.pageSource)
      setPageSource(location.state.pageSource)
    }
    else if (props)
    {
      data.setDataType(props.pageSource)
      setPageSource(props.pageSource)
    }
  }, [])

  // pre-loading data
  useEffect(() => {
    if (auth.isContextSecured)
    {
      data.fetchRFStudies()
      data.fetchUtilities()
      data.fetchInvestmentData()
    }
  }, [auth.isContextSecured])

  useEffect(() => {
    if (data.investmentData?.amount !== undefined)
    {
      data.setInvestmentData({ rows: data.investmentData["amount"].length })
      //console.log("/data/investments:", resp.data)
      const desiredInvestmentHeaders = {
        "issuer": "Issuer", "amount": "Amount", "rate": "Rate", "issuedate": "Issue Date", "maturitydate": "Maturity Date",
        "type": "Type", "description": "Description", "custodian": "Custodian"
      }
      const desiredAmountHeaders = ["Amount"]
      const desiredDateHeaders = ["Issue Date", "Maturity Date"]
      
      const dataFromServer = formatServerData(data.investmentData, desiredInvestmentHeaders)

      if (!dataFromServer || dataFromServer.length === 0) return
      
      setCurrentData(dataFromServer)
      setCurrentDataColumns(formatReactableColumns(dataFromServer[0], desiredDateHeaders, desiredAmountHeaders))
      setCurrentRTAmountHeaders(desiredAmountHeaders)
      setCurrentRTDateHeaders(desiredDateHeaders) 
      setCurrentFile("investments_" + todaysDate() + ".csv")
    }
    setDataLoaded(true)
  }, [data.investmentData])

  // updates data after upload (after a given amount of time)
  useEffect(() => {
    if (data.processingCashflow)
    {
      setTimeout(function () {
        data.fetchRFStudies()
      }, 140000)
    }
  }, [data.processingCashflow])

  useEffect(() => {
    if (data.processingUtility)
    {
      setTimeout(function () {
        data.fetchUtilities()
      }, 10000)
    }
  }, [data.processingUtility])

  useEffect(() => {
    if (data.processingInvestments)
    {
      setTimeout(function () {
        data.fetchInvestmentData()
        data.setInvestmentData({ rows: data.investmentData["amount"].length })
      }, 2000)
    }
  }, [data.processingInvestments])

  useEffect(() => {
    if (Object.entries(data.cashflowData).length !== 0) 
    {
      const desiredCashflowHeaders = {
        "year": "Year", "openingbalance": "Opening Balance", "contribution": "Contribution", "expenditureia": "Expenditure (IA)",
        "interestincome": "Interest Income", "closingbalance": "Closing Balance", "inflationrate": "Inflation Rate", "interestrate": "Interest Rate"
      }
      const desiredAmountHeaders = ["Opening Balance", "Contribution", "Expenditure (IA)", "Interest Income", "Closing Balance"]
      const dataFromServer = formatServerData(data.cashflowData, desiredCashflowHeaders)
  
      if (!dataFromServer || !Array.isArray(dataFromServer) || dataFromServer.length <= 0) return
  
      setCurrentData(dataFromServer)
      setCurrentDataColumns(formatReactableColumns(dataFromServer[0], [], desiredAmountHeaders))
      setCurrentRTAmountHeaders(desiredAmountHeaders)
    }
  }, [data.cashflowData])

  useEffect(() => {
    if (Object.entries(data.utilityData).length !== 0)
    {
      const desiredEnergyHeaders = { "startdate": "Start Date", "enddate": "End Date", "consumption": "Consumption", "cost": "Cost" }
      const desiredAmountHeaders = ["Cost"]
      const desiredDateHeaders = ["Start Date", "End Date"]
      const dataFromServer = formatServerData(data.utilityData, desiredEnergyHeaders)

      if (!dataFromServer || !Array.isArray(dataFromServer) || dataFromServer.length <= 0) return

      setCurrentData(dataFromServer)
      setCurrentDataColumns(formatReactableColumns(dataFromServer[0], desiredDateHeaders, desiredAmountHeaders))
      setCurrentRTAmountHeaders(desiredAmountHeaders)
      setCurrentRTDateHeaders(desiredDateHeaders)

    }
  }, [data.utilityData])

  function handleFileSelect(inputData, tempTitle) {
    setTitle(tempTitle)
    updateDisplayedData(inputData)
  }

  async function updateDisplayedData(inputData) {

    async function retrieveFileData(year = 0, utilityType = "") {
      if (data.dataType === "cashflow")
      {
        console.log("fetching cashflow data")
        data.fetchCashflowData(year)
        setCurrentFile("cashflow_" + year + ".csv")
      }
      else if (data.dataType === "energy")
      {
        console.log("fetching " + utilityType + " data")
        await data.fetchUtility(utilityType)
        for (let i in data.utilityFiles)
        {
          if (data.utilityFiles[i]["type"] === utilityType)
          {
            setCurrentFile(utilityType + "_" + data.utilityFiles[i]["latest_date"] + ".csv")
            break
          }
        }
      }
      else if (data.dataType === "investments")
      {
        console.log("fetching investments data")
        data.fetchInvestmentData()
      }
    }
  
    setCurrentData(null)
    setCurrentFile(null)
    if (data.dataType === "cashflow")
    {
      await retrieveFileData(inputData)
    }
    else if (data.dataType === "energy")
    {
      await retrieveFileData(0, inputData)
    }
    else if (data.dataType === "investments")
    {
      await retrieveFileData()
    }
    setTableView(true)
  }

  function UploadDataModal(props) {
    const [dragActive, setDragActive] = useState(false)
    const [formData, setFormData] = useState(null)

    const [newFileName, setNewFileName] = useState(null)

    const newInputRef = useRef(null)

    function handleDrag(e) {
      e.preventDefault()
      e.stopPropagation()
      if (e.type === "dragenter" || e.type === "dragover")
      {
        setDragActive(true)
      }
      else if (e.type === "dragleave")
      {
        setDragActive(false)
      }
    }

    function handleDrop(e) {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(false)

      if (e.dataTransfer.files[0].type !== "text/csv")
      {
        console.log("not the right type!")
        return
      }

      if (e.dataTransfer.files && e.dataTransfer.files[0])
      {
        setNewFileName(e.dataTransfer.files[0].name)
        setFormData({
          ...formData,
          file: e.dataTransfer.files[0]
        })
      }
    }

    function handleFileUpload(e) {
      e.preventDefault()
      if (e.target.files && e.target.files[0])
      {
        setNewFileName(e.target.files[0].name)
        setFormData({
          ...formData,
          file: e.target.files[0]
        })
      }
    }

    function handleChange(e) {
      setFormData({
        ...formData,
        [e.target.id]: e.target.value
      })
    }

    function handleDelete() {
      setNewFileName(null)
      setFormData({
        ...formData,
        file: null
      })
    }

    function handleSubmit(e) {
      // handle no data
      e.preventDefault()
      if (!formData || !formData["file"]) return

      let url_query = ""
      const postData = new FormData()
      postData.append('email', auth.email)
      postData.append('building_short_name', auth.selectedBuilding)

      if (data.dataType === "cashflow")
      {
        if (!formData["engineer"]) return
        if (!formData["publish_date"]) formData["publish_date"] = todaysDate()

        url_query = '/fileupload/cashflow'
        const publish_date = formData["publish_date"].replaceAll("-", "")
        postData.append('engineer', formData["engineer"])
        postData.append('publish_date', publish_date)
      }
      else if (data.dataType === "energy")
      {
        if (!formData["company"]) return
        if (!formData["utility_type"]) formData["utility_type"] = "electricity"

        url_query = '/fileupload/utility'
        postData.append('utility_type', formData["utility_type"])
        postData.append('company', formData["company"])
      }
      else if (data.dataType === "investments")
      {
        url_query = '/fileupload/investments'
      }

      const file = formData["file"]
      postData.append('file', file)

      data.fileUpload(url_query,postData)
      props.onHide()
    }

    return (
      <Modal {...props} backdrop="static" size="md" dialogClassName="modal-20">
        <Modal.Header closeButton>
          <Modal.Title>File Upload</Modal.Title>
        </Modal.Header>
        <form id='form-file-upload' onSubmit={handleSubmit} >
          <Modal.Body>
            {!newFileName && (
              <>
                <input ref={newInputRef} type="file" id='input-file-upload' accept=".csv" onChange={handleFileUpload} />
                <label id='label-file-upload' htmlFor='input-file-upload' className={dragActive ? "drag-active" : ""} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                  <div className='modal-button-container'>
                    <p>Drag and drop your CSV file here or</p>
                    <Button variant={`${newFileName ? "success" : "primary"}`} onClick={() => newInputRef.current.click()}>Upload a file</Button>
                  </div>
                </label>
              </>
            )}

            {newFileName && (
              <>
                <div className='file-uploaded space-between'>
                  <div><b>File Name:</b> {newFileName}</div>
                  <input type="image" src={x} alt="x" onClick={handleDelete} />
                </div>
              </>
            )}

            {data.dataType === "cashflow" && (
              <div className='additional-data' style={{'flexWrap':'wrap'}}>
                <input type="text" placeholder="Engineer" id="engineer" onChange={handleChange} />
                <div style={{'display':'flex'}}>
                  <h6 style={{'marginRight':'10px', 'marginTop':'4px'}}>Publish date:</h6>
                  <input type="date" id="publish_date" defaultValue={todaysDate()} onChange={handleChange} />
                </div>
              </div>
            )}

            {data.dataType === "energy" && (
              <div className='additional-data' style={{'flexWrap':'wrap'}}>
                <h6>Utility type: </h6>
                <select id="utility_type" onChange={handleChange}>
                  <option value="electricity">Electricity</option>
                  <option value="water">Water</option>
                  <option value="steam">Steam</option>
                  <option value="naturalgas">Natural Gas</option>
                </select>
                <input type="text" placeholder="Company" id="company" onChange={handleChange} />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" type="submit">Submit</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }

  // component for displaying data as a table if user clicks into a study, investment file or utility bill
  function TableView() {
    return (
      <div className='table-view'>
        <div className='buttons space-between'>
          <Button onClick={() => setTableView(false)}>Back</Button>
          <span style={{margin: 'auto', fontSize: '22px', fontWeight: 600}}>{title}</span>
          <CSVLink data={currentData ? currentData : []} enclosingCharacter={''}
            filename={currentFile ? currentFile : ""}>
            <img src={download} />
          </CSVLink>
        </div>
        <div className='react-table' style={{'whiteSpace':'nowrap'}}>
          {currentData && currentDataColumns && <ReactTableBasic data={currentData} columns={currentDataColumns} formatDateHeaders={currentDateRTHeaders} formatAmountHeaders={currentAmountRTHeaders} />}
        </div>
      </div>
    )
  }

  return (
    <>
      {dataLoaded && (
        <div className='manage-data'>
          <UploadDataModal show={modalShow} onHide={() => setModalShow(false)} />
          <div className='content'>
            <Tab.Container defaultActiveKey={pageSource}>
              <Nav variant="tabs" onSelect={(key) => {
                setTableView(false)
                data.setDataType(key) 
              }}>
                <Nav.Item>
                  <Nav.Link eventKey={"cashflow"} style={{color: "#5d98d1"}}>Reserve Fund</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"investments"} style={{color: "#5d98d1"}}>Investments</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"energy"} style={{color: "#5d98d1"}}>Energy Analytics</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey={"cashflow"}>
                  {data.processingCashflow && (
                    <div className='processing flex-center'>
                      <h3>Your Reserve Fund data is being uploaded.</h3>
                      <Spinner animation="border" />
                    </div>
                  )}
                  {!data.processingCashflow && (
                    <>
                      {!tableView && (
                        <div className='data-overview'>
                          <h3>{data.availableRFStudies === null ? "No Studies Uploaded" : "Your Building's Studies"}</h3>
                          {data.availableRFStudies && data.availableRFStudies.map(({ engineer, year, publish_date }, index) => {
                            return (
                              <div className='component'>
                                <button className='component-container' key={index} onClick={() => handleFileSelect(year, `${year} Study by ${engineer}`)}>
                                  <div><b>Year: </b>{year}</div>
                                  <div><b>Publish Date: </b>{formatDateDashes(publish_date)}</div>
                                  <div><b>Engineer: </b>{engineer}</div>
                                </button>
                              </div>
                            )
                          })}
                          <Button className='upload-button' onClick={() => setModalShow(true)}>Upload</Button>
                        </div>
                      )}
                      {tableView && <TableView />}
                    </>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey={"investments"}>
                  {data.processingInvestments && (
                    <div className='processing flex-center'>
                      <h3>Your Investments data is being uploaded.</h3>
                      <Spinner animation="border" />
                    </div>
                  )}
                  {!data.processingInvestments && (
                    <>
                      {!tableView && (
                        <div className='data-overview'>
                          <h3>{data.investmentData === null ? "No Investment Data Uploaded" : "Your Building's Investment Data"}</h3>
                          {data.investmentData &&
                            <div className='component'>
                              <button className='component-container' onClick={() => handleFileSelect(null, `Current Investments`)}>
                                <div><b>Number of investments: </b>{data.investmentData["rows"]}</div>
                              </button>
                            </div>
                          }
                          <Button className='upload-button' onClick={() => setModalShow(true)}>Upload</Button>
                        </div>
                      )}
                      {tableView && <TableView />}
                    </>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey={"energy"}>
                  {data.processingUtility && (
                    <div className='processing flex-center'>
                      <h3>Your Energy data is being uploaded.</h3>
                      <Spinner animation="border" />
                    </div>
                  )}
                  {!data.processingUtility && (
                    <>
                      {!tableView && (
                        <div className='data-overview'>
                          <h3>{data.utilityFiles === null ? "No Utility Bills Uploaded" : "Your Building's Utility Bills"}</h3>
                          {data.utilityFiles && data.utilityFiles.map(({ type, latest_date, company }, index) => {
                            return (
                              <div className='component'>
                                <button className='component-container' key={index} onClick={() => handleFileSelect(type, `${type.charAt(0).toUpperCase() + type.slice(1)} Bills (${company})`)}>
                                  <div><b>Utility Type: </b>{type}</div>
                                  <div><b>Date: </b>{formatDateDashes(latest_date)}</div>
                                  <div><b>Company: </b>{company}</div>
                                </button>
                              </div>
                            )
                          })}
                          <Button className='upload-button' onClick={() => setModalShow(true)}>Upload</Button>
                        </div>
                      )}
                      {tableView && <TableView />}
                    </>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      )}
    </>
  )
}