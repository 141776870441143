import { useEffect, useState, useCallback, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { Dropdown, DropdownButton, Button, Modal, OverlayTrigger, Popover, PopoverBody, Spinner, Form, Table } from 'react-bootstrap'

// context
import { authContext } from '../authContext.js'
import { dataContext } from '../dataContext.js'

// Components
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import RangeSlider from 'react-bootstrap-range-slider'
import MissingData from '../MissingData.js'
import OffCanvasCashflow from '../OffCanvasCashflow.js'
import SelectStudyModal from '../SelectStudyModal.js'
import OnboardingLinePlotModal from '../OnboardingLinePlotModal.js'

// Helper Functions
import { onMouseOutSync, chartExtremes, getIndex } from '../../lib/helpers/highchartsHelpers.js'
import { formatAmount, formatAmountKM, formatDate, YYYYMMDDtoDate } from '../../lib/helpers/formattingHelpers.js'

// logo svgs
import logoBlue from '../../svg/mini-logo-blue.svg'
import logoYellow from '../../svg/mini-logo-yellow.svg'
import greyQuestion from '../../svg/grey-question.svg'
import handPointer from '../../svg/hand-pointer.svg'

// significant expense interface
import { getExpenseFlag } from '../../lib/helpers/tooltipFlags.js'
import { min } from 'moment/moment.js'

export default function Calculator() {
  // context and navigation
  const auth = useContext(authContext)
  const data = useContext(dataContext)

  const currentYear = new Date().getFullYear()

  //------------------------------------------------------------------------------------------------------------------
  // Component State
  //------------------------------------------------------------------------------------------------------------------

  const [selectedYear, setSelectedYear] = useState(null)
  const [initialEffectRan, setInitialEffectRan] = useState(false)

  // chart options
  const [chartOptionsReserve, setChartOptionsReserve] = useState({title: { text: ''}})
  const [chartOptionsExpenditures, setChartOptionsExpenditures] = useState({title: { text: ''}})
  const [chartOptionsRate, setChartOptionsRate] = useState({title: { text: ''}})
  const [chartOptionsComposition, setChartOptionsComposition] = useState({title: { text: ''}})
  const [chartOptionsRateCalcs, setChartOptionsRateCalcs] = useState({title: { text: ''}})
  const [chartOptionsPercentMLGIC, setChartOptionsPercentMLGIC] = useState({title: { text: ''}})
  const [chartOptionsMinCashBalance, setChartOptionsMinCashBalance] = useState({title: { text: ''}})
  const [chartOptionsVolatilityCone, setChartOptionsVolatilityCone] = useState({title: { text: ''}})

  // for mobile screens
  const [mobileScreen, setMobileScreen] = useState(true)
  const [labelRotation, setLabelRotation] = useState(0)
  const [chartSpacing, setChartSpacing] = useState([20,20,20,20])

  // chart interactive drawing setMouseOver
  const [mouseOver, setMouseOver] = useState(true)
  const [mouseOverEvent, setMouseOverEvent] = useState({})
  const [mouseOverPoint, setMouseOverPoint] = useState({})
  const [mouseOverYear, setMouseOverYear] = useState(null)

  const [tempRiskLevel, setTempRiskLevel] = useState(0)
  const [tempMinCash, setTempMinCash] = useState(0)
  const [tempInflationYield, setTempInflationYield] = useState(0)
  const [tempInflationVol, setTempInflationVol] = useState(0)
  const [tempGICYield, setTempGICYield] = useState(0)
  const [tempGICVol, setTempGICVol] = useState(0)
  const [tempMLGICYield, setTempMLGICYield] = useState(0)
  const [tempMLGICVol, setTempMLGICVol] = useState(0)
  const [tempCashIntRate, setTempCashIntRate] = useState(0)
  const [tempWindow, setTempWindow] = useState(null)

  const [showModalSettingsHistory, setShowModalSettingsHistory] = useState(false)
  const [showSubmitSettingsModal, setShowSubmitSettingsModal] = useState(false)
  const [showSubmitSettingsWarningModal, setShowSubmitSettingsWarningModal] = useState(false)

  // show flags
  const [showHeaderModal, setShowHeaderModal] = useState(0)
  const [dataExists, setDataExists] = useState(false)
  // const [showMiniDiagram, setShowMiniDiagram] = useState(false)

  // totals header
  const [labelPoint, setLabelPoint] = useState({})
  const [bypassRequest, setBypassRequest] = useState(false)
  const [showOffCanvas, setShowOffCanvas] = useState(false)
  const [showInputModal, setShowInputModal] = useState(0)
  const [showOnboardingModal, setShowOnboardingModal] = useState(false)

  const navigate = useNavigate()

  //------------------------------------------------------------------------------------------------------------------
  // Helper Functions
  //------------------------------------------------------------------------------------------------------------------

  function toPercentString(decimalNum, noDecimals=false) {
    if (decimalNum == 0) return "0%"
    else if (decimalNum < 0.01) return (decimalNum * 100).toPrecision(1).toString() + "%"
    else if (decimalNum < 0.1) return (decimalNum * 100).toPrecision(2).toString() + "%"
    else
    {
      if (noDecimals) return (decimalNum * 100).toPrecision(2).toString() + "%"
      else return (decimalNum * 100).toPrecision(3).toString() + "%"
    } 
  }

  function getStudyFromYear(year) {
    for (let i = 0; i < data.availableRFStudies.length; i++) {
      if (data.availableRFStudies[i]['year'].toString() == year) return year + " Study by " + data.availableRFStudies[i]['engineer']
    }
  }

  // syncs all charts onMouseOver
  function onMouseOverSyncRF(e, chartNames, all=false) {
    // looping through all charts
    Highcharts.charts.forEach(chart => {
      // only apply code to given charts
      if (chart && (all || chartNames.includes(chart.title.textStr)))
      {
        // collects each point at current location on x-axis e.g. for rates chart, there would be 3 points (because there are 3 series)
        let pointsTooltip = []
        // looping through series
        chart.series.forEach(s => {
          // looping through points
          s.points.forEach(p => {
            // since mouseOut behavior is unpredictable and laggy for reserve chart, same functionality is included here
            // (if marker is currently over point, remove it)
            if (p.state == 'hover') p.setState('')

            // syncs points between the graphs
            if (p.index === e.target.index)
            {
              // sets point state to "hover" (dots appear on graph at given point)
              p.setState('hover')
              pointsTooltip.push(p)

              // refreshing crosshair of given charts
              chart.xAxis[0].drawCrosshair(e, p)

              // if mouse over on support charts, then manually update main chart label
              if (chart.title.textStr === "reserve-fund-benchmark" || chart.title.textStr === "reserve-fund-comparison")
              {
                setLabelPoint(p)
                //refreshReserveLabel(p)
                // Deprecated TODO validate this is not too computationally heavy
                // drawing markers on main chart when hovering over Detail Charts 
                // setMouseOverPoint(p)
                // setMouseOverEvent(e)
                // setMouseOverYear(e.target.category)
              }    
            }
          })
        })
        // refreshing tooltip of synced charts
        if (pointsTooltip.length > 0)
        {
          // this method takes an array of point objects that it uses to display the tooltip
          chart.tooltip.refresh(pointsTooltip)
        }
      }
    })
  }

  // updates chart properties based on window size
  const resizeMobile = () => {
    if (window.matchMedia("(max-width: 560px)").matches) 
    {
      setLabelRotation(-35)
      setChartSpacing([10,0,0,0])
      setMobileScreen(true)
    } 
    else if(!window.matchMedia("(max-width: 560px)").matches ) 
    {
      setLabelRotation(0)
      setChartSpacing([20,20,20,20])
      setMobileScreen(false)
    }
  }

  // helper function to generate difference spline using two highcharts lines
  function getNegativeDifference(listA, listB) {
    const result = []

    for (var i = 0; i < listA.length; i++) {
      const year = listA[i][0]
      const valueA = listA[i][1]
      const valueB = listB[i][1]
      
      //Determine intercept if necessary
      if (i > 0)
      {
        const prevYear = listA[i-1][0]
        const prevValueA = listA[i-1][1]
        const prevValueB = listB[i-1][1]
        const slopeA = (valueA-prevValueA)/(year-prevYear)
        const slopeB = (valueB-prevValueB)/(year-prevYear)
        const yIntA = valueA - year*slopeA  
        const yIntB = valueB - year*slopeB  

        const interceptX = (yIntB-yIntA)/(slopeA-slopeB)
        const interceptY = interceptX*slopeA+yIntA
        //console.log([interceptX, interceptY])
        if (interceptX > prevYear && interceptX < year)
        {
          result.push([interceptX, interceptY, interceptY])
        }
      }

      //Push actual difference
      if (valueB > valueA)
      {
        result.push([year, valueA, valueB])
      }
      else
      {
        result.push([year, null, null])
      }
    }

    return result 
  }
  
  //------------------------------------------------------------------------------------------------------------------
  // Component Hooks
  //------------------------------------------------------------------------------------------------------------------

  // on page render, fetch data
  useEffect(() => {
    if (!auth.isContextSecured) return

    if (!data.setBuildingSettingsReceived) data.fetchBuildingSettings()

    data.fetchRFStudies()
    if (auth.selectedStudyYear) {
      setSelectedYear(auth.selectedStudyYear)
    }
  }, [auth.isContextSecured])

  // show offcanvas on page render if page width is greater than 1715px
  useEffect(() => {
    if (data.availableRFStudies.length == 0 || selectedYear==null || initialEffectRan) return
    
    if (window.innerWidth > 1715) {
      setShowOffCanvas(true)
    }
    setInitialEffectRan(true)
  }, [data.availableRFStudies, data.showSelectStudyModal])
  
  // set temporary building configurations after settings are fetched from backend
  useEffect(() => {
    setTempRiskLevel(data.savedRiskLevel)
    setTempMinCash(data.savedMinCash)
    setTempMLGICYield(data.savedMLGICYield)
    setTempMLGICVol(data.savedMLGICVol)
    setTempGICYield(data.savedGICYield)
    setTempGICVol(data.savedGICVol)
    setTempInflationYield(data.savedInflationYield)
    setTempInflationVol(data.savedInflationVol)
    setTempCashIntRate(data.savedCashIntRate)
  }, [data.savedMLGICYield])

  useEffect(() => {
    if (!auth.selectedStudyYear)
    {
      if (data.availableRFStudies.length == 1) setSelectedYear(data.availableRFStudies[0].year)
      else data.setShowSelectStudyModal(true)
    }
    if (data.availableRFStudies.length !== 0)
    {
      setDataExists(true)
    }
  }, [data.availableRFStudies])
  
  // fire after study year is selected
  useEffect(() => {
    if (selectedYear)
    {
      if (bypassRequest)
      {
        setBypassRequest(false)
        return
      }

      data.fetchCashflowData(selectedYear) 
    }
  }, [selectedYear, auth.isContextSecured]) // only trigger if user selects the year, otherwise this effect will be run twice on startup

  useEffect(() => {
    if (data.showProcessingData)
    {
      setDataExists(false)
    }
  }, [data.showProcessingData])

  //Set up risize event listener for chart mobile views
  useEffect(() => {

    //Initialize state variables
    resizeMobile()

    // Add event listener for window resize
    window.addEventListener('resize', resizeMobile)

  }, [])

  // Highcharts Chart Options Hook
  useEffect(() => {
    //resetReserveChart()
    if (data.dataVCRange.length > 0)
    {
      var tempDataStudyBenchmark = []
      
      // Find Chart Extremes (y axis boundary)
      var extremes = chartExtremes([data.dataBaseLow, data.dataSystemHigh, data.dataClosing])
      var tempExtremes = chartExtremes([data.dataVCRange])
      if (extremes.max < tempExtremes.max)
      {
        extremes.max = tempExtremes.max
        data.setDataSystemHigh(getIndex(data.dataVCRange, 2)) // for next time hook is fired
      }
      data.setDataExtremes(extremes)

  
      // setting chart options - this is done to ensure that each chart is not rerendered when risk level is changed, improving efficiency + preventing page from snapping to top
      setChartOptionsReserve({
        chart: {
          type: "line",
          height: '40%',
          borderRadius: 10, // same as other divs in index.css  
          backgroundColor: "#ffffff",
          spacing: chartSpacing,
          events: {
            // update main chart label for some non-mouseover edge cases
            render: function () {
              // if mouse is over chart, exit code
              if (this.xAxis[0].paddedTicks.length == 4) return
              
              var point
              var iSeries = 2 // True Benchmark Series              
              var lastIndex = this.series[iSeries].points.length - 1

              // on page load or recalculate request
              if (this.mainLabel == undefined)
              {
                point = this.series[iSeries].points[lastIndex]
                setLabelPoint(point)
                //refreshReserveLabel(point)
              }
            }
          }
        },
        title: {
          text: "reserve-fund-comparison",
          style: {
            display: 'none'
          }
        },
        tooltip: {
          // crosshairs: true,
          shared: true,
          // split: false,
          formatter: function () {
            return false
          }
        },
        series: [
          {
            name: 'Vertical City Range',
            type: 'areasplinerange',
            data: data.dataVCRange,
            color: data.savedConfig ? '#a7b661':'#ffffff00',
            lineWidth: 0,
            fillOpacity: 0.3,
            marker: {
              enabled: false
            }
          },
          {
            name: 'Vertical City',
            type: 'spline',
            data: data.dataVC,
            color: data.savedConfig ? '#a7b661':'#ffffff00',
            lineWidth: 2.5,
            marker: {
              enabled: false,
            }
          },
          {
            name: 'True Benchmark',
            type: 'spline',
            data: data.dataTrueBenchmark,
            color: '#5d98d1',
            lineWidth: 2,
            marker: {
              enabled: false,
            }
          },
          {
            name: 'True Benchmark Range',
            type: 'areasplinerange',
            data: data.dataTrueBenchmarkRange,
            color: '#5d98d1',
            lineWidth: 0,
            fillOpacity: 0.3,
            marker: {
              enabled: false
            }
          },
          {
            name: 'Study Benchmark',
            type: 'spline',
            data: tempDataStudyBenchmark,
            color: '#fbd57e',
            lineWidth: 2,
            marker: {
              enabled: false,
            }
          },
          {
            name: 'Difference',
            type: 'areasplinerange',
            data: getNegativeDifference(data.dataVCRange.map(val => [val[0], Math.min(val[1], val[2])]), data.dataTrueBenchmark), //Show difference if negative
            color: '#FF0000',
            lineWidth: 0,
            zIndex: -1,
            fillOpacity: 0.3,
            marker: {
              enabled: false
            },
            
          },
        ],
        legend: {
          enabled: false
        },
        plotOptions: {
          // disable markers for all series
          series: {
            marker: {
              states: {
                hover: {
                  enabled: false
                }
              }
            },
            // mouse over chart event
            point: {
              events: {
                mouseOver(e) {
                  // mouseOverYear now triggers useEffect which does chart drawing

                  if(e.target.series.name === "Difference"){
                    return
                  }
                  setMouseOverPoint(this)

                  setMouseOverEvent(e)
                  setMouseOverYear(e.target.category) // 2048
                }
              }
            },
            events: {  
              legendItemClick: function () {
                return false
              }
            }
          }
        },
        xAxis: [{
          // visible: false,
          crosshair: true,

          lineWidth: 0,
          visible: true,
          startOnTick: true,
          endOnTick: false,
          maxPadding: 0.03,
          // draws a line where the current year is
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick positions to be start of graph, end of graph, and current year
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear],
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          visible: true,
          title: { enabled: false },
          max: extremes.max,
          // makes minimum always 0, unless minimum point is less than 0
          min: extremes.min > 0 ? 0 : extremes.min,
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [extremes.min, 0, extremes.max]
            }
          }
        }],
        credits: {
          enabled: false
        }
      })

      setChartOptionsExpenditures({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'expenditures',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          split: false,
          formatter: function () {
            const expense = getExpenseFlag(auth.selectedBuilding, this.points[0].x)
            const htmlTooltip = 
            `<div>\
              <b>${this.points[0].x}</b><br/>\
              <span style='color:#5d98d1'>●</span> \
              Contribution: <b>${formatAmount(this.points[0].y, 0)}</b><br/>\
              <span style='color:#f1c21ad0'>●</span>\
              Expenditures: <b>${formatAmount(this.points[1].y, 0)}</b><br/>\
              ${(expense != undefined) 
                ? 
                `<div style='color:#66000000'>-</div>\
                <span style='color:#000000; font-size:6px; text-indent: 3px'>●</span>\
                <p style="text-indent: 40px">${expense}</p>`
                : ""
              }
            </div>`
            return mobileScreen ? false:htmlTooltip
          }
        },
        xAxis: [{
          visible: true,
          // draws line for current year
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be on current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear],
          labels: {rotation: currentYear - data.dataMinYear == 1 ? -45 : labelRotation}
        }],
        yAxis: [{
          visible: true,          
          lineWidth: 0,
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
        }],
        series: [
          {
            name: 'Contribution',
            type: 'column',
            data: data.dataContribution,
            color: '#5d98d1',
            marker: {
              enabled: false
            }
          },
          {
            name: 'Expenditures',
            type: 'column',
            data: data.dataExpenditures,
            lineWidth: 0,
            color: '#f1c21ad0',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'reserve-fund-comparison', 'rates'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false
              }
            },
            stickyTracking: true
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsRate({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'rates',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          split: false,
          crosshairs: true,
          shared: true,
          formatter: function () {
            var html_tooltip = '<b>Year ' + this.x + '</b><br/>'
            for (let i in this.points)
            {
              html_tooltip += '<span style="color:' + this.points[i].series.color + ';">\u25CF </span>' + this.points[i].series.name + ': ' + '<b>' + formatAmount(this.points[i].y * 100, 2, false, true) + '%' + '</b><br/>'
            }
            return mobileScreen ? false:html_tooltip
          },
        },
        xAxis: [{
          visible: true,
          gridLineWidth: 1,
          gridLineColor: "#5d98d1",
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear],
          labels: {rotation: currentYear - data.dataMinYear == 1 ? -45 : labelRotation}
        }],
        yAxis: [{
          visible: true,
          // max: .1,
          min: 0,
          // tickPositions: [0],
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {              
              return this.value * 100 + '%'              
            }
          }
        }],
        series: [
          {
            name: 'Contribution Rate',
            data: data.dataContributionRate,
            color: '#5d98d1',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          },
          {
            name: 'Interest Rate',
            data: data.dataInterestRate,
            color: '#a7b661',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          },
          {
            name: 'Inflation Rate',
            data: data.dataInflationRate,
            color: '#f1c21ad0',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'reserve-fund-comparison', 'expenditures'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false
              }
            }
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsComposition({
        chart: {
          type: 'area',
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'composition',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          split: false,
          formatter: function () {
            const expense = getExpenseFlag(auth.selectedBuilding, this.points[0].x)
            const htmlTooltip = 
            `<div>\
              <b>${this.points[0].x}</b>\
              <br/>\
              <span style='color:#5d98d1'>●</span> \
              Cash: <b>${(this.points[0].percentage).toFixed(1)}%</b> (${formatAmountKM(this.points[0].y, 0)}) <br/>\
              <span style='color:#f55656'>●</span> \
              Neg. Cash: <b>${(this.points[1].percentage).toFixed(1)}%</b> (${formatAmountKM(this.points[1].y, 0)}) <br/>\
              <span style='color:#a7b661'>●</span> \
              GIC: <b>${(this.points[2].percentage).toFixed(1)}%</b> (${formatAmountKM(this.points[2].y, 0)}) <br/>\
              <span style='color:#f1c21ad0'>●</span>\
              MLGIC: <b>${(this.points[3].percentage).toFixed(1)}%</b> (${formatAmountKM(this.points[3].y, 0)}) <br/>\
            </div>`
            return mobileScreen ? false:htmlTooltip
          }
        },
        xAxis: [{
          visible: true,
          // draws line for current year
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be on current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear],
          labels: {rotation: currentYear - data.dataMinYear == 1 ? -45 : labelRotation}
        }],
        yAxis: [{
          title: { enabled: false},
          visible: true,          
          lineWidth: 0,
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            format: '{value}%'
        }
        }],
        series: [
          {
            name: 'Cash',
            data: data.dataCompositionCash,
            lineWidth: 0,
            color: '#5d98d1',
            marker: {
              enabled: false
            }
          },
          {
            name: 'Negative Cash',
            data: data.dataCompositionNegCash,
            lineWidth: 0,
            color: '#f55656',
            marker: {
              enabled: false
            }
          },
          {
            name: 'GIC',
            data: data.dataCompositionGIC,
            lineWidth: 0,
            color: '#a7b661',
            marker: {
              enabled: false
            }
          },
          {
            name: 'MLGIC',
            data: data.dataCompositionMLGIC,
            lineWidth: 0,
            lineWidth: 0,
            color: '#f1c21ad0',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'reserve-fund-comparison', 'rates'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false
              }
            },
            stickyTracking: true
          },
          area: {
            stacking: 'percent',
            marker: {
                enabled: false
            }
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsRateCalcs({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '44%'
        },
        title: {
          text: 'calculated-rates',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
          enabled: !mobileScreen
        },
        tooltip: {
          split: false,
          crosshairs: true,
          shared: true,
          formatter: function () {
            var html_tooltip = '<b>Year ' + this.x + '</b><br/>'
            for (let i in this.points)
            {
              html_tooltip += '<span style="color:' + this.points[i].series.color + ';">\u25CF </span>' + this.points[i].series.name + ': ' + '<b>' + formatAmount(this.points[i].y * 100, 2, false, true) + '%' + '</b><br/>'
            }
            return mobileScreen ? false:html_tooltip
          },
        },
        xAxis: [{
          visible: true,
          gridLineWidth: 1,
          gridLineColor: "#5d98d1",
          lineWidth: 0,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: currentYear,
            label: 'Current year'
          }],
          // sets tick to be current year
          tickPositioner: function () {
            if (this.tickPositions)
            {
              return [currentYear]
            }
          },
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear],
          labels: {rotation: currentYear - data.dataMinYear == 1 ? -45 : labelRotation}
        }],
        yAxis: [{
          visible: true,
          // max: .1,
          min: 0,
          // tickPositions: [0],
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [{
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
          }],
          labels: {
            formatter: function () {              
              return this.value * 100 + '%'              
            }
          }
        }],
        series: [
          {
            name: 'Simulated Interest Rate',
            data: data.dataCalcInterestRate,
            color: '#a7b661',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          },
          {
            name: 'Simulated Inflation Rate',
            data: data.dataCalcInflationRate,
            color: '#fbd57e',
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          }
        ],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver(e) {
                  onMouseOverSyncRF(e, ['reserve-fund-benchmark', 'reserve-fund-comparison', 'expenditures'])
                },
                // mouseOut() {
                //   onMouseOutSync()
                // }
              }
            },
            events: {
              legendItemClick: function () {
                return false
              }
            }
          }
        },
        credits: {
          enabled: false
        }
      })

      setChartOptionsMinCashBalance({
        chart: {
          borderRadius: 10, // same as other divs in index.css 
          spacing: chartSpacing,
          backgroundColor: "#ffffff",
          height: '40%',
        },
        title: {
          text: 'Min Cash Balance',
          style: {
            display: 'none'
          }
        },
        legend: {
          itemStyle: {
            'cursor': 'default'
          },
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          split: false,
          formatter: function () {
            const expense = getExpenseFlag(auth.selectedBuilding, this.points[0].x)
            const htmlTooltip = 
            `<div>\
              <b>${this.points[0].x}</b><br/>\
              <span style='color:#5d98d1'>●</span> \
              Cash Balance: <b>${formatAmount(this.points[0].y, 0)}</b><br/>\
              <span style='color:#ff0000'>●</span> \
              Min Cash Balance: <b>${formatAmountKM(tempMinCash)}</b><br/>\
            </div>`
            return htmlTooltip
          }
        },
        xAxis: [{
          visible: true,
          lineWidth: 0,
          labels: {rotation: labelRotation}
        }],
        yAxis: [{
          visible: true,          
          lineWidth: 0,
          title: { enabled: false, text: "Canadian Dollars" },
          startOnTick: false,
          endOnTick: false,
          plotLines: [
            {
              color: '#FF0000',
              width: 2,
              value: tempMinCash,
              zIndex: 5,
              label: 'Min Cash Balance'
            },
            {
            color: '#000000',
            width: 2,
            value: 0,
            dashStyle: 'ShortDot',
            label: 'Zero'
            }
          ],
          labels: {
            formatter: function () {
              if (this.value != null)
              {
                return formatAmountKM(this.value)
              }
            }
          },
        }],
        series: [
          {
            name: 'Cash Balance',
            type: 'column',
            data: data.dataCompositionCash,
            color: '#5d98d1',
            marker: {
              enabled: false
            }
          },
          {
            name: 'Min Cash Balance',
            type: 'line',
            color: '#FF0000',
            marker: {
              enabled: false
            }
          }
        ],
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return false
              }
            },
            stickyTracking: true
          }
        },
        credits: {
          enabled: false
        }
      })

    }
  }, [data.dataVCRange, mobileScreen, data.savedConfig])

  // For configuration input modal charts
  useEffect(() => {

    var averageData = Array(8).fill(Number((1).toFixed(1)))
    var varianceData = []
    var lineName = ''
    var rangeName = ''
    var stepSize = 0
    var maxY = 0
    var minY = 0

    // set appropriate values based on chart that is displaying
    if (showInputModal === 3)
    {
      lineName = 'Average'
      rangeName = 'Effect of Varience'
      stepSize = tempInflationVol
      maxY = 10
      minY = -10
    }
    else if (showInputModal === 4)
    {
      lineName = 'Yield'
      rangeName = 'Effect of Volatility'
      stepSize = tempGICVol
      maxY = 10
      minY = -10
    }
    else
    {
      lineName = 'Yield'
      rangeName = 'Effect of Volatility'
      stepSize = tempMLGICVol
      maxY = 20
      minY = -20
    }

    // Creates varianceData for cone chart
    for (let i = 0; i < 8; i++) 
    {
      const row = []
      for (let j = 0; j < 2; j++) 
      {
          if (j == 0)
          {
            row.push(Number((1 - i*stepSize*10).toFixed(1)))
          }
          else
          {
            row.push(Number((1 + i*stepSize*10).toFixed(1)))
          }
          
      }
      varianceData.push(row)
  }


    setChartOptionsPercentMLGIC({
      chart: {
        type: 'bar',
        height: '100vh',
        borderRadius: 10, // same as other divs in index.css 
        spacing: chartSpacing,
        margin: [10, 10, 40, 10],
      },
      title: {
        text: 'Investment Composition',
        style: {
          display: 'none'
        }
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
        reversed: true,
        min: 0,
        max: 100,
        title: {
          text: 'Precent of total funds invested',
        },
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true, 
            format: '{point.y}%', 
            style: {
              textOutline: 'transparent',
            },
          },
        },
      },
      series: [
        {
          name: 'Market Linked GICs',
          data: [Number(tempRiskLevel)*100],
          color: '#A7B661',
        },
        {
          name: 'Traditional GICs',
          data: [100 - Number(tempRiskLevel)*100],
          color: '#5d98d1',
        },
      ],
      credits: {
        enabled: false
      }
    })

    setChartOptionsVolatilityCone({
      chart: {
        height: '40%',
        borderRadius: 10, // same as other divs in index.css 
        spacing: chartSpacing,
        margin: [10, 10, 20, 10],
      },
      title: {
        text: 'effects of yield and volalility',
        style: {
          display: 'none'
        }
      },
      xAxis: {
          title: {
              text: 'Years',
          },
          visible: false,
      },
      yAxis: {
          title: {
              text: 'Value Range',
          },
          min: minY,
          max: maxY,
          visible: false
      },
      tooltip: {
        // crosshairs: true,
        shared: true,
        // split: false,
        formatter: function () {
          return false
        }
      },
      series: [ 
        {
          name: lineName,
          data: averageData,
          type: 'spline',
          color: '#a7b661',
          marker: {
            enabled: false,
            symbol: 'circle'
          }
        }, 
        {
          name: rangeName,
          data: varianceData,
          type: 'areasplinerange',
          lineWidth: 0,
          color: '#a7b661',
          fillOpacity: 0.3,
          marker: {
            enabled: false,
            symbol: 'circle'
          },
        }
      ],
      credits: {
        enabled: false
      }
    })

  }, [showInputModal])

  // Mouse Over X-Axis Tick Hook - triggered when mouse over main chart point changes
  useEffect(() => {
    if (mouseOverYear)
    {
      //----------------------------------------------------
      // Transparent box to right of hovered point
      //----------------------------------------------------

      var width = mouseOverPoint.series.chart.plotWidth - mouseOverPoint.plotX,
          height = mouseOverPoint.series.chart.plotHeight
      
      // destory box if already exists
      if (mouseOverPoint.series.chart.transparentBox != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.transparentBox).length > 0) mouseOverPoint.series.chart.transparentBox.destroy()
      }

      // adjust x coordinates of box and markers depending on starting position of chart (varies depending on y-axis ticks)
      var xAxisPadding = 0
      if (data.dataExtremes.max < 10000000)
      {
        if (data.dataExtremes.min < 0) xAxisPadding = 10
        else xAxisPadding = 5
      }
      else if (data.dataExtremes.min < 0)
      {
        if (data.dataExtremes.min < -10000000) xAxisPadding = 8
        else xAxisPadding = 10
      }

      // draw transparent box to right of hovered point
      mouseOverPoint.series.chart.transparentBox = mouseOverPoint.series.chart.renderer.rect(mouseOverPoint.plotX + 65 + xAxisPadding + 1, 0, width + 15, height + 19, 1, 0)
        .attr({
          fill: "rgba(255,255,255,0.75)",
          zIndex: 3
        })
        .add()

      //----------------------------------------------------
      // Draw Markers
      //----------------------------------------------------
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.marker1 != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.marker1).length > 0)
        {
          mouseOverPoint.series.chart.marker1.destroy()
          if (data.savedConfig) mouseOverPoint.series.chart.marker2.destroy()
        }
      }

      // True Benchmark (blue marker)
      mouseOverPoint.series.chart.marker1 = mouseOverPoint.series.chart.renderer.rect(
        mouseOverPoint.plotX + xAxisPadding  + (mobileScreen ? 39:59.9), 
        mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].plotY + (mobileScreen?5.5:15.5), 
        10, 10, 5, 0
      ).attr({
        fill: "#5d98d1", 
        zIndex: 4
      }).add()

      // Vertical City (green marker)
      if(data.savedConfig)
      {
        mouseOverPoint.series.chart.marker2 = mouseOverPoint.series.chart.renderer.rect(
          mouseOverPoint.plotX + xAxisPadding + (mobileScreen ? 39:59.9), 
          mouseOverPoint.series.chart.series[1].data[mouseOverPoint.index].plotY + (mobileScreen?5.5:15.5), 
          10, 10, 5, 0
        ).attr({
          fill: "#a7b661", 
          zIndex: 4
        }).add()   
      }
           

      //----------------------------------------------------
      // Draw Data Label (next to points)
      //----------------------------------------------------
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.dataLabel != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.dataLabel).length > 0) mouseOverPoint.series.chart.dataLabel.destroy()
      }
      
      if (data.savedConfig)
      {
        var seriesA = mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].plotY
        var y // calc middle point between 2 points
        var value // calc difference of 2 points 
        var seriesC = mouseOverPoint.series.chart.series[1].data[mouseOverPoint.index].plotY
        y = ((seriesA - seriesC) / 2) + seriesC
        value = mouseOverPoint.series.chart.series[1].data[mouseOverPoint.index].y - mouseOverPoint.series.chart.series[2].data[mouseOverPoint.index].y

        var color = (value > 0) ? 'green' : 'red'
        var html = `<div class='data-label' id=${color}>${(color == 'green' ? '+' : '')}${formatAmountKM(value)}</div>`
        var xAxisLabelPadding = 72

        if (mouseOverPoint.index == mouseOverPoint.series.points.length - 1) xAxisLabelPadding = 62

        // TODO styling, bolder
        if (value != 0) mouseOverPoint.series.chart.dataLabel = mouseOverPoint.series.chart.renderer.label(
          mobileScreen ? null:html,
          mouseOverPoint.plotX + xAxisLabelPadding + xAxisPadding,
          y + 6.8, // TODO adjust this value depending on shape/size of label
          null, null, null, true
        ).attr({
          zIndex: 4
        }).add()
      }

      //----------------------------------------------------
      // Update Main Chart Label
      //----------------------------------------------------
      
      setLabelPoint(mouseOverPoint)
      //refreshReserveLabel(mouseOverPoint)

      //----------------------------------------------------
      // Sync All Charts
      //----------------------------------------------------

      onMouseOverSyncRF(mouseOverEvent, ['rates', 'expenditures'])

      //----------------------------------------------------
      // Draw X Axis Tick
      //----------------------------------------------------

      const tempReserveOptions = {
        xAxis: [{
          tickPositions: [data.dataMinYear, currentYear, mouseOverYear, data.dataMaxYear]
        }],
      }
      setChartOptionsReserve(tempReserveOptions)      
    }
  }, [mouseOverYear, auth.isContextSecured])

  // Mouse Out Chart Component Hook
  useEffect(() => {
    if (Object.keys(mouseOverPoint).length == 0) return
    if (mouseOverPoint.index == null) return
    if (data.recalculationBuffering) return
    if (mouseOver == false)
    {
      
      //----------------------------------------------------
      // Destroy Drawn SVGs
      //----------------------------------------------------

      // destory box if already exists
      if (mouseOverPoint.series.chart.transparentBox != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.transparentBox).length > 0) mouseOverPoint.series.chart.transparentBox.destroy()
      }
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.marker1 != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.marker1).length > 0)
        {
          mouseOverPoint.series.chart.marker1.destroy()
          if (data.savedConfig) mouseOverPoint.series.chart.marker2.destroy()
        }
      }
      
      // destory markers if already exists
      if (mouseOverPoint.series.chart.dataLabel != undefined)
      {
        if (Object.keys(mouseOverPoint.series.chart.dataLabel).length > 0) mouseOverPoint.series.chart.dataLabel.destroy()
      }
      
      //----------------------------------------------------
      // Reset Main Chart Label
      //----------------------------------------------------

      //resetReserveChart()
      onMouseOutSync(['rates', 'expenditures'])

      //----------------------------------------------------
      // Remove X Tick
      //----------------------------------------------------

      const tempReserveOptions = {
        xAxis: [{
          tickPositions: [data.dataMinYear, currentYear, data.dataMaxYear]
        }],
      }
      setChartOptionsReserve(tempReserveOptions)
    }
  }, [mouseOver])

  //------------------------------------------------------------------------------------------------------------------
  // Handle Function
  //------------------------------------------------------------------------------------------------------------------

  function handleSelectYear(year) {
    setMouseOverPoint({}) // this is necessary to prevent onMouseOut useEffect hook from throwing error on null chart object
    setSelectedYear(year)
    auth.selectStudy(year.toString())

    // if (currentYear - year > 0) setShowMiniDiagram(true)
    // else setShowMiniDiagram(false)
  }

  function handleCalculate(year) {
    // check if called from button click or not
    if (typeof year != 'number') year = selectedYear
    var body

    if (data.historical)
    {
      body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": year, "window": tempWindow, "max_pct_mlgic": tempRiskLevel,
        "min_cash_holdback": tempMinCash, "inf_rate_yield": tempInflationYield, "inf_rate_vol": tempInflationVol,
        "int_rate_yield": tempGICYield, "int_rate_vol": tempGICVol, "mlgic_yield": tempMLGICYield, "mlgic_vol": tempMLGICVol, 'cash_int_rate': tempCashIntRate}
      // data.fetchCashflowHistory(body)
      return // TODO remove this for testing
    }
    else
    {
      body = { "email": auth.email, "building_short_name": auth.selectedBuilding, "year": year, "max_pct_mlgic": tempRiskLevel,
        "min_cash_holdback": tempMinCash, "inf_rate_yield": tempInflationYield, "inf_rate_vol": tempInflationVol,
        "int_rate_yield": tempGICYield, "int_rate_vol": tempGICVol, "mlgic_yield": tempMLGICYield, "mlgic_vol": tempMLGICVol, 'cash_int_rate': tempCashIntRate}
      data.fetchCashflowCustom(body)
    }
  }

  function handleSaveConfiguration() {
    if (selectedYear != data.availableRFStudies[0].year)
    {
      setShowSubmitSettingsWarningModal(true)
    }
    else
    {
      setShowSubmitSettingsModal(true)
    }
  }

  //------------------------------------------------------------------------------------------------------------------
  // Callback Functions
  //------------------------------------------------------------------------------------------------------------------

  const handleSyncCharts = useCallback((e, chartNames, all=false) => {
    onMouseOverSyncRF(e, chartNames, all)
  }, [])
  
  const handleCloseOffCanvas = useCallback(() => {
    setShowOffCanvas(false)
  }, [])

  const handleCloseOnboarding = useCallback(() => {
    setShowOnboardingModal(false)
  }, [])

  const handleSelectStudy = useCallback((year) => {
    data.setShowSelectStudyModal(false)
    handleSelectYear(year)
  }, [])

  //------------------------------------------------------------------------------------------------------------------
  // Components
  //------------------------------------------------------------------------------------------------------------------

  function TotalsHeader() {
    if (Object.keys(labelPoint).length === 0 ) return <></>
    if (labelPoint.category == null) return <></>
    const chart = labelPoint.series.chart
    const index = labelPoint.index
    const year = labelPoint.x

    var studyBenchmark = null
    var benchmark = null
    var benchmarkRange = null
    var vc = null
    var vcRange = null

    // sets label data
    chart.series.forEach(s => {
      if (s.name === "Study Benchmark")
      {
        studyBenchmark = s.yData[index]
      }
      else if (s.name === "True Benchmark")
      {
        benchmark = s.yData[index]
      }
      else if (s.name === "True Benchmark Range")
      {
        benchmarkRange = s.yData[index]
      }
      else if (s.name === "Vertical City")
      {
        vc = s.yData[index]
      }
      else if (s.name === "Vertical City Range")
      {
        vcRange = s.yData[index]
      }
    })

    var difference = vc - benchmark
    var pos = false
    if (difference > 0) pos = true
    var numYears = year - currentYear

    function Difference() {
      return (
        <button className='component-container info-button green' style={{'width':'100%', 'paddingTop': '0px', 'paddingBottom': '10px'}} onClick={() => setShowHeaderModal(1)}>
          <div id='difference' className='flex-column-breakpoint'>
            <div style={{'width':'90%', 'marginBottom':"0px"}}>
              <h5 id='difference'>Difference</h5>
              <p>(Custom - Simulated)</p>
            </div>
            <div className='vertical'>
              <div  className='flex'>
                <div className={'amount medium ' + (pos ? 'positive': (difference == 0 ? 'nill': 'negative'))}>{pos ? '+': ''}{formatAmount(difference, 0)}</div>
              </div>
              <p id='difference' className='smaller right' style={{'marginTop': '-10px'}}>In {numYears} years</p>
            </div>
          </div>
        </button>
      )
    }

    function TrueBenchmark() {
      return (
        <button className='component-container info-button blue' style={{'width':'100%', 'padding': '0px 10px 10px','marginRight':'10px'}} onClick={() => setShowHeaderModal(2)}>
          <div className='flex-column-breakpoint'>
            <div style={{'width':'80%'}}>
              <h5 id='simulated' style={{'color':'#5d98d1'}}>Simulated</h5>
              <p id='portfolio-date' style={{'marginLeft': '2px', 'marginTop':'-5px'}}>As of {year}</p>
            </div>
            <div className='vertical'>
              <div  className='flex'>
                <div className='legend-size circle-legend' id='true-bm'/>
                <div className='amount medium'>{formatAmount(benchmark, 0)}</div>
              </div>
              <div >
                <p id='range' className='smaller right'>${formatAmountKM(benchmarkRange[0], 0) + " to $" + formatAmountKM(benchmarkRange[1], 0)}</p>
              </div>
            </div>
          </div>
        </button>
      )
    }

    function CustomBenchmark() {
      return (
        <button className='component-container info-button green' style={{'width':'100%', 'padding': '0px 10px 10px'}} onClick={() => setShowHeaderModal(3)}>
          <div className='flex-column-breakpoint'>
            <div style={{'width':'80%'}}>
              <h5 id='simulated' style={{'color': '#a7b661'}}>Custom</h5>
              <p id='portfolio-date' style={{'marginLeft': '2px','marginTop':'-5px'}}>As of {year}</p>
            </div>
            <div className='vertical'>
              <div  className='flex'>
                <div className='legend-size circle-legend' id='vc-bm' />
                <div className='amount medium'>{formatAmount(vc, 0)}</div>
              </div>
              <div >
                <p id='range' className='smaller right'>${formatAmountKM(vcRange[0], 0) + " to $" + formatAmountKM(vcRange[1], 0)}</p>
              </div>
            </div>
          </div>
        </button>
      )
    }

    function StudyBenchmark() {
      return (
        <button className='component-container' style={{'width':'37.5%', 'paddingTop': '0px'}} onClick={() => setShowHeaderModal(2)}>
            <div className='flex-column-breakpoint'>
              <div style={{'width':'90%'}}>
                <h5 style={{'marginTop': '25px', 'color': '#f1c31a'}}>Study</h5>
                <p id='portfolio-date' style={{'marginLeft': '2px'}}>As of {year}</p>
              </div>
              <div className='vertical'>
                <div  className='flex'>
                  <div className='legend-size circle-legend' id='study-bm' />
                  <div className='amount medium'>{formatAmount(studyBenchmark, 0)}</div>
                </div>
              </div>
            </div>
        </button>
      )
    }

    function NoInput() {
      return (
        <div className='cell-description component-container' style={{'width':'100%', 'padding':'10px'}}>
          <div className='flex-column-breakpoint' style={{'justifyContent': 'center','alignItems':'center', 'textAlign':'center', 'height':'100%',}}>
            <h5 id='noinput'>Enter Configurations Below!</h5>
            <img style={{'marginLeft': '10px', 'transform':'scaleY(-1)'}} src={handPointer} alt="Hand Pointer"/>
          </div>
        </div>
      ) 
    }

    if (studyBenchmark)
    {
      return (
        <div className='flex-column-mobile totals' style={{'marginBottom': '10px', 'marginTop': '5px', 'gridGap': '10px'}}>
          <div className='totals' style={{'width':'33%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
            <StudyBenchmark />
          </div>
          <div className='totals' style={{'width':'66%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
            <TrueBenchmark />
            <Difference />
          </div>
        </div>
     )
    }
    else
    {
      return (
        <div className='flex-column-mobile totals' style={{'marginBottom': '10px', 'marginTop': '5px', 'gridGap': '10px'}}>
          <div className='totals' style={{'width':'33%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
            <Difference />
          </div>
          <div className='totals' style={{'width':'66%','display':'flex', 'marginTop':'0px', 'marginBottom':'0px'}}>
            <TrueBenchmark />
            {data.savedConfig && <CustomBenchmark />}
            {!data.savedConfig && <NoInput />}
          </div>
        </div>
     )
    }
  }

  // TODO add more content to each modal; draw diagrams for absolute beginers
  // TODO improve writing of each modal v2
  function HeaderModal(props) {
    var title
    var text
    var idCircleLegend = ""
    var subheader
    if (showHeaderModal==1)
    {
      title = "Difference"
      text = <span>This is the difference between the <span style={{'color': '#a7b661', 'font-weight': '600'}}>Custom</span> line and the <span style={{'color': '#5d98d1', 'font-weight': '600'}}>Simulated</span> line. If the number is <span style={{'color': '#f55656'}}>negative</span> it means that your saved configuration will perform worse than if didn't change anything. If it is <span style={{'color': '#98b02c'}}>positive</span>, it means your saved configuration will improve the fund compared to not changing anything.</span>
      subheader = <div>(<span style={{'color': '#a7b661'}}>Custom</span> - <span style={{'color': '#5d98d1'}}>Simulated</span>)</div>
    }
    else if (showHeaderModal==2)
    {
      title = 'Simulated'
      text = "This is a more accurate forecast of the reserve fund using the same expenditures and contributions as the reserve fund study. It's basically the 2.0 version of the Cashflow Table that you are used to using. This line is simulated using forecasted interest and inflation rates as well as by using a more realistic forecasting process than that of a Reserve Fund Study."
      idCircleLegend = "true-bm"
      subheader = <div>The better forecast</div>
    }
    else if (showHeaderModal==3)
    {
      title = 'Custom'
      text = <span>This is the line showing what your reserve fund would look like under the new configurations that you have saved below. For more information on how this process works, you can read more <a target="_blank" href='https://www.verticalcity.ca/post/vertical-city-toolkit-reserve-fund-forecasting#viewer-c7p47'>here</a>.</span>
      idCircleLegend = "vc-bm"
      subheader = <div>Saved Configuration</div>
    }

    return (
      <Modal className="totals-modal totals" dialogClassName="modal-width" show={props.show} onHide={() => setShowHeaderModal(0)} size="lg" centered>
        <Modal.Header>
          <div>
            <div className='flex'>
              <Modal.Title>{title}</Modal.Title>
              {idCircleLegend && <div className='legend-size circle-legend' id={idCircleLegend} style={{'margin-right': '0', 'margin-left': '10px'}} />}
            </div>
            {subheader}
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className='space-between'>
          {text}
        </div>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalSettingsHistory(props) {
    const [selectedRowIndex, setSelectedRowIndex] = useState(null)

    const handleRowClick = (index) => {
      setSelectedRowIndex(index)
    }
  
    // updates state of page component inputs
    function handleSelectInputs() {
      if (selectedRowIndex !== null) {
        setTempRiskLevel(data.buildingSettingsHistory.max_pct_mlgic[selectedRowIndex])
        setTempMinCash(data.buildingSettingsHistory.min_cash_holdback[selectedRowIndex])
        setTempInflationYield(data.buildingSettingsHistory.inf_rate_yield[selectedRowIndex])
        setTempInflationVol(data.buildingSettingsHistory.inf_rate_vol[selectedRowIndex])
        setTempGICYield(data.buildingSettingsHistory.int_rate_yield[selectedRowIndex])
        setTempGICVol(data.buildingSettingsHistory.int_rate_vol[selectedRowIndex])
        setTempMLGICYield(data.buildingSettingsHistory.mlgic_yield[selectedRowIndex])
        setTempMLGICVol(data.buildingSettingsHistory.mlgic_vol[selectedRowIndex])
        setTempCashIntRate(data.buildingSettingsHistory.cash_int_rate[selectedRowIndex])
      }
      setShowModalSettingsHistory(false)
    }

    const numberOfRows = data.buildingSettingsHistory.date.length

    return (
      <Modal show={props.show} onHide={() => setShowModalSettingsHistory(false)} backdrop="static" size="lg" className="settings-history-modal" dialogClassName="modal-width">
        <Modal.Header closeButton>
          <Modal.Title>Configuration History</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: "0px" }}>
          <div style={{ marginBottom: "10px" }}>
          Browse your building's saved configuration history. Click on a row and click the 'Select Inputs' button to update your current configuration.
          </div>
          <Table className="save-config-table" hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Max % MLGIC</th>
                <th>Min Cash Holdback</th>
                <th>MLGIC Yield</th>
                <th>MLGIC Vol</th>
                <th>Int Rate Yield</th>
                <th>Int Rate Vol</th>
                <th>Inf Rate Yield</th>
                <th>Inf Rate Vol</th>
                {/* <th>Cash Int Rate</th> */}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: numberOfRows }).map((_, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(index)}
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedRowIndex === index ? "#a6b661c0" : "",
                }}
              >
                <td style={{width: '12%'}}>{formatDate(YYYYMMDDtoDate(data.buildingSettingsHistory.date[index]))}</td>
                <td style={{margin: 'auto'}}>{toPercentString(data.buildingSettingsHistory.max_pct_mlgic[index], true)}</td>
                <td>{formatAmount(data.buildingSettingsHistory.min_cash_holdback[index], 0)}</td>
                <td>{toPercentString(data.buildingSettingsHistory.mlgic_yield[index])}</td>
                <td>{toPercentString(data.buildingSettingsHistory.mlgic_vol[index])}</td>
                <td>{toPercentString(data.buildingSettingsHistory.int_rate_yield[index])}</td>
                <td>{toPercentString(data.buildingSettingsHistory.int_rate_vol[index])}</td>
                <td>{toPercentString(data.buildingSettingsHistory.inf_rate_yield[index])}</td>
                <td>{toPercentString(data.buildingSettingsHistory.inf_rate_vol[index])}</td>
                {/* <td>{toPercentString(data.buildingSettingsHistory.cash_int_rate[index])}</td> */}
              </tr>
              ))}
          </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setShowModalSettingsHistory(false)}>Cancel</Button>
          <Button variant="success" onClick={handleSelectInputs} disabled={selectedRowIndex === null}>Select Inputs</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function SubmitSettingsModal(props) {
    // variables to keep track of what confics have been changed
    var changedPerMLGIC = true
    var changedMinCash = true
    var changedInfAve = true
    var changedInfVar = true
    var changedGICYield = true
    var changedGICVol = true
    var changedMLGICYield = true
    var changedMLGICVol = true
    var changedConfigs = []

    function handleSaveConfig() {
      setShowSubmitSettingsModal(false)
      const body = {
        "email": auth.email,
        "building_short_name": auth.selectedBuilding,
        "year": selectedYear,
        "max_pct_mlgic": tempRiskLevel,
        "min_cash_holdback": tempMinCash,
        "mlgic_yield": tempMLGICYield, 
        "mlgic_vol": tempMLGICVol, 
        "inf_rate_yield": tempInflationYield, 
        "inf_rate_vol": tempInflationVol,
        "int_rate_yield": tempGICYield, 
        "int_rate_vol": tempGICVol, 
        "cash_int_rate": tempCashIntRate
      }

      data.uploadBuildingSettings(body)
    }

    // set value of variables keeping track of what configs have changed
    if (data.savedRiskLevel === tempRiskLevel)
    {
      changedPerMLGIC = false 
      changedConfigs.push(' Market Linked GICs Percentage')
    }
    if (data.savedMinCash === tempMinCash)
    {
      changedMinCash = false
      changedConfigs.push(' Min Cash Balance')
    }
    if (data.savedInflationYield === tempInflationYield)
    {
      changedInfAve = false
      changedConfigs.push(' InflationRate Average')
    }
    if (data.savedInflationVol === tempInflationVol)
    {
      changedInfVar = false
      changedConfigs.push(' Inflation Rate Variance')
    }
    if (data.savedGICYield === tempGICYield)
    {
      changedGICYield = false
      changedConfigs.push(' GIC Interest Rate Yield')
    }
    if (data.savedGICVol === tempGICVol)
    {
      changedGICVol = false
      changedConfigs.push(' GIC Interest Rate Volatility')
    }
    if (data.savedMLGICYield === tempMLGICYield)
    {
      changedMLGICYield = false
      changedConfigs.push(' Market Linked GIC Yield')
    }
    if (data.savedMLGICVol === tempMLGICVol)
    {
      changedMLGICVol = false
      changedConfigs.push(' Market Linked GIC Volatility')
    }

    // TODO make this modal look more organized - don't have multiple titles for setting; use table format
    return (
      <Modal show={props.show} onHide={() => setShowSubmitSettingsModal(false)} backdrop="static" size="md" className="submit-settings-modal" dialogClassName="modal-width">
        <Modal.Header closeButton>
            <Modal.Title>Save Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{'paddingBottom':'0px'}}>
        <div style={{'marginBottom': '10px'}}>Are you sure you want to save the following calculator configuration to your building's profile?</div>
        <Table className='save-config-table'>
            <thead>
              <tr>
                <th>Configuration</th>
                <th>Old</th>
                <th>New</th>
              </tr>
            </thead>
            <tbody>
              {(changedPerMLGIC) && (
              <tr>
                <td >Market Linked GIC Composition</td>
                <td>{toPercentString(data.savedRiskLevel)}</td>
                <td>{toPercentString(tempRiskLevel)}</td>
              </tr>
              )}
              {(changedMinCash) && (
              <tr>
                <td>Minimum Cash Balance</td>
                <td>{formatAmount(data.savedMinCash, 0)}</td>
                <td>{formatAmount(tempMinCash, 0)}</td>
              </tr>
              )}
              {((changedInfAve) || (changedInfVar)) && (
              <tr id='group-top' style={{'borderColor':'#ffffff'}}>
                <td style={{'paddingBottom':'0px'}}>
                  <b>Inflation Rate</b>
                </td>
                <td></td>
                <td></td>
              </tr>
              )}
              {(changedInfAve) && (
              <tr id={(changedInfVar) ? 'group-middle':'group-bottom'} style={(changedInfVar) ? {'borderColor':'#ffffff'}:{}}>
                <td >Average</td>
                <td>{toPercentString(data.savedInflationYield)}</td>
                <td>{toPercentString(tempInflationYield)}</td>
              </tr>
              )}
              {(changedInfVar) && (
              <tr id='group-bottom'> 
                <td>Variance</td>
                <td>{toPercentString(data.savedInflationVol)}</td>
                <td>{toPercentString(tempInflationVol)}</td>
              </tr>
              )}
              {((changedGICYield) || (changedGICVol)) &&(
              <tr id='group-top' style={{'borderColor':'#ffffff'}}>
                <td>
                  <b>GIC Interest Rate</b>
                </td>
                <td></td>
                <td></td>
              </tr>
              )}
              {(changedGICYield) && (
              <tr id={(changedGICVol) ? 'group-middle':'group-bottom'} style={(changedGICVol) ? {'borderColor':'#ffffff'}:{}}>
                <td>Yield</td>
                <td>{toPercentString(data.savedGICYield)}</td>
                <td>{toPercentString(tempGICYield)}</td>
              </tr>
              )}
              {(changedGICVol) && (
              <tr id='group-bottom'>
                <td>Volatility</td>
                <td>{toPercentString(data.savedGICVol)}</td>
                <td>{toPercentString(tempGICVol)}</td>
              </tr>
              )}
              {((changedMLGICYield) || (changedMLGICVol)) && (
              <tr id='group-top' style={{'borderColor':'#ffffff'}}>
                <td>
                  <b>Market Linked GIC</b>
                </td>
                <td></td>
                <td></td>
              </tr>
              )}
              {(changedMLGICYield) && (
              <tr id={(changedMLGICVol) ? 'group-middle':'group-bottom'} style={(changedMLGICVol) ? {'borderColor':'#ffffff'}:{}}>
                <td>Yeild</td>
                <td>{toPercentString(data.savedMLGICYield)}</td>
                <td>{toPercentString(tempMLGICYield)}</td>
              </tr>
              )}
              {(changedMLGICVol) && (
              <tr id='group-bottom' style={(changedConfigs.length === 0) ? {'borderColor':'#ffffff'}:{}}>
                <td>Volatility</td>
                <td>{toPercentString(data.savedMLGICVol)}</td>
                <td>{toPercentString(tempMLGICVol)}</td>
              </tr>
              )}
            </tbody>
          </Table>
          {(changedConfigs.length > 0) && (<p style={{'color':'#808080', 'fontSize':'12px','margin':'10px 0px'}}>
            The following {changedConfigs.length} inputs have not been changed: {changedConfigs.toString()}.
          </p>)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setShowSubmitSettingsModal(false)}>Cancel</Button>
          <Button variant="success" onClick={handleSaveConfig}>Save</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  // TODO style, make bigger than other modal and positioned in the middle
  function SubmitSettingsWarningModal(props) {
    const newestYear = data.availableRFStudies[0].year
    function handleSaveAnyways() {
      setShowSubmitSettingsWarningModal(false)
      setShowSubmitSettingsModal(true)
    }
    function handleSeeOperating() {
      setBypassRequest(true) // skip default get cashflow request that is fired when selectedYear is changed
      setShowSubmitSettingsWarningModal(false)
      handleSelectYear(newestYear)
      handleCalculate(newestYear)
    }
    // TODO complete buttons
    return (
      <Modal show={props.show} onHide={() => setShowSubmitSettingsWarningModal(false)} size="md" className="submit-settings-modal" dialogClassName="modal-width">
        <Modal.Header closeButton>
            <Modal.Title>Warning: Old Study Selected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          By saving the currently selected configuration, all reserve fund studies will be processed, including the operating study (the newest one - {newestYear} for this building) which is used for the other app features i.e. Next Actions and Report Card.
          <br></br><br></br>
          You may want to see how these changes affect the operating study before saving them.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-success" onClick={() => setShowSubmitSettingsWarningModal(false)}>Cancel</Button>
          <Button variant="success" onClick={handleSeeOperating}>See Operating</Button>
          <Button variant="success" onClick={handleSaveAnyways}>Save Anyways</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  function InputsModal(props) {

    var title
    var text
    var mobileText
    var chart

    if (showInputModal === 1)
    {
      title = "Market Linked GICs"
      chart = chartOptionsPercentMLGIC
      mobileText = "This input slider allows users to control the percentage of their investment allocated to Market-Linked GICs compaired to regular GICs. Regular GICs offer fixed interest rates for predictable returns, while Market-Linked GICs combine the security of GICs with variable returns linked to market performance, providing potential for higher gains along with some level of risk."
      text = "This input slider allows users to control the percentage of their investment allocated to Market-Linked GICs compaired to regular GICs. Regular GICs offer fixed interest rates for predictable returns, while Market-Linked GICs combine the security of GICs with variable returns linked to market performance, providing potential for higher gains along with some level of risk. The graph below is a distribution of the total funds invested, showing the percentage invested in Market-Linked GICs and traditional GICs."
    }
    else if (showInputModal === 2)
    {
      title = "Min Cash Balance"
      chart = chartOptionsMinCashBalance
      mobileText = "This input slider allows users to control the minimum cash balance of the reserve fund. The minimum cash balance refers to the amount of cash that an investor holds as a reserve without being invested. The reserve fund typically maintains a cash balance above the set minimum amount; however, occasional optimizations may result in temporary dips below this threshold."
      text = "This input slider allows users to control the minimum cash balance of the reserve fund. The minimum cash balance refers to the amount of cash that an investor holds as a reserve without being invested. The reserve fund typically maintains a cash balance above the set minimum amount; however, occasional optimizations may result in temporary dips below this threshold. The graph below shows the minimum cash balance compared to the total cash balance of the reserve fund. "
    }
    else if (showInputModal === 3)
    {
      title = "Inflation Rate"
      chart = chartOptionsVolatilityCone
      mobileText = "These input sliders allow users to control the average and variance of the inflation rate. Average refers to the average value of the inflation rate, typically expressed as a percentage. Variance measures the degree of variation in the inflation rate, indicating the extent of fluctuations and unpredictability in its market performance."
      text = "These input sliders allow users to control the average and variance of the inflation rate. Average refers to the average value of the inflation rate, typically expressed as a percentage. Variance measures the degree of variation in the inflation rate, indicating the extent of fluctuations and unpredictability in its market performance. The graph bellow shows the effects variance and average have on the inflation rate."
    }
    else if (showInputModal === 4)
    {
      title = "GIC Interest Rate"
      chart = chartOptionsVolatilityCone
      mobileText = "These input sliders allow users to control the yield and volatility of the interest rate of the GICs. Yield refers to the income generated by an investment, typically expressed as a percentage, representing the return on the investment relative to its cost or current value. Volatility measures the degree of variation in the interest rate, indicating the extent of fluctuations and unpredictability in its market performance."
      text = "These input sliders allow users to control the yield and volatility of the interest rate of the GICs. Yield refers to the income generated by an investment, typically expressed as a percentage. Volatility measures the degree of variation in the interest rate, indicating the extent of fluctuations and unpredictability in its market performance. The graph bellow shows the effects variance and average have on the GIC interest rate."
    }
    else if (showInputModal === 5)
    {
      title = "Market Linked GIC"
      chart = chartOptionsVolatilityCone
      mobileText = "These input sliders allow users to control the yield and volatility of the Market Linked GICs. Yield refers to the income generated by an investment, typically expressed as a percentage, representing the return on the investment relative to its cost or current value. Volatility measures the degree of variation in the Market Linked GICs, indicating the extent of fluctuations and unpredictability in its market performance."
      text = "These input sliders allow users to control the yield and volatility of the Market Linked GICs. Yield refers to the income generated by an investment, typically expressed as a percentage. Volatility measures the degree of variation in the Market Linked GICs, indicating the extent of fluctuations and unpredictability in its market performance. The graph bellow shows the effects variance and average have on the Market Linked GIC interest rate."
    }

    return (
      <Modal className="config-modal totals" dialogClassName="modal-width" show={props.show} onHide={() => setShowInputModal(0)} size="lg"  centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!mobileScreen && text}
          {mobileScreen && mobileText}
          {!mobileScreen && (showInputModal === 1 || showInputModal === 2) && (
            <div className="flex" style={{'flexDirection':'column','width':'70%', 'margin':'auto'}}>
              <HighchartsReact highcharts={Highcharts} options={chart} />
            </div>
          )}
          { !mobileScreen && !(showInputModal === 1 || showInputModal === 2) && (
            <div className="flex" style={{'flexDirection':'column','width':'50%', 'margin':'auto'}}>
              <HighchartsReact highcharts={Highcharts} options={chart} />
            </div>
          )}
        </Modal.Body>
      </Modal>
    )
  }

  const configurationUnchanged = (
    tempRiskLevel == data.savedRiskLevel && 
    tempMinCash == data.savedMinCash && 
    tempInflationVol == data.savedInflationVol &&
    tempInflationYield == data.savedInflationYield &&
    tempGICVol == data.savedGICVol &&
    tempGICYield == data.savedGICYield &&
    tempMLGICVol == data.savedMLGICVol &&
    tempMLGICYield == data.savedMLGICYield
  )

  const handleInnerClick = (e) => {
    e.stopPropagation()
  }

  return (
    <div>
      {showOffCanvas && data?.dataInflationRate?.[0]?.[1] != undefined && !data.showSelectStudyModal && <OffCanvasCashflow show={showOffCanvas} selectedYear={selectedYear} selectedBuilding={auth.selectedBuilding} studyString={getStudyFromYear(selectedYear)} onSyncCharts={handleSyncCharts} onClose={handleCloseOffCanvas} inflation={data?.dataInflationRate[0][1]} interest={data?.dataInterestRate[0][1]} dataOpening={data.dataOpening} dataContribution={data.dataContribution} dataExpenditures={data.dataExpenditures} dataInterestIncome={data.dataInterestIncome} dataClosing={data.dataClosing} mouseOverYear={mouseOverYear} mouseOver={mouseOver} />}
      {data.showMissingRFData && <MissingData text="There are no Reserve Fund Studies linked to your account." pageSource="cashflow" />}
      {data.showProcessingData && <MissingData text="Please wait for your Reserve Fund Study to finish being processed. Refresh this page to check if it is." pageSource="cashflow" showUpload={false} />}
      {data.showSelectStudyModal && <SelectStudyModal show={data.showSelectStudyModal} availableRFStudies={data.availableRFStudies} onSelectStudy={handleSelectStudy}/>}
      {showHeaderModal>0 && <HeaderModal show={showHeaderModal>0} />}
      {(showInputModal > 0) && <InputsModal show={showInputModal > 0}/>}
      {showOnboardingModal && <OnboardingLinePlotModal show={showOnboardingModal} onClose={handleCloseOnboarding} />}
      {!data.showProcessingData && dataExists && !data.showSelectStudyModal && !data.showMissingRFData && (
        <div className={'content' + (showOffCanvas?' show-offcanvas':'')}>
          <div className='reserve-fund'>
            <div className='intro' id="CalculatorIntroTour">
              <div className='flex-center'>
              <h1 className='title'>Forecast Calculator</h1>
              </div>
              <p className='information'>
                Explore different market scenarios and investment strategies with our Forecast Calculator to make more informed decisions about your reserve fund's future. <a target="_blank" href="https://www.verticalcityinstitute.com/post/vertical-city-toolkit-reserve-fund-forecasting">How this works »</a>
              </p>
            </div>

            <div className='flex-center study-dropdown' style={{'flexDirection': 'column', 'marginTop': '20px'}}>
              <OverlayTrigger placement="top" overlay={<Popover><PopoverBody>Select a Reserve Fund Study to be used for the forecast from the list of the uploaded studies for {auth.selectedBuilding}.</PopoverBody></Popover>}>
              <DropdownButton id='study-dropdown' title={getStudyFromYear(selectedYear)} size='lg'>
                {data.availableRFStudies.map((study) => <Dropdown.Item onClick={() => handleSelectYear(study.year)}>{study["year"]} Study by {study["engineer"]}</Dropdown.Item>)}
              </DropdownButton>
              </OverlayTrigger>
            </div>

            <div id="CalculatorForecastTour">
            <div className='header' id='projected-reserve-fund' style={{'paddingTop': '0px'}}>
              <div className='left'>
                <img src={logoBlue} alt="Blue Logo" width="60" height="60" />
                  <p className='section-header'>Reserve Fund Forecast</p>
              </div>
              <div className='divider divider-1'></div>
            </div>

            {data.cashflowBuffering && (
            <div className= 'flex-center'>
              <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
            </div>
            )} 

            {!data.cashflowBuffering && <div className='section-description'>Compare our benchmark forecast against your custom scenarios. Adjust the sliders below to modify your custom forecast parameters. Use 'Preview Configuration' to visualize different scenarios on the chart, or 'Lock In Configuration' to update your configuration across the entire system, including the <a style={{'color': '#5d98d1', 'cursor': 'pointer'}} onClick={() => navigate('/app/dashboard')}>Investment Dashboard</a>'s calculated Next Actions.</div>}

            <TotalsHeader />
            
            {!data.cashflowBuffering && (
            <div className='component' id='reserve-chart'>
              {data.recalculationBuffering && 
              <div className= 'flex-center' id="recalculating">
                <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
              </div>} 
              {!data.recalculationBuffering && 
              <div className="component-container" id='reserve-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                {data.dataVCRange && <HighchartsReact highcharts={Highcharts} options={chartOptionsReserve} />}
              </div>}
            </div>
            )}
            </div>

            {data.cashflowBuffering && (
            <div className= 'flex-center'>
              <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
            </div>
            )} 

            {showModalSettingsHistory && <ModalSettingsHistory show={showModalSettingsHistory}></ModalSettingsHistory>}
            {showSubmitSettingsModal && <SubmitSettingsModal show={showSubmitSettingsModal}></SubmitSettingsModal>}
            {showSubmitSettingsWarningModal && <SubmitSettingsWarningModal show={showSubmitSettingsWarningModal}></SubmitSettingsWarningModal>}

            {!data.cashflowBuffering && (
            <div id="CalculatorInputsTour" className="inputs" style={{'marginTop':'15px'}}>
              
              <div className='flex' style={{marginBottom: '10px', alignItems: 'center'}}>
                <span style={{fontSize: '19px', fontWeight: 600, marginRight: '6px'}}>Strategy</span>
                <div className='section-description' style={{margin: 0}}>These sliders adjust fund management during simulations, including the balance between traditional and Market Linked GICs and the minimum cash balance to maintain.</div>
              </div>

              <div className='flex' style={{"marginBottom": '7.5px', 'gap': '10px', 'flexWrap':'wrap'}}>
                <button className='input flex-grow-1 component-container space-between info-button green' style={{'color':'#000000'}} onClick={() => setShowInputModal(1)}>
                  <div>
                    <h5>Market Linked GICs</h5>
                    <p id='range'>(0-100%)</p>
                  </div>
                  <div className='slider' onClick={handleInnerClick}>
                    <RangeSlider value={tempRiskLevel} tooltip={'off'} onChange={(e) => setTempRiskLevel(e.target.value)} min={0} max={1} step={0.1}/>
                    <div className='input space-between'>
                      <div></div>
                      <p>{(tempRiskLevel == data.savedRiskLevel) ? "(saved) ": ""}{toPercentString(tempRiskLevel)}</p>
                    </div>
                  </div>
                </button>
                <button className='input flex-grow-1 component-container space-between info-button green' style={{'color':'#000000'}} onClick={() => setShowInputModal(2)}>
                  <div>
                    <h5>Min Cash Balance</h5>
                    <p id='range'>(0-1mil.)</p>
                  </div>
                  <div className='slider' onClick={handleInnerClick}>
                    <RangeSlider value={tempMinCash} tooltip={'off'} onChange={(e) => setTempMinCash(e.target.value)} min={0} max={1000000} step={100000} />
                    <div className='input space-between'>
                      <p></p>
                      <p>{(tempMinCash == data.savedMinCash) ? "(saved) ": ""}{formatAmount(tempMinCash, 0)}</p>
                    </div>
                  </div>
                </button>
                {data.historical && (
                <div className='input flex-grow-1 component-container space-between'>
                  <OverlayTrigger placement="left" overlay={<Popover><PopoverBody>By default, the {data.dataMaxYear - data.dataMinYear + 1} Year Window is set to the currently selected Reserve Fund Study's window</PopoverBody></Popover>}>
                    <div>
                      <h5>{data.dataMaxYear - data.dataMinYear + 1} Year Window</h5>
                      <p id='range'>(1980-{data.dataMaxYear})</p>
                    </div>
                  </OverlayTrigger>
                  <div className='slider'>
                    <RangeSlider value={tempWindow} tooltip={'off'} onChange={(e) => setTempWindow(e.target.value)} min={1980} max={data.dataMinYear} step={1} />
                    <div className='input space-between'>
                      <p></p>
                      <p>{(tempWindow == data.dataMinYear) ? "(saved) ": ""}{tempWindow + " to "}{Number(tempWindow) + (data.dataMaxYear - data.dataMinYear)}</p>
                    </div>
                  </div>
                </div>
                )}
              </div>

              <div className='flex' style={{marginBottom: '10px', alignItems: 'center'}}>
                <span style={{fontSize: '19px', fontWeight: 600, marginRight: '6px'}}>Assumptions</span>
                <div className='section-description' style={{margin: 0}}>These inputs model future market conditions, evolving interest and inflation rates for a more dynamic and accurate forecast compared to traditional fixed-rate studies.</div>
              </div>

              <div className='flex' style={{'gap': '10px','flexWrap':'wrap'}}>
                <button className='flex-grow-1 component-container info-button green' style={{'color':'#000000'}} id={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear) ? 'disabled' : ''} onClick={() => setShowInputModal(3)}>
                  <div className='input space-between'>
                    <h5 id='bold'>Inflation Rate</h5>
                    {data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear > currentYear) && <p style={{'margin': 'auto 0px'}}>Applied to {currentYear}-{Number(tempWindow) + data.dataMaxYear - data.dataMinYear} ({Number(tempWindow) + data.dataMaxYear - data.dataMinYear - currentYear} Years)</p>}
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Average</h5>
                      <p id='range'>(1-10%)</p>
                    </div>
                    <div className='slider' id={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < data.dataMinYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempInflationYield} tooltip={'off'} onChange={(e) => setTempInflationYield(e.target.value)} min={0.01} max={0.1} step={0.001} disabled={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear)} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempInflationYield) > 0.01) ? setTempInflationYield(Number(tempInflationYield)-0.001):setTempInflationYield(tempInflationYield)}} disabled={Number(tempInflationYield) <= 0.01}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempInflationYield) < 0.10) ? setTempInflationYield(Number(tempInflationYield)+0.001):setTempInflationYield(tempInflationYield)}} disabled={Number(tempInflationYield) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempInflationYield == data.savedInflationYield) ? "(saved) ": ""}{toPercentString(tempInflationYield)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Variance</h5>
                      <p id='range'>(0-10%)</p>
                    </div>
                    <div className='slider' id={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempInflationVol} tooltip={'off'} onChange={(e) => setTempInflationVol(e.target.value)} min={0} max={0.1} step={0.001} disabled={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear)} />
                      <div className='input space-between' onClick={handleInnerClick}>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempInflationVol) > 0) ? setTempInflationVol(Number(tempInflationVol)-0.001):setTempInflationVol(tempInflationVol)}} disabled={Number(tempInflationVol) <= 0}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempInflationVol) < 0.10) ? setTempInflationVol(Number(tempInflationVol)+0.001):setTempInflationVol(tempInflationVol)}} disabled={Number(tempInflationVol) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempInflationVol == data.savedInflationVol) ? "(saved) ": ""}{toPercentString(tempInflationVol)}</p>
                      </div>
                    </div>
                  </div>
                </button>
                <button className='flex-grow-1 component-container info-button green' style={{'color':'#000000'}} id={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear) ? 'disabled' : ''} onClick={() => setShowInputModal(4)}>
                  <div className='input space-between' >
                    <h5 id='bold'>GIC Interest Rate</h5>
                    {data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear > currentYear) && <p style={{'margin': 'auto 0px'}}>Applied to {currentYear}-{Number(tempWindow) + data.dataMaxYear - data.dataMinYear} ({Number(tempWindow) + data.dataMaxYear - data.dataMinYear - currentYear} Years)</p>}
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Yield</h5>
                      <p id='range'>(1-10%)</p>
                    </div>
                    <div className='slider' id={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempGICYield} tooltip={'off'} onChange={(e) => setTempGICYield(e.target.value)} min={0.01} max={0.1} step={0.001} disabled={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear)} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempGICYield) > 0.01) ? setTempGICYield(Number(tempGICYield)-0.001):setTempGICYield(tempGICYield)}} disabled={Number(tempGICYield) <= 0.01}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempGICYield) < 0.10) ? setTempGICYield(Number(tempGICYield)+0.001):setTempGICYield(tempGICYield)}} disabled={Number(tempGICYield) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempGICYield == data.savedGICYield) ? "(saved) ": ""}{toPercentString(tempGICYield)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Volatility</h5>
                      <p id='range'>(0-10%)</p>
                    </div>
                    <div className='slider' id={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempGICVol} tooltip={'off'} onChange={(e) => setTempGICVol(e.target.value)} min={0} max={0.1} step={0.001} disabled={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear)} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempGICVol) > 0) ? setTempGICVol(Number(tempGICVol)-0.001):setTempGICVol(tempGICVol)}} disabled={Number(tempGICVol) <= 0}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempGICVol) < 0.10) ? setTempGICVol(Number(tempGICVol)+0.001):setTempGICVol(tempGICVol)}} disabled={Number(tempGICVol) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempGICVol == data.savedGICVol) ? "(saved) ": ""}{toPercentString(tempGICVol)}</p>
                      </div>
                    </div>
                  </div>
                </button>
                <button className='flex-grow-1 component-container info-button green' style={{'color':'#000000'}} id={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear) ? 'disabled' : ''} onClick={() => setShowInputModal(5)}>
                  <div className='input space-between'>
                    <h5 id='bold'>Market Linked GIC</h5>
                    {data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear > currentYear) && <p style={{'margin': 'auto 0px'}}>Applied to {currentYear}-{Number(tempWindow) + data.dataMaxYear - data.dataMinYear} ({Number(tempWindow) + data.dataMaxYear - data.dataMinYear - currentYear} Years)</p>}
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Yield</h5>
                      <p id='range'>(1-10%)</p>
                    </div>
                    <div className='slider' id={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempMLGICYield} tooltip={'off'} onChange={(e) => setTempMLGICYield(e.target.value)} min={0.01} max={0.1} step={0.001} disabled={data.historical} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempMLGICYield) > 0.01) ? setTempMLGICYield(Number(tempMLGICYield)-0.001):setTempMLGICYield(tempMLGICYield)}} disabled={Number(tempMLGICYield) <= 0.01}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempMLGICYield) < 0.10) ? setTempMLGICYield(Number(tempMLGICYield)+0.001):setTempMLGICYield(tempMLGICYield)}} disabled={Number(tempMLGICYield) >= 0.10}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempMLGICYield == data.savedMLGICYield) ? "(saved) ": ""}{toPercentString(tempMLGICYield)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='input space-between'>
                    <div>
                      <h5>Volatility</h5>
                      <p id='range'>(1-20%)</p>
                    </div>
                    <div className='slider' id={data.historical && (Number(tempWindow) + data.dataMaxYear - data.dataMinYear < currentYear) ? 'disabled' : ''} onClick={handleInnerClick}>
                      <RangeSlider value={tempMLGICVol} tooltip={'off'} onChange={(e) => setTempMLGICVol(e.target.value)} min={0.01} max={0.2} step={0.001} disabled={data.historical} />
                      <div className='input space-between'>
                        {!mobileScreen && (
                          <div style={{'marginTop': '-9px'}}>
                            <button className='increment' id='left' onClick={() => {(Number(tempMLGICVol) > 0.01) ? setTempMLGICVol(Number(tempMLGICVol)-0.001):setTempMLGICVol(tempMLGICVol)}} disabled={Number(tempMLGICVol) <= 0.01}>◀</button>
                            <button className='increment' id='right' onClick={() => {(Number(tempMLGICVol) < 0.20) ? setTempMLGICVol(Number(tempMLGICVol)+0.001):setTempMLGICVol(tempMLGICVol)}} disabled={Number(tempMLGICVol) >= 0.20}>▶</button>
                          </div>
                        )}
                        {mobileScreen && <p></p>}
                        <p>{(tempMLGICVol == data.savedMLGICVol) ? "(saved) ": ""}{toPercentString(tempMLGICVol)}</p>
                      </div>
                    </div>
                  </div>
                </button>
              </div>

              <div className='space-between' style={{marginTop: '10px'}}>
                <Button className="header-button" variant="outline-success" onClick={() => setShowModalSettingsHistory(true)} style={{'maxWidth':'38vw'}} disabled={!data.savedConfig}>See History</Button>
                <div style={{display: 'flex', flexDirection: 'row-reverse', gap: '6px', whiteSpace:'nowrap', position: 'relative', flexWrap:'wrap'}}>
                  <Button id="CalculatorSaveTour" className="header-button" variant="success" onClick={handleSaveConfiguration} style={{'maxWidth':'38vw'}} disabled={data.historical || configurationUnchanged}>Lock In Configuration</Button>
                  <Button id="CalculatorCalculateTour" className="header-button" variant="outline-success" onClick={handleCalculate}>Preview Configuration</Button>
                </div>
              </div>
            </div>
            )}

            <div id="CalculatorDetailsTour">
            <div className='header' id='details' style={{'paddingTop': '0px'}}>
              <div className='left'>
                <img src={logoYellow} alt="Green Logo" width="60" height="60" />
                <p className='section-header'>{data.detailCharts ? "Reserve Fund Study Details" : "Custom Simulation Details"}</p>
              </div>
              <div className='divider divider-3'></div>
              {data.savedConfig && (
                <div className='switch'>
                  <Form>
                      <Form.Check
                        type="switch"
                        id="yellow"
                        disabled={!data.savedConfig}
                        label={data.detailCharts ? "Custom Simulation Details" : "RFS Details"}
                        onClick={() => data.setDetailCharts(!data.detailCharts)}
                      />
                  </Form>
                </div>
              )}
            </div>

            {data.cashflowBuffering && (
            <div className= 'flex-center'>
              <Spinner animation="border" style={{ width: "7rem", height: "7rem", margin: '60px 0px'}}/>
            </div>
            )} 

            {!data.cashflowBuffering && (<>
            {data.detailCharts && <div className='section-description'>The following charts provide details of the currently selected Reserve Fund Study simulation. Our system builds from the assumptions in these charts to get the forecasts above. The left chart shows projected cashflows between owner contributions and building expenditures. The right chart shows projected market assumptions and contribution increases.</div>}
            {!data.detailCharts && <div className='section-description'>The following charts provide details of the configured custom simulation above. The left chart shows the composion of Cash, GIC and MLGIC of the Reserve Fund based on a percentage. The right chart displays the calculated interest and inflation rates derived from a probability density function, which are used to simulate investment performance.</div>}
            {data.detailCharts && (
            <div className='component space-between detail-charts'>
              <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsExpenditures} />
              </div>
              <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsRate} />
              </div>
            </div>
            )}
            {!data.detailCharts && (
            <div className='component space-between detail-charts'>
              <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsComposition} />
              </div>
              <div className='component-container' id='detail-chart' onMouseLeave={() => setMouseOver(false)} onMouseEnter={() => setMouseOver(true)}>
                <HighchartsReact highcharts={Highcharts} options={chartOptionsRateCalcs} />
              </div>
            </div>
            )}
            </>)}
            </div>

            <OverlayTrigger placement="left" overlay={<Popover><PopoverBody>Learn how we made these charts with your cashflow table</PopoverBody></Popover>}>
            <button onClick={() => setShowOnboardingModal(!showOnboardingModal)} className='onboarding-buttom' style={{position: 'fixed', bottom: '85px', right: '20px'}}>
              <img src={greyQuestion} alt="Yellow Question Mark" width="50" height="50" />
            </button>
            </OverlayTrigger>

            <OverlayTrigger placement="left" overlay={<Popover><PopoverBody>Learn more about this page</PopoverBody></Popover>}>
            <button id="CalculatorHelpTour" onClick={() => setShowOffCanvas(!showOffCanvas)} className='understand-page-button' style={{position: 'fixed', bottom: '20px', right: '20px'}}>
                  <img src={greyQuestion} alt="Blue Question Mark" width="50" height="50" />
            </button>
            </OverlayTrigger>

            <div className='disclaimer'>
              Please note that Vertical City Toolkit is intended for informational purposes only and should not be construed as investment advice. 
              Condominium Boards should seek the advice of qualified professionals when making investment decisions for their Reserve Funds.
              Vertical City Toolkit does not provide financial, legal, tax or investment advice or recommendations.
            </div>

          </div>
        </div>
      )}
    </div>
  )
}
